import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, FormControl, Select, OutlinedInput, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable/';
import NewCustomerDialog from '../../popups/NewCustomerDialog';
import EditCustomerDialog from '../../popups/EditCustomerDialog';
import CustomerService from '../../../services/CustomerService';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Loader from '../../utils/Loading';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
import { ROUTES, USER_PERMISSIONS } from '../../../const';
import { useHistory } from 'react-router-dom';
import { Paper, TablePagination, Typography, Tooltip, IconButton, Grid } from '@mui/material';
import { AlertMessagesContext } from 'react-alert-messages';
import ShopsHelper from '../../../helpers/ShopsHelper';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CsvHelper from '../../../helpers/CsvHelper';
import { saveAs } from 'file-saver';
import ArrayHelper from '../../../helpers/ArrayHelper';
import SyncIcon from '@mui/icons-material/Sync';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PrintIcon from '@mui/icons-material/Print';
import { SelectFilterStyle } from '../../../const';

const FILTER_CUSTOMER_BALANCE = {
  excess: 'Excess',
  due: 'Due',
  nil: 'Zero',
};

function Customers() {
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const history = useHistory();

  const [customers, setCustomers] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [isShowCreateDialog, setIsShowCreateDialog] = useState(false);
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(0);
  const [isEnableSearchRefreshButton, setIsEnableSearchRefreshButton] = useState(false);
  const [filterGroup, setFilterGroup] = useState([]);
  const [filteredCustomerGroups, setFilteredCustomerGroups] = useState([]);
  const [customerGroups, setCustomerGroups] = useState([]);
  const [filterBalance, setFilterBalance] = useState([]);
  const [filteredCustomerBalances, setFilteredCustomerBalances] = useState([]);

  const userHasCustomersWritePermission = user?.shop_permissions?.customers_permission === USER_PERMISSIONS.WRITE;

  const openEditCustomerDialog = (customer) => {
    setEditData(customer);
  };

  const closeEditCustomerDialog = () => {
    setEditData(null);
  };

  const openCreateNewCustomerDialog = () => {
    setIsShowCreateDialog(true);
  };

  const closeCreateNewCustomerDialog = () => {
    setIsShowCreateDialog(false);
  };

  const getCustomers = useCallback(
    async ({
      _offset = offset,
      _limit = limit,
      search = filterText,
      groups = filteredCustomerGroups,
      balance_types = filteredCustomerBalances,
    } = {}) => {
      setLoadingIndicator(true);
      try {
        const response = await CustomerService.getCustomersWithCount({
          offset: _offset,
          limit: _limit,
          search: search || undefined,
          group_ids: groups.length ? groups.join(',') : undefined,
          balance_types: balance_types.length ? balance_types.join(',') : undefined,
        });

        const customers = response?.data || [];

        setCustomers(customers);
        setCount(customers.length);

        setIsEnableSearchRefreshButton(false);
      } catch (error) {
        postAlertMessage({ text: error.message, type: 'failed' });
      }
      setLoadingIndicator(false);
    },
    [filterText, filteredCustomerGroups, filteredCustomerBalances, limit, offset, postAlertMessage]
  );

  const redirectToCustomerrDetails = async (data) => {
    history.push(`${ROUTES.CUSTOMERS}/${data.id}`);
  };

  const getBalance = (data) => {
    const balance = ShopsHelper.getAmountFormatted(shop, data?.balance);
    let balanceStyle;
    if (balance >= 0) balanceStyle = { color: 'green' };
    else if (balance > data?.credit_limit) balanceStyle = { color: 'orange' };
    else balanceStyle = { color: 'red' };
    return (
      <Typography variant="body2" style={balanceStyle} gutterBottom>
        {balance >= 0 ? balance : `(cr) ${balance * -1}`}
      </Typography>
    );
  };

  const CSV_COLUMNS = {
    id: 'ID',
    code: 'Code',
    name: 'Name',
    email: 'Email',
    mobile: 'Mobile',
    location: 'Place',
    group: {
      title: 'Group',
      getValue: (item) => {
        return item?.group?.name || '';
      },
    },
    credit_limit: 'Credit Limit',
    trn_number: 'TRN',
    balance: {
      title: 'Balance',
      getValue: (item) => {
        return ShopsHelper.getAmountFormatted(shop, item?.balance || 0);
      },
    },
  };

  const headerData = [
    {
      label: 'ID',
      id: 'id',
      type: 'text',
    },
    {
      label: 'Code',
      id: 'code',
      type: 'text',
    },
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Mobile',
      id: 'mobile',
      type: 'text',
    },
    {
      label: 'Place',
      id: 'location',
      type: 'text',
      cssClass: 'no-print',
      cssClassHeader: 'no-print',
    },
    {
      label: 'Group',
      id: 'group',
      type: 'callback',
      viewRender: (data) => data?.group?.name ?? '-',
      cssClass: filteredCustomerGroups.length ? 'no-print' : '',
      cssClassHeader: filteredCustomerGroups.length ? 'no-print' : '',
    },
    {
      label: 'Credit Limit',
      id: 'credit_limit',
      type: 'callback',
      viewRender: (customer) =>
        customer.credit_limit >= 0 ? customer.credit_limit : `(cr) ${-1 * customer.credit_limit}`,
    },
    {
      label: 'TRN',
      id: 'trn_number',
      type: 'text',
    },
    {
      label: 'Balance',
      id: 'balance',
      type: 'callback',
      viewRender: (data) => getBalance(data),
    },
    {
      label: 'Actions',
      id: 'actions',
      cssClass: 'no-print',
      cssClassHeader: 'no-print',
      type: 'callback',
      viewRender: (item) => {
        return (
          <Grid className={styles.btnGroup}>
            <Tooltip title="View Details" onClick={() => redirectToCustomerrDetails(item)}>
              <RemoveRedEyeIcon className={styles.iconButton} />
            </Tooltip>
            {userHasCustomersWritePermission && (
              <>
                <Tooltip title="Edit Customer" onClick={() => openEditCustomerDialog(item)}>
                  <EditIcon className={styles.iconButton} />
                </Tooltip>
              </>
            )}
          </Grid>
        );
      },
    },
  ];

  const toCsvBtnPressed = async () => {
    const csv = CsvHelper.getString(customers, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `customer-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const handleImportCsv = async (csvFile) => {
    setLoadingIndicator(true);

    try {
      const csvData = await CsvHelper.parseCsvFile(csvFile);
      const customers = csvData.map((row, index) => {
        if (!row.Name) {
          throw new Error('Name must be provided and cannot be empty.');
        } else if (!row.Mobile) {
          throw new Error('Mobile must be provided and cannot be empty.');
        }
        const group = customerGroups.find((g) => g.name === row.Group) || {};
        return {
          name: row.Name,
          email: row.Email,
          mobile: row.Mobile,
          ean: row.EAN,
          location: row.Location,
          balance: Number(row.Balance),
          credit_limit: Number(row?.['Credit Limit']),
          trn_number: row.TRN,
          code: row.Code,
          group: {
            id: group.id || null,
            name: row.Group || null,
          },
        };
      });

      const alertId = 'customers-batch-update';
      const customersChunked = ArrayHelper.chunk(customers, 40);
      postAlertMessage({
        key: alertId,
        text: `Updating customers as ${customersChunked.length} segments`,
        timeout: -1,
      });
      for (const [index, customersChunk] of customersChunked.entries()) {
        await CustomerService.updateCustomers(customersChunk);
        postAlertMessage({
          key: alertId,
          text: `${index + 1}th chunk uploaded successfully`,
          timeout: -1,
        });
      }

      getCustomers();

      postAlertMessage({
        key: alertId,
        text: 'Customers imported successfully',
        type: 'success',
      });
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed', timeout: 5000 });
    }
    setLoadingIndicator(false);
  };

  const customerFeatureIsExpired = useCallback(() => {
    if (!shop?.customer_support_validity) {
      return false;
    }
    const currentDate = new Date();
    const validityDate = new Date(shop.customer_support_validity);
    return shop.customer_support_validity == null || currentDate > validityDate;
  }, [shop?.customer_support_validity]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    let offset = newPage * limit;
    setOffset(offset);
    getCustomers({ _offset: offset });
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
    getCustomers({ _limit: limit });
  };

  const getCustomerGroups = async () => {
    const resp = await CustomerService.getCustomerGroups();
    setCustomerGroups(resp);
  };

  useEffect(() => {
    if (!shop?.id || customerFeatureIsExpired()) return;
    getCustomers();
  }, [customerFeatureIsExpired, shop?.id, filteredCustomerGroups, getCustomers]);

  useEffect(() => {
    if (!shop?.id || customerFeatureIsExpired()) return;
    getCustomerGroups();
  }, [customerFeatureIsExpired, shop?.id]);

  const totalBalance = customers?.reduce((sum, customer) => sum + (customer.balance ?? 0), 0) ?? 0;
  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={`${styles.titleSec} no-print`}>
        <h2 className={styles.title}>
          Customer<span className={styles.menuTitle}>Management</span>
        </h2>
        {shop && shop?.customer_support_validity !== null && (
          <div className="buttons" style={{ display: 'flex', justifyContent: 'flex-end', gap: 5 }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ paddingBottom: '4px' }}>
                <label className={styles.label}>Import As</label>
              </div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#676dd8' }}
              >
                <input
                  accept="text/csv"
                  type="file"
                  hidden
                  id="import"
                  onChange={(event) => {
                    const fileField = event.target;
                    const csvFile = fileField.files[0];
                    fileField.value = null;
                    handleImportCsv(csvFile);
                  }}
                />
                <ImportExportIcon className={styles.actionBtnIcon} />
                <label for="import">CSV</label>
              </Button>
            </div>
            <div style={{ justifyContent: 'flex-end' }}>
              <div style={{ paddingBottom: '4px' }}>
                <label className={styles.label}>Print Report</label>
              </div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={window.print}
              >
                <PrintIcon className={styles.actionBtnIcon} />
                Print
              </Button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ paddingBottom: '4px' }}>
                <label className={styles.label}>Export As</label>
              </div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={toCsvBtnPressed}
              >
                <ImportExportIcon className={styles.actionBtnIcon} />
                CSV
              </Button>
            </div>
          </div>
        )}
      </div>
      {shop && customerFeatureIsExpired() ? (
        <RestrictionInfo
          title={'Customer support feature not available '}
          content={'To get customer support options, contact support team..'}
        />
      ) : (
        <>
          <div className={styles.changeable}>
            <div className={styles.filterSec}>
              <div className={styles.headTitle}>
                <h2 className={styles.subTitle}>Customers</h2>
              </div>
              <div style={{ paddingBottom: '12px', paddingRight: '12px' }} className={styles.actionButtons}>
                <div style={{ paddingTop: '22px' }} className={styles.filterDiv}>
                  <div className={`${styles.filerInputSec} no-print`}>
                    {userHasCustomersWritePermission && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ backgroundColor: '#ff851b' }}
                        className={styles.actionBtn}
                        onClick={openCreateNewCustomerDialog}
                      >
                        <NoteAddIcon className={styles.actionBtnIcon} />
                        New Customer
                      </Button>
                    )}
                    <div className={styles.searchSec}>
                      <input
                        type="text"
                        value={filterText}
                        onChange={(e) => {
                          setFilterText(e.target.value);
                          setIsEnableSearchRefreshButton(true);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setOffset(0);
                            setPage(0);
                            getCustomers({ _offset: 0 });
                          }
                        }}
                        className={styles.searchInput}
                        placeholder="Search Customers"
                      />
                      <Tooltip title="Refresh">
                        <IconButton
                          className={styles.iconBtn}
                          onClick={() => {
                            setOffset(0);
                            setPage(0);
                            getCustomers({ _offset: 0 });
                          }}
                          disabled={!isEnableSearchRefreshButton}
                        >
                          <SyncIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                {customerGroups.length > 0 && (
                  <div className={styles.filterDiv}>
                    <div style={{ paddingBottom: '4px' }}>
                      <label className={styles.label}>Customer Groups</label>
                    </div>
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <Select
                        labelId="group-multiple-checkbox-label"
                        id="group-multiple-checkbox"
                        multiple
                        displayEmpty
                        value={filterGroup}
                        onChange={(e) => {
                          setFilterGroup(e.target.value);
                          setFilteredCustomerGroups(e.target.value);
                        }}
                        input={<OutlinedInput className={styles.selectInput} />}
                        style={{ width: 135, height: 40 }}
                        renderValue={(selected) =>
                          selected.length === 0 ? 'All' : selected.map((id) => customerGroups.find((grp) => grp.id === id)?.name).join(', ')
                        }
                        MenuProps={SelectFilterStyle}
                      >
                        {customerGroups.map((group) => (
                          <MenuItem key={group.id} value={group.id}>
                            <Checkbox checked={filterGroup.includes(group.id)} color="primary" />
                            <ListItemText primary={group.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )
                }
                {
                  customers ? (
                    <div className={styles.filterDiv}>
                      <div style={{ paddingBottom: '4px' }}>
                        <label className={styles.label}>Customer Balance</label>
                      </div>
                      <div>
                        <FormControl sx={{ m: 1, width: 300 }}>
                          <Select
                            labelId="balance-multiple-checkbox-label"
                            id="balance-multiple-checkbox"
                            multiple
                            displayEmpty
                            value={filterBalance}
                            onChange={(e) => {
                              const selectedBalances = e.target.value;
                              setFilterBalance(selectedBalances);
                              setFilteredCustomerBalances(selectedBalances);
                            }}
                            input={<OutlinedInput className={styles.selectInput} />}
                            style={{ width: 135, height: 40 }}
                            renderValue={(selectedBalances) => {
                              if (selectedBalances.length === 0) return <span>All</span>;
                              const selectedNames = selectedBalances.map((balance) => FILTER_CUSTOMER_BALANCE[balance]);
                              return selectedNames.join(', ');
                            }}
                            MenuProps={SelectFilterStyle}
                          >
                            {Object.entries(FILTER_CUSTOMER_BALANCE).map(([key, label]) => (
                              <MenuItem key={key} value={key}>
                                <Checkbox checked={filterBalance.includes(key)} color="primary" />
                                <ListItemText primary={label} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  ) : null
                }
              </div >
            </div >
          </div >
          {customers && customers.length ? (
            <Paper className={styles.tableWrapper}>
              <DataTable
                columns={headerData}
                rows={customers}
                deactiveRow={true}
                summary={{
                  trn_number: 'Total*',
                  balance: totalBalance,
                }}
              />
              <TablePagination
                className="no-print"
                rowsPerPageOptions={[20, 50, 100, 200, 500]}
                component="div"
                count={count}
                rowsPerPage={limit}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          ) : (
            !loadingIndicator && (
              <Info
                title={'No customers to list'}
                content={
                  'You have no customers to list with current filter configuration. Please clear the filters or create a customer'
                }
              />
            )
          )}
          {editData && (
            <EditCustomerDialog
              closeDialog={closeEditCustomerDialog}
              editData={editData}
              reloadCustomers={getCustomers}
              customerGroups={customerGroups}
            />
          )}
          {isShowCreateDialog && (
            <NewCustomerDialog
              customerGroups={customerGroups}
              closeCreateNewCustomerDialog={closeCreateNewCustomerDialog}
              reloadCustomers={getCustomers}
            />
          )}
        </>
      )}
    </div>
  );
}

export default withConsoleBase(Customers);
