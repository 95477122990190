import React, { createContext, useState } from 'react';
import SubscriptionService from '../services/SubscriptionService';

export const OnlinePurchaseContext = createContext();

export default function OnlinePurchaseProvider(props) {
  const [isLoading, setLoading] = useState(false);
  const [subscriptionsAndAddons, setSubscriptionsAndAddons] = useState(null);
  const [purchaseData, setPurchaseData] = useState({
    subscription: null,
    add_ons: [],
    amount: 0,
    payable: 0,
    tax: 0,
    shop_details: null,
  });

  const loadSubscriptionsAndAddons = async () => {
    if (subscriptionsAndAddons) return;
    setLoading(true);
    const resp = await SubscriptionService.getSubscriptionsAndAdons();
    
    setSubscriptionsAndAddons(resp);
    setLoading(false);
  };

  return (
    <OnlinePurchaseContext.Provider
      value={{
        isLoading,
        purchaseData,
        subscriptionsAndAddons,
        setPurchaseData,
        loadSubscriptionsAndAddons,
      }}
    >
      {props.children}
    </OnlinePurchaseContext.Provider>
  );
}
