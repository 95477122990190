import BackendService from './BackendService';

async function getPurchaseDetails(params) {
    return BackendService.getData('/items/purchases/', {}, params);
}
async function addPurchase(data = {}) {
    return BackendService.postData('/items/purchases/', data);
}
async function getPurchase(id) {
    return BackendService.getData(`/items/purchase/${id}/`, '');
}
async function updatePurchase(data, id) {
    return BackendService.put(`/items/purchase/${id}/`, data);
}
async function deletePurchase(id) {
    return BackendService.destroy(`/items/purchase/${id}/`, '');
}

async function getPurchaseReturnDetails(params) {
    return BackendService.getData('/purchase-returns/', {}, params);
}
async function addPurchaseReturn(data = {}) {
    return BackendService.postData('/purchase-returns/', data);
}
async function getPurchaseReturn(id) {
    return BackendService.getData(`/purchase-returns/${id}/`, '');
}
async function updatePurchaseReturn(data, id) {
    return BackendService.patch(`/purchase-returns/${id}/`, data);
}
async function deletePurchaseReturn(id) {
    return BackendService.destroy(`/purchase-returns/${id}/`, '');
}

const PurchaseService = {
    getPurchaseDetails,
    addPurchase,
    getPurchase,
    updatePurchase,
    deletePurchase,
    getPurchaseReturnDetails,
    addPurchaseReturn,
    getPurchaseReturn,
    updatePurchaseReturn,
    deletePurchaseReturn,
};

export default PurchaseService;