import React, { useState, useEffect, useContext, useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Button, Tooltip, Grid } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable/';
import EditCategoryDialog from '../../popups/EditCategoryDialog';
import AddCategoryDialog from '../../popups/AddCategoryDialog';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ItemService from '../../../services/ItemService';
import ListDialog from '../../popups/ListDialog';
import Loader from '../../utils/Loading';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { UserContext } from '../../../Context/UserContext';
import { ShopContext } from '../../../Context/ShopContext';
import { USER_PERMISSIONS } from '../../../const';
import { AlertMessagesContext } from 'react-alert-messages';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

function ItemsCategories() {
  const { user } = useContext(UserContext);
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [categories, setCategories] = useState([]);
  const [categoriesFiltered, setCategoriesFiltered] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isShowCreateFormDialog, setShowCreateFormDialog] = useState(false);
  const [itemsData, setItemsData] = useState('');
  const [searchText, setSearchText] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);

  const handleEditFormDialogClose = () => {
    setSelectedCategory(null);
  };

  const handleCreateFormDialogClose = () => {
    setShowCreateFormDialog(false);
  };

  const onCategoryUpdateSuccess = async () => {
    await getCategories();
  };

  const onCategorySelected = (category) => {
    setSelectedCategory(category);
  };

  const toggleItemsTab = (rowData) => {
    setItemsData(rowData);
  };

  const handleOpenCreateFormDialog = () => {
    setShowCreateFormDialog(true);
  };

  const getCategories = useCallback(async () => {
    setLoadingIndicator(true);
    try {
      const res = await ItemService.getItemCategories();

      setCategories(res);
      setCategoriesFiltered(res);
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  }, [postAlertMessage]);

  const handleCategorySearch = (value) => {
    setSearchText(value);
    setCategoriesFiltered(categories.filter((category) => category.name.toLowerCase().includes(value.toLowerCase())));
  };

  const getItems = async (category) => {
    setLoadingIndicator(true);
    try {
      const items = await ItemService.getItems({ category: category?.id });
      toggleItemsTab(items);
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const headerData = [
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
  ];

  if (shop && shop.image_support_validity != null) {
    headerData.splice(1, 0, {
      label: 'Image',
      id: 'image',
      type: 'image',
    });
  }
  headerData.push(
    {
      label: 'Available',
      id: 'is_available',
      type: 'callback',
      viewRender: (category) => (category.is_available ? 'Available' : 'Unavailable'),
    },
    {
      label: 'Order',
      id: 'order',
      type: 'text',
    }
  );

  if (shop && (shop.kot_validity != null || shop.kot_lite_validity != null)) {
    headerData.push({
      label: 'Kitchen Ip',
      id: 'kitchen_ip',
      type: 'text',
    });
  }
  headerData.push({
    label: 'Actions',
    id: 'viewItems',
    title: 'View Products',
    type: 'callback',
    viewRender: (bill) => {
      return (
        <Grid className={styles.btnGroup}>
          <Tooltip title="View Items" onClick={() => getItems(bill)}>
            <RemoveRedEyeIcon className={styles.iconButton} />
          </Tooltip>
          {((user || {}).shop_permissions || {}).products_permission === USER_PERMISSIONS.WRITE && (
            <Tooltip title="Edit Category" onClick={() => onCategorySelected(bill)}>
              <EditIcon className={styles.iconButton} />
            </Tooltip>
          )}
        </Grid>
      );
    },
  });

  const listHeader = [
    {
      label: 'PLU',
      id: 'plu',
      type: 'text',
    },
    {
      label: 'Item Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Price',
      id: 'price',
      type: 'text',
    },
  ];
  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />

      <div className={styles.titleSec}>
        <h2 className={styles.title}>Categories</h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}></div>
          <div className={styles.filerInputSec}>
            {((user || {}).shop_permissions || {}).products_permission === USER_PERMISSIONS.READ ? (
              ''
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={handleOpenCreateFormDialog}
              >
                <AddShoppingCartIcon className={styles.actionBtnIcon} />
                New category
              </Button>
            )}
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                placeholder="Search category"
                onChange={(e) => {
                  handleCategorySearch(e.target.value);
                }}
                className={styles.searchInput}
              />
              <Tooltip title="Search" placement="bottom">
                <SearchIcon className={styles.searchIcon} />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      {categories &&
        (categoriesFiltered.length ? (
          <DataTable columns={headerData} rows={categoriesFiltered} />
        ) : (
          !loadingIndicator && (
            <Info
              title={'You have no categories to list'}
              content={"You haven't categories with the given properties"}
            />
          )
        ))}

      {selectedCategory && (
        <EditCategoryDialog
          category={selectedCategory}
          handleClose={handleEditFormDialogClose}
          onSuccess={onCategoryUpdateSuccess}
        />
      )}

      {isShowCreateFormDialog && (
        <AddCategoryDialog handleClose={handleCreateFormDialogClose} onSuccess={onCategoryUpdateSuccess} />
      )}

      {itemsData && (
        <ListDialog
          toggleItemsTab={toggleItemsTab}
          rowData={itemsData}
          tableHeader={listHeader}
          title={'Category Products'}
          emptyDataTitle="You have an empty list of items for this category!"
          emptyDataContent="Start adding your items for this category by editing this category"
        />
      )}
    </div>
  );
}

export default withConsoleBase(ItemsCategories);
