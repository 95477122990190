import React from 'react';
import styles from './styles.module.css';
import { DialogActions, Button } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import Info from '../utils/Alert/Info';

function PurchaseReturnTypePopup({ closeDialog, handleAddPurchaseReturn }) {
  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Select payment refund type</h2>
          <CloseIcon onClick={closeDialog} />
        </div>
        <div className={styles.inputSec}>
          <h3 className={styles.inputSubLabel}>
            <Info
              titlw={'Payment Return Type'}
              content={
                'How would you like to process the paid amout in this purchase return ? . You can choose between refunding the payment or keeping it in credit.'
              }
              severity={'info'}
            />
          </h3>
        </div>
        <div className={styles.footSec}>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                handleAddPurchaseReturn('refund');
              }}
              className={styles.closeBtn}
            >
              Refunded
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ backgroundColor: '#00a65a' }}
              onClick={() => {
                handleAddPurchaseReturn('credit');
              }}
            >
              Keep In Credit
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}

export default PurchaseReturnTypePopup;
