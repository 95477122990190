import React, { useCallback, useContext, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {
  Button,
  MenuItem,
  TextField,
  ListItemText,
  OutlinedInput,
  Checkbox,
  Select,
  FormControl,
  Tooltip,
} from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import ItemisedBillReportService from '../../../services/ItemisedBillReportService';
import SalespersonsService from '../../../services/SalesPersonService';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SyncIcon from '@material-ui/icons/Sync';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { saveAs } from 'file-saver';
import 'date-fns';
import ItemService from '../../../services/ItemService';
import ItemSummaryReportService from '../../../services/ItemSummaryReportService';
import Info from '../../utils/Alert/Info';
import Loader from '../../utils/Loading';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import AlertHelper from '../../../helpers/AlertHelper';
import Error from '../../utils/Alert/Error';
import { ShopContext } from '../../../Context/ShopContext';
import { AlertMessagesContext } from 'react-alert-messages';
import CsvHelper from '../../../helpers/CsvHelper';
import { filterEndDate } from '../../utils/FilterEndDate';
import PrintIcon from '@mui/icons-material/Print';
import ShopsHelper from '../../../helpers/ShopsHelper';
import Grayout from '../../utils/GrayOut/Grayout';
import TerminalService from '../../../services/TerminalServices';
import { SelectFilterStyle } from '../../../const';
import DateLimitDialog from '../../popups/DateLimitDialog';
import ItemSearchSelect from '../../utils/ItemSearchSelect/ItemSearchSelect';
import DateTimeRange from '../../utils/DateTimeRange/DateTimeRange';
import { dynamicDateRange } from '../../../services/DynamicDateRangeService';

function ItemisedBillSummaryReport() {
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState(getDefaultStartTime());
  const [filterToDate, setFilterToDate] = useState(filterEndDate());
  const [terminals, setTerminals] = useState([]);
  const [filterTerminal, setFilterTerminal] = useState([]);
  const [salesPersons, setSalesPersons] = useState([]);
  const [filterSalesperson, setFilterSalesperson] = useState([]);
  const [filterCategory, setFilterCategory] = useState('all');
  const [gst, setGst] = useState('all');
  const [filterItem, setFilterItem] = useState('All');
  const [sorting, setSorting] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [categories, setCategories] = useState('');
  const [vats, setVats] = useState('');
  const [prevFilterData, setPrevFilterData] = useState({
    filterFromDate,
    filterToDate,
    filterItem,
    filterCategory,
    sorting,
    gst,
  });
  // const [machineNames, setMachineNames] = useState('');
  const [errorMsg, setErrorMsg] = useState();
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(null);
  const [dateLimitPopup, setDateLimitPopup] = useState(false);

  const CSV_COLUMNS = {
    bill_no: {
      title: 'Bill No',
      getValue: (datum) => datum?.bill_no || datum.fid,
    },
    plu: 'PLU',
    name: 'Name',
    date: 'Date',
    time: {
      title: 'Time',
      getValue: (datum) => DateTimeHelpers.formatServerTimeSegment(datum.time),
    },
    price: {
      title: 'Unit Price',
      getValue: (datum) => {
        return ShopsHelper.getAmountFormatted(shop, datum?.price || 0);
      },
    },
    tax: 'Tax(%)',
    tax_amount: {
      title: 'Tax Amount',
      getValue: (datum) => {
        return ShopsHelper.getAmountFormatted(shop, datum?.tax_amount || 0);
      },
    },
    cess: 'Additional Tax(%)',
    cess_amount: {
      title: 'Additional Tax',
      getValue: (datum) => {
        return ShopsHelper.getAmountFormatted(shop, datum?.cess_amount || 0);
      },
    },
    quantity: 'Sold Quantity',
    total: {
      title: 'Total Amount',
      getValue: (datum) => {
        return ShopsHelper.getAmountFormatted(shop, datum?.total || 0);
      },
    },
  };

  function getDefaultStartTime() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  const isAnyChangeOnReportsFilters = useCallback(() => {
    return (
      filterFromDate !== prevFilterData.filterFromDate ||
      filterToDate !== prevFilterData.filterToDate ||
      filterItem !== prevFilterData.filterItem ||
      filterCategory !== prevFilterData.filterCategory ||
      sorting !== prevFilterData.sorting ||
      gst !== prevFilterData.gst ||
      filterTerminal !== prevFilterData.filterTerminal ||
      filterSalesperson !== prevFilterData.filterSalesperson
    );
  }, [
    filterFromDate,
    filterToDate,
    filterItem,
    filterCategory,
    sorting,
    gst,
    filterTerminal,
    filterSalesperson,
    prevFilterData.filterFromDate,
    prevFilterData.filterToDate,
    prevFilterData.filterItem,
    prevFilterData.filterCategory,
    prevFilterData.sorting,
    prevFilterData.gst,
    prevFilterData.filterTerminal,
    prevFilterData.filterSalesperson,
  ]);

  useEffect(() => {
    generateItemisedBillReports();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCategories();
    getVat();
    getSalespersons();
    getTerminals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnReportsFilters());
  }, [isShowGenerateButton, isAnyChangeOnReportsFilters]);

  const generateItemisedBillReports = () => {
    const { startDate, endDate } = dynamicDateRange('ITEMISED_BILL');
  
    setFilterFromDate(startDate);
    setFilterToDate(endDate);
  
    loadItemisedBillReports({
      startTime: startDate,
      endTime: endDate,
      sort: sorting,
      category: filterCategory,
      item: filterItem,
      vat: gst,
      cashier_ids: filterSalesperson,
      terminal_ids: filterTerminal,
    });
  
    setIsShowGenerateButton(false);
    setPrevFilterData({
      filterFromDate: startDate,
      filterToDate: endDate,
      filterItem,
      filterCategory,
      sorting,
      gst,
      filterSalesperson,
      filterTerminal,
    });
  };
  

  const getSalespersons = async () => {
    const res = await SalespersonsService.getSalesPersons();
    setSalesPersons(res);
  };

  const getTerminals = async () => {
    const res = await TerminalService.getTerminals();
    setTerminals(res);
  };

  const getCategories = async () => {
    const res = await ItemService.getItemCategories();
    setCategories(res);
  };

  // const getMachineName = async () => {
  //   const res = await DeviceService.getDevices();
  //   setMachineNames(res);
  // };

  const getVat = async () => {
    const res = await ItemSummaryReportService.getVat();
    setVats(res);
  };

  const toCsvBtnPressed = async () => {
    const csv = CsvHelper.getString(reportData, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `itemised-bill-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const loadItemisedBillReports = async ({
    startTime = filterFromDate,
    endTime = filterToDate,
    sort = sorting,
    category = filterCategory,
    item = filterItem,
    vat = gst,
    cashier_ids = filterSalesperson,
    terminal_ids = filterTerminal,
  } = {}) => {
    if (DateTimeHelpers.reportDateLimit(startTime, endTime)) {
      setDateLimitPopup(true);
      return;
    }
  
    setLoadingIndicator(true);
    try {
      const data = {
        filter_start_time: DateTimeHelpers.getTimezoneOffsetDate(startTime),
        filter_end_time: DateTimeHelpers.getTimezoneOffsetDate(endTime),
        sort_bill_num: sort,
        category_id: category === 'all' ? undefined : category,
        item_id: item === 'All' || item === 'all' ? undefined : item,
        filter_vat: vat === 'all' ? undefined : [vat],
        terminal_ids: terminal_ids.length ? terminal_ids : null,
        cashier_ids: cashier_ids.length ? cashier_ids : null,
      };
  
      const resp = await ItemisedBillReportService.getItemisedBillReport(data);
      setReportData(resp);
    } catch (err) {
      AlertHelper.setShortMessage(setErrorMsg, err.message);
    } finally {
      setLoadingIndicator(false);
    }
  };  

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const headerData = [
    {
      label: 'Bill No',
      id: 'bill_no',
      type: 'callback',
      viewRender: (obj) => obj.bill_no || obj.fid,
    },
    {
      label: 'PLU',
      id: 'plu',
      type: 'text',
    },
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Date',
      id: 'date',
      type: 'text',
    },
    {
      label: 'Time',
      id: 'time',
      type: 'callback',
      viewRender: (item) => DateTimeHelpers.formatServerTimeSegment(item.time),
    },
    {
      label: 'Unit Price',
      id: 'price',
      type: 'floatAmount',
    },
    {
      label: 'Tax(%)',
      id: 'tax',
      type: 'text',
    },
    {
      label: 'Tax',
      id: 'tax_amount',
      type: 'floatAmount',
    },
    {
      label: 'Additional Tax(%)',
      id: 'cess',
      type: 'text',
    },
    {
      label: 'Additional Tax',
      id: 'cess_amount',
      type: 'floatAmount',
    },
    {
      label: 'Sold Quantity',
      id: 'quantity',
      type: 'callback',
      viewRender: (item) => {
        if (!item.quantity || isNaN(item.quantity)) return;
        return `${ShopsHelper.getAmountFormatted(shop, item.quantity)} ${item.unit || ''}`;
      },
    },
    {
      label: 'Total Amount',
      id: 'total',
      type: 'callback',
      viewRender: (item) => {
        return `${shop?.currency || ''} ${ShopsHelper.getAmountFormatted(shop, item?.total)}`;
      },
    },
  ];

  const _getItemisedReportSummary = (items) => {
    let totalAmount = 0;
    let totalTax = 0;
    let totalAdditionalTax = 0;
    items.forEach((item) => {
      totalAmount += item?.total;
      totalTax += item.tax_amount;
      totalAdditionalTax += item.cess_amount;
    });
    return {
      totalAmount: ShopsHelper.getAmountFormatted(shop, totalAmount),
      totalTax: ShopsHelper.getAmountFormatted(shop, totalTax),
      totalAdditionalTax: ShopsHelper.getAmountFormatted(shop, totalAdditionalTax),
    };
  };

  const filteredReportData = reportData.filter(
    (datum) =>
      datum.bill_no?.toLowerCase()?.includes(searchText.toLowerCase()) ||
      datum.name?.toLowerCase()?.includes(searchText.toLowerCase())
  );
  const summary = _getItemisedReportSummary(filteredReportData);
  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <span className={styles.title}>
          {' '}
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>
        <div style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Print Report</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={window.print}
          >
            <PrintIcon className={styles.actionBtnIcon} />
            Print
          </Button>
        </div>
        <div style={{ justifyContent: 'flex-end' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Export As</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={toCsvBtnPressed}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            CSV
          </Button>
        </div>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>
              Itemised Sales Summary Report for{'  '}
              {DateTimeHelpers.convertDateToDMY(filterFromDate)}
              {'  '}to{'  '}
              {DateTimeHelpers.convertDateToDMY(filterToDate)}
            </h2>
          </div>
          <div className={styles.filerInputSec}>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="Search by bill no, name"
              />
              <Tooltip title="Search" placement="bottom">
                <SearchIcon className={styles.searchIcon} />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.dateDiv}>
            <DateTimeRange
              setFilterFromDate={setFilterFromDate}
              setFilterToDate={setFilterToDate}
              storageKey="ITEMISED_BILL"
            />
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Terminal</label>
            </div>
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  displayEmpty
                  value={filterTerminal}
                  onChange={(e) => setFilterTerminal(e.target.value)}
                  input={<OutlinedInput />}
                  style={{ width: 135, height: 40 }}
                  renderValue={(filterTerminal) => {
                    if (filterTerminal.length === 0) return <span>All</span>;
                    const selectedNames = filterTerminal.map((id) => terminals.find((tr) => tr.id === id).name);
                    return selectedNames.join(', ');
                  }}
                  MenuProps={SelectFilterStyle}
                >
                  {terminals.map((terminal) => (
                    <MenuItem key={terminal.id} value={terminal.id}>
                      <Checkbox checked={filterTerminal.includes(terminal.id)} color="primary" />
                      <ListItemText primary={terminal.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Salesperson</label>
            </div>
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  displayEmpty
                  value={filterSalesperson}
                  onChange={(e) => setFilterSalesperson(e.target.value)}
                  input={<OutlinedInput />}
                  style={{ width: 135, height: 40 }}
                  renderValue={(filterSalesperson) => {
                    if (filterSalesperson.length === 0) return <span>All</span>;
                    const selectedNames = filterSalesperson.map(
                      (id) => salesPersons.find((person) => person.id === id).name
                    );
                    return selectedNames.join(', ');
                  }}
                  MenuProps={SelectFilterStyle}
                >
                  {salesPersons.map((salesperson) => (
                    <MenuItem key={salesperson.id} value={salesperson.id}>
                      <Checkbox checked={filterSalesperson.includes(salesperson.id)} color="primary" />
                      <ListItemText primary={salesperson?.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Tax</label>
            </div>
            <div>
              <TextField
                select
                name="category"
                size="small"
                variant="outlined"
                defaultValue={gst}
                color="primary"
                className={styles.textfeild}
                onChange={(event) => {
                  setGst(event.target.value);
                }}
              >
                {vats &&
                  vats.map((vat) => (
                    <MenuItem value={vat} key={vat}>
                      {vat}
                    </MenuItem>
                  ))}
                <MenuItem value="all">All</MenuItem>
              </TextField>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Category</label>
            </div>
            <div>
              <TextField
                select
                name="gst"
                size="small"
                variant="outlined"
                defaultValue={filterCategory}
                color="primary"
                className={styles.textfeild}
                onChange={(event) => {
                  setFilterCategory(event.target.value);
                }}
              >
                {categories &&
                  categories.map((category) => (
                    <MenuItem value={category.id} key={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                <MenuItem value="all">All</MenuItem>
              </TextField>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Item</label>
            </div>
            <ItemSearchSelect filterItem={filterItem} setFilterItem={setFilterItem} />
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Bill No Sort</label>
            </div>
            <div>
              <TextField
                select
                name="billNo"
                size="small"
                variant="outlined"
                color="primary"
                style={{ width: 135 }}
                value={sorting}
                onChange={(event) => {
                  setSorting(event.target.value);
                }}
                displayEmpty
              >
                <MenuItem value={null}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={'DESC'}>Descending</MenuItem>
                <MenuItem value={'ASC'}>Ascending</MenuItem>
              </TextField>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Generate Report</label>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={generateItemisedBillReports}
                disabled={isShowGenerateButton === false}
              >
                <SyncIcon className={styles.actionBtnIcon} />
                Generate
              </Button>
            </div>
          </div>
        </div>
      </div>
      {errorMsg && (
        <div className={styles.marginTop}>
          <Error title={errorMsg} />
        </div>
      )}

      {isShowGenerateButton === null ? (
        <Info
          title={'Set filters and click generate button'}
          content={
            'Reports are generated based on the filters. Please set filters and click generate button to the generate report'
          }
        />
      ) : filteredReportData && filteredReportData.length ? (
        <div className={styles.tableContainer}>
          <Grayout open={isShowGenerateButton} />
          <DataTable
            columns={headerData}
            rows={filteredReportData}
            rowKey="bill_no-plu"
            summary={{
              name: 'Total',
              total: `${shop?.currency || ''} ${summary?.totalAmount}`,
              tax_amount: `${shop?.currency || ''} ${summary.totalTax}`,
              cess_amount: `${shop?.currency || ''} ${summary.totalAdditionalTax}`,
            }}
          />
        </div>
      ) : (
        !loadingIndicator && (
          <Info
            severity="warning"
            title={'Oh no, there are no item sales matching to your filter'}
            content={'There are no item sales matching to your filter. Please verify your inputs to generate report.'}
          />
        )
      )}

      {dateLimitPopup && (
        <DateLimitDialog
          handleClose={() => {
            setDateLimitPopup(false);
          }}
        />
      )}
    </div>
  );
}

export default withConsoleBase(ItemisedBillSummaryReport);
