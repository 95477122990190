import React, { useContext, useState } from 'react';
import { Button, DialogActions } from '@mui/material';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import { AlertMessagesContext } from 'react-alert-messages';
import ItemService from '../../services/ItemService';

export default function ConfirmProductionDialog({ handleClose, handleSuccess, items = [] }) {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const [isProcessing, setIsProcessing] = useState(false);

  const subItemsMap = items.reduce((acc, item) => {
    if (Array.isArray(item.details?.sub_items)) {
      item.details.sub_items.forEach(subItem => {
        const key = subItem.item_id;
  
        if (acc[key]) {
          acc[key].quantity += subItem.fraction * item.quantity;
        } else {
          acc[key] = {
            id: subItem.item_id,
            name: subItem.name,
            quantity: subItem.fraction * item.quantity,
          };
        }
      });
    }
    return acc;
  }, {});

  const handleActionButton = async () => {
    setIsProcessing(true);
    try {

      const productionData = items.map(item => ({
        id: item.item?.id,
        name: item.item?.name,
        quantity: item.quantity,
      }));
      await ItemService.createProductionEntry({ items: productionData });
  
      postAlertMessage({ text: 'Production processed successfully', type: 'success' });
      handleSuccess();
    } catch (error) {
      postAlertMessage({ text: error.message || 'Failed to confirm production', type: 'failed' });
      setIsProcessing(false);
      return;
    }
    handleClose();
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper} style={{ width: "50%" }} >
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Confirm Production</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.itemsList}>
          <div className={styles.listHeader}>
            <span className={styles.headerItem}>Item Name</span>
            <span className={styles.headerItem}>Quantity</span>
          </div>
          <ul className={styles.subItemsList}>
            {Object.values(subItemsMap).map(({ id, name, quantity }) => (
              <div key={id} className={styles.subItemInfo}>
                <li className={styles.subItem}>{name}</li>
                <li>:</li>
                <li className={styles.subItem}>{parseFloat(quantity.toFixed(3))}</li>
              </div>
            ))}
          </ul>
        </div>
        <div className={styles.footSec} style={{padding:'30px 5px'}}>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleClose} className={styles.closeBtn}>
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#00a65a' }}
              onClick={handleActionButton}
              disabled={isProcessing}
            >
              Confirm Production
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
