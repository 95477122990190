import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FormControl, Select, MenuItem, Button, Menu, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';
import dayjs from 'dayjs';
import styles from './styles.module.css';

const DateTimeRange = ({ setFilterFromDate, setFilterToDate, storageKey }) => {
  const [selectedOption, setSelectedOption] = useState('yesterday');
  const [fromDate, setFromDate] = useState(dayjs().subtract(1, 'day').startOf('day'));
  const [fromTime, setFromTime] = useState(dayjs().startOf('day'));
  const [toDate, setToDate] = useState(dayjs().subtract(1, 'day').endOf('day'));
  const [toTime, setToTime] = useState(dayjs().endOf('day'));
  const [anchorEl, setAnchorEl] = useState(null);
  const selectBoxRef = useRef(null);

  const handleDateRangeClick = useCallback(
    (range) => {
      const today = dayjs().startOf('day');
      let start, end;

      switch (range) {
        case 'today':
          start = today;
          end = today.endOf('day');
          break;
        case 'yesterday':
          start = today.subtract(1, 'day');
          end = today.subtract(1, 'day').endOf('day');
          break;
        case 'last3days':
          start = today.subtract(3, 'day');
          end = today.endOf('day');
          break;
        case 'last7days':
          start = today.subtract(7, 'day');
          end = today.endOf('day');
          break;
        default:
          return;
      }

      setFromDate(start);
      setFromTime(start.startOf('day'));
      setToDate(end);
      setToTime(end.endOf('day'));

      setFilterFromDate(start.toDate());
      setFilterToDate(end.toDate());
      localStorage.setItem(storageKey, JSON.stringify({ range }));
    },
    [setFilterFromDate, setFilterToDate, storageKey]
  );

  useEffect(() => {
    const cached = localStorage.getItem(storageKey);
    if (cached) {
      const { range } = JSON.parse(cached);
      setSelectedOption(range || 'yesterday');
      handleDateRangeClick(range || 'yesterday');
    } else {
      setSelectedOption('yesterday');
      handleDateRangeClick('yesterday');
    }
  }, [handleDateRangeClick, storageKey]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value === 'custom') {
      setAnchorEl(selectBoxRef.current);
    } else {
      setAnchorEl(null);
      handleDateRangeClick(value);
    }
  };

  const handleApplyCustomDate = () => {
    if (fromDate && toDate && fromTime && toTime) {
      const fromDateTime = fromDate.set('hour', fromTime.hour()).set('minute', fromTime.minute());
      const toDateTime = toDate.set('hour', toTime.hour()).set('minute', toTime.minute());

      setFilterFromDate(fromDateTime.toDate());
      setFilterToDate(toDateTime.toDate());

      localStorage.setItem(
        storageKey,
        JSON.stringify({
          range: 'custom',
          fromDate: fromDateTime.format('YYYY-MM-DD HH:mm'),
          toDate: toDateTime.format('YYYY-MM-DD HH:mm'),
        })
      );

      setAnchorEl(null);
    } else {
      alert('Please select both dates and times.');
    }
  };

  return (
    <div>
      <div style={{ paddingBottom: '4px' }}>
        <label className={styles.label}>Date Range</label>
      </div>
      <div style={{ height: '38px' }} ref={selectBoxRef}>
        <FormControl fullWidth variant="outlined" size="small">
          <Select
            size="small"
            value={selectedOption}
            onChange={handleChange}
            style={{ display: 'flex', alignItems: 'center', paddingTop: '4px' }}
            className={styles.select}
            MenuProps={{
              MenuListProps: {
                sx: {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  width: '100%',
                },
              },
            }}
          >
            <MenuItem
              value="today"
              className={styles.menuItems}
              style={{ alignItems: 'flex-start', padding: '10px 14px 4px' }}
            >
              Today
            </MenuItem>
            <MenuItem
              value="yesterday"
              className={styles.menuItems}
              style={{ alignItems: 'flex-start', padding: '10px 14px 4px' }}
            >
              Yesterday
            </MenuItem>
            <MenuItem
              value="last3days"
              className={styles.menuItems}
              style={{ alignItems: 'flex-start', padding: '10px 14px 4px' }}
            >
              Last 3 Days
            </MenuItem>
            <MenuItem
              value="last7days"
              className={styles.menuItems}
              style={{ alignItems: 'flex-start', padding: '10px 14px 4px' }}
            >
              Last 7 Days
            </MenuItem>
            <MenuItem
              className={styles.menuItems}
              style={{ alignItems: 'flex-start', padding: '10px 14px 4px' }}
              value="custom"
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(selectBoxRef.current);
              }}
            >
              Custom
            </MenuItem>
          </Select>
        </FormControl>

        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={styles.body}>
              <div>
                <div style={{ marginBottom: '10px' }}>
                  <strong>From:</strong>
                </div>
                <div className={styles.dateTimePicker}>
                  <DatePicker
                    label="Date"
                    value={fromDate}
                    onChange={(v) => setFromDate(v)}
                    maxDate={dayjs()}
                    sx={{ width: '550px' }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <TimePicker
                    label="Time"
                    value={fromTime}
                    onChange={(v) => setFromTime(v)}
                    sx={{ width: '450px' }}
                    renderInput={(params) => <TextField {...params} />}
                    ampm
                    // slots={{ clock: DigitalClock }}
                  />
                </div>
              </div>

              <div>
                <div style={{ marginBottom: '10px' }}>
                  <strong>To:</strong>
                </div>
                <div className={styles.dateTimePicker}>
                  <DatePicker
                    label="Date"
                    value={toDate}
                    onChange={(v) => setToDate(v)}
                    maxDate={dayjs()}
                    sx={{ width: '550px' }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <TimePicker
                    label="Time"
                    value={toTime}
                    onChange={(v) => setToTime(v)}
                    sx={{ width: '450px' }}
                    renderInput={(params) => <TextField {...params} />}
                    ampm
                    // slots={{ clock: DigitalClock }}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', paddingTop: '8px' }}>
                <Button onClick={() => setAnchorEl(null)}>Cancel</Button>
                <Button onClick={handleApplyCustomDate} color="primary">
                  Apply
                </Button>
              </div>
            </div>
          </LocalizationProvider>
        </Menu>
      </div>
    </div>
  );
};

export default DateTimeRange;
