import Alert from '@mui/material/Alert';
import React, { useEffect, useState, useContext } from 'react';
import ShopsHelper from '../../helpers/ShopsHelper';
import { ROUTES, THEME_SANOFT } from '../../const';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { ThemeContext } from '../../Context/ThemeContext';
import RedirectToWhatsapp from '../utils/RedirectToWhatsapp';

/**
 * Display subscription ending soon alert if the current shop's subscription plan is going to end in the next 15 days
 * @param props - { shop }
 * @returns SubscriptionEndingSoonAlertView
 */
export default function SubscriptionEndingSoonAlert(props) {
  const { shop, notifications } = props;
  const { theme } = useContext(ThemeContext);
  const [subscriptionRemainingDays, setSubscriptionRemainingDays] = useState();
  const [highlightedNotification, setHighlightedNotification] = useState(null);

  useEffect(() => {
    const remainingDays = ShopsHelper.getSubscriptionRemainingDays(shop);
    if (remainingDays < 7) {
      setSubscriptionRemainingDays(remainingDays);
    } else if (notifications) {
      const highlightNotification = notifications.find(
        (notification) => notification?.should_highlight === true && notification?.read === false
      );
      setHighlightedNotification(highlightNotification);
    }
  }, [shop, notifications]);

  return (
    <div>
      {subscriptionRemainingDays ? (
        <Alert severity="warning">
          {subscriptionRemainingDays < 0
            ? 'Your subscription expired'
            : `Your subscription validity ends in ${subscriptionRemainingDays} days.`}{' '}
          <strong>
            Click here to{' '}
            {theme?.name === THEME_SANOFT ? (
              <Link to={ROUTES.RENEW_PLAN}> renew your plan</Link>
            ) : (
              <RedirectToWhatsapp message="Renew subscription" />
            )}
          </strong>
        </Alert>
      ) : (
        highlightedNotification && (
          <Alert severity="warning">
            {highlightedNotification?.title}{' '}
            <strong>
              Click here to{' '}
              {theme?.name === THEME_SANOFT ? (
                <Link to={highlightedNotification?.link}> {highlightedNotification?.action}</Link>
              ) : (
                <RedirectToWhatsapp message={highlightedNotification?.action} />
              )}
            </strong>
          </Alert>
        )
      )}
    </div>
  );
}
