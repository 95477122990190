import BackendService from './BackendService';

async function getTables() {
  return BackendService.getData('/kot/tables/');
}
async function createTables(data) {
  return BackendService.postData('/kot/tables/', data);
}
async function updateTables(id, data = {}) {
  return BackendService.put(`/kot/tables/${id}/`, data);
}
async function deleteTables(id) {
  return BackendService.destroy(`/kot/tables/${id}/`, '');
}

const TableService = {
  getTables,
  createTables,
  updateTables,
  deleteTables,
};

export default TableService;
