import React, { useState, useEffect, useContext, useCallback } from 'react';
import styles from './styles.module.css';
import Loader from '../../utils/Loading';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { Button, Grid, Typography, Stack, Divider, Chip } from '@mui/material';
import { OnlinePurchaseContext } from '../../../Context/OnlinePurchaseContext';
import { ShopContext } from '../../../Context/ShopContext';
import CustomFormSwitch from '../../utils/CustomComponents/CustomFormSwitch';
import { ADDONS } from '../Register/RegisterConfig';
import TerminalServices from '../../../services/TerminalService';
import { useHistory } from 'react-router-dom';
import { REGISTRATION_TYPE, ROUTES, COUNTRY_CONFIGRATION } from '../../../const';

function RenewPlan() {
  const {
    purchaseData,
    setPurchaseData,
    loadSubscriptionsAndAddons,
    isLoading: isLoadingInPurchaseContext,
    subscriptionsAndAddons,
  } = useContext(OnlinePurchaseContext);
  const { shop } = useContext(ShopContext);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [yearly, setYearly] = useState(true);
  const [terminals, setTerminals] = useState([]);
  const [currentSub, setCurrentSub] = useState(shop?.subscription || {});
  const [selectedSub, setSelectedSub] = useState(shop?.subscription || {});

  const getRenewPlan = useCallback(() => {
    const { subscription_end_date, subscription, ...shopDetails } = shop;

    const sub =
      currentSub?.key === selectedSub?.key
        ? subscription
        : subscriptionsAndAddons.subscriptions.find((s) => s.key === selectedSub?.key);

    const subscriptionEndDate = new Date(subscription_end_date);
    let amount = yearly ? sub.yearly_price : sub.monthly_price;

    const subscriptionPlan = {
      key: sub.key,
      id: sub.id,
      validity: yearly ? 365 : 30,
      name: sub.name,
      price: amount,
    };

    let addonsPlan = [];
    if (subscriptionPlan.type === 'premium') {
      addonsPlan.push({
        key: 'expense',
        validity: yearly ? 365 : 30,
        name: 'Expense Support',
        price: 0,
      });
    }

    let freeTerminal = true;
    let addons_with_sub = (sub.config?.addons?.free || []).map((addon) => addon.key);

    addonsPlan = [
      ...addonsPlan,
      ...Object.keys(ADDONS)
        .map((addonKey) => {
          const addon = (subscriptionsAndAddons.add_ons || []).find((a) => a.key === addonKey);
          if (!addon) return null;

          if (addonKey === 'pos_terminal' || addonKey === 'ecs_terminal') {
            const count = terminals.filter((t) => t.type === ADDONS[addonKey]).length;
            if (count <= 0) return null;
            let discountAmount = freeTerminal ? (yearly ? addon.yearly_price : addon.monthly_price) : 0;
            amount -= discountAmount;
            let terminalFree = freeTerminal;
            freeTerminal = false;
            amount += yearly ? addon.yearly_price * count : addon.monthly_price * count;
            return {
              free: terminalFree,
              key: addonKey,
              count,
              name: addon.name,
              price: yearly ? addon.yearly_price : addon.monthly_price,
              validity: yearly ? 365 : 30,
            };
          }

          if (addons_with_sub.includes(addon.key)) return null;

          const validityDate = new Date(shopDetails[ADDONS[addonKey]]);
          if (isNaN(validityDate) || validityDate < subscriptionEndDate) return null;

          const addonAmount = yearly ? addon.yearly_price : addon.monthly_price;
          amount += addonAmount;
          return {
            key: addon.key,
            name: addon.name,
            validity: yearly ? 365 : 30,
            price: yearly ? addon.yearly_price : addon.monthly_price,
          };
        })
        .filter(Boolean),
    ];

    const tax = amount * (COUNTRY_CONFIGRATION[selectedSub?.country]?.tax ||  COUNTRY_CONFIGRATION['DEFAULT']?.tax);

    return {
      subscription: subscriptionPlan,
      add_ons: addonsPlan,
      amount,
      tax,
      payable: amount + tax,
      action_type: REGISTRATION_TYPE.RENEWAL,
    };
  }, [shop, terminals, yearly, subscriptionsAndAddons, currentSub?.key, selectedSub?.key, selectedSub?.country]);

  const handleChangeValidity = (event) => {
    setYearly(event.target.checked);
  };

  const getTerminals = async () => {
    setLoading(true);
    const resp = await TerminalServices.getTerminals();
    setTerminals(resp);
    setLoading(false);
  };

  const handleBuyButton = async () => {
    history.push(ROUTES.PROCESS_PAYMENT);
  };

  const handleSelectSubscription = (sub) => {
    setSelectedSub(sub);
  };

  useEffect(() => {
    getTerminals();
    loadSubscriptionsAndAddons();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!shop) return;
    setCurrentSub(shop.subscription);
    setSelectedSub(shop.subscription);
  }, [shop]);

  useEffect(() => {
    if (!subscriptionsAndAddons || !shop) return;
    const plan = getRenewPlan();
    setPurchaseData(plan);
  }, [subscriptionsAndAddons, getRenewPlan, setPurchaseData, shop]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loading || isLoadingInPurchaseContext} />
      <div className={styles.changeable}>
        <Grid display="flex" justifyContent="flex-end">
          <Stack direction="row" spacing={1} alignItems="center">
            <span className={styles.text}>Monthly</span>
            <CustomFormSwitch checked={yearly} onChange={handleChangeValidity} />
            <span className={styles.text}>Yearly</span>
          </Stack>
        </Grid>
        <Grid container rowSpacing={4} columnSpacing={4}>
          <Grid item xs={12} display="flex" alignItems="center" flexDirection="column">
            <h1 className={styles.head}>Renew Your Plan</h1>
            <h3>Renew with {currentSub?.key === selectedSub?.key ? 'current' : 'chousen'} plan</h3>
            <Typography variant="body2" className={styles.note}>
              This is your {currentSub?.key === selectedSub?.key ? 'current' : 'chousen'} plan. If you want to purchase
              any additional add-ons, please contact our support team.
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Grid container rowGap={2} className={styles.billContainer}>
              <Grid item container rowSpacing={2} className={styles.billWrapper}>
                <Grid item xs={12}>
                  <h3>Plan</h3>
                </Grid>
                <Grid item xs={6}>
                  <h4>Subscription</h4>
                </Grid>
                <Grid item xs={6}>
                  <h4>{purchaseData?.subscription?.name}</h4>
                </Grid>
                <Grid item xs={6}>
                  <h4>Validity</h4>
                </Grid>
                <Grid item xs={6}>
                  <h4>{purchaseData?.subscription?.validity}</h4>
                </Grid>
                <Grid item xs={6}>
                  <h4>Price</h4>
                </Grid>
                <Grid item xs={6}>
                  <h4>{purchaseData?.subscription?.price}</h4>
                </Grid>
              </Grid>
              <Grid item container rowSpacing={2} className={styles.billWrapper}>
                <Grid item xs={12}>
                  <h3>Add-ons</h3>
                </Grid>
                {purchaseData?.add_ons.map((adn) => (
                  <>
                    <Grid item xs={6}>
                      <h4 style={{ textTransform: 'capitalize' }}>{adn.name}</h4>
                    </Grid>
                    <Grid item xs={6}>
                      <h4>
                        {adn.price ? adn.price : <span className={styles.hilight}>FREE</span>}
                        {adn.count !== undefined &&
                          ` X ${adn.free ? adn.count - 1 : adn.count} = ${
                            adn.free ? (adn.count - 1) * adn.price : adn.count * adn.price
                          }`}
                        {adn.free && (
                          <span className={styles.hilight}>
                            {' '}
                            <Chip label=" 1 FREE" color="success" variant="outlined" className={styles.chip} />
                          </span>
                        )}
                      </h4>
                    </Grid>
                  </>
                ))}
                {subscriptionsAndAddons?.add_ons
                  .filter((a) => (selectedSub.config?.addons?.free || []).some((adn) => adn.key === a.key))
                  .map((subAdn) => (
                    <>
                      <Grid item xs={6}>
                        <h4 style={{ textTransform: 'capitalize' }}>{subAdn.name}</h4>
                      </Grid>
                      <Grid item xs={6}>
                        <h4>
                          <span className={styles.hilight}>
                            <Chip label="FREE" color="success" variant="outlined" className={styles.chip} />
                          </span>
                        </h4>
                      </Grid>
                    </>
                  ))}
              </Grid>
              <Grid item container rowSpacing={2} className={styles.billWrapper}>
                <Grid item xs={6}>
                  <h4>Total</h4>
                </Grid>
                <Grid item xs={6}>
                  <h4>{purchaseData?.amount || 0}</h4>
                </Grid>
                <Grid item xs={6}>
                  <h4>GST (18%)</h4>
                </Grid>
                <Grid item xs={6}>
                  <h4>{purchaseData?.tax || 0}</h4>
                </Grid>
                <Grid item xs={6}>
                  <h3>Sub Total</h3>
                </Grid>
                <Grid item xs={6}>
                  <h4>{purchaseData?.payable || 0}</h4>
                </Grid>
              </Grid>
              <Grid item container rowSpacing={2}>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Button variant="contained" className={styles.btn} onClick={handleBuyButton}>
                    Renew
                  </Button>
                </Grid>
              </Grid>
              <Grid item container rowSpacing={2}>
                <Grid item xs={3} display="none" alignItems="center">
                  <Divider className={styles.divider} />
                </Grid>
                <Grid item xs={6} display="none" justifyContent="center">
                  <Typography variant="body2" className={styles.subText}>
                    Or choose another plan from below
                  </Typography>
                </Grid>
                <Grid item xs={3} display="none" alignItems="center">
                  <Divider className={styles.divider} />
                </Grid>
              </Grid>
              <Grid item container rowSpacing={2} columnSpacing={1} style={{ display: 'none' }}>
                {subscriptionsAndAddons?.subscriptions.map((sub) => (
                  <Grid item xs={12} md={6}>
                    <Grid
                      container
                      rowSpacing={1}
                      className={styles.subContainer}
                      style={{
                        backgroundColor: selectedSub.key === sub.key ? '#f1f8ffe6' : 'none',
                      }}
                    >
                      <Grid item xs={12} display="flex" justifyContent="center" pt={2} pl={2} pr={2}>
                        <h3 style={{ color: '#1976d2' }}>{sub.name}</h3>
                      </Grid>
                      <Grid item xs={12} display="flex" justifyContent="center" pl={2} pr={2}>
                        <Divider className={styles.divider} />
                      </Grid>
                      <Grid item xs={12} display="flex" justifyContent="space-between" pl={2} pr={2}>
                        <h3>Rs {yearly ? sub.yearly_price : sub.monthly_price}</h3>
                        <h4>(Excl. tax)</h4>
                      </Grid>
                      <Grid item xs={12} display="flex" justifyContent="center" pl={2} pr={2}>
                        <Divider className={styles.divider} />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        flexDirection="column"
                        rowGap={1}
                        pl={2}
                        pr={2}
                        pb={1}
                        style={{ height: 170 }}
                      >
                        <h4>Addons with subscription</h4>
                        {(sub.config?.addons?.free || []).length ? (
                          <ul>
                            {subscriptionsAndAddons.add_ons
                              .filter((a) => (selectedSub.config?.addons?.free || []).some((adn) => adn.key === a.key))
                              .map((addonn) => (
                                <li>- {addonn.name}</li>
                              ))}
                          </ul>
                        ) : (
                          <Typography variant="body2" className={styles.note} style={{ textAlign: 'center' }}>
                            No add-ons with subscription
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        className={styles.proceedBtn}
                        pb={1}
                        pt={2}
                        pl={2}
                        pr={2}
                        onClick={() => {
                          handleSelectSubscription(sub);
                        }}
                        style={{ backgroundColor: selectedSub.key === sub.key ? '#1976d2e6' : 'none' }}
                      >
                        <h3>Proseed</h3>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withConsoleBase(RenewPlan);
