export const dynamicDateRange = (storageKey) => {
    const getDefaultStartTime = () => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    };
  
    const today = getDefaultStartTime();
    let startDate, endDate;
  
    const cachedData = localStorage.getItem(storageKey);
    let range = 'yesterday';
    let parsed = {};
    if (cachedData) {
      parsed = JSON.parse(cachedData);
      if (parsed.range) range = parsed.range;
    }
  
    switch (range) {
      case 'today':
        startDate = new Date(today);
        endDate = new Date();
        endDate.setHours(23, 59, 59, 999);
        break;
      case 'yesterday':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 1);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(startDate);
        endDate.setHours(23, 59, 59, 999);
        break;
      case 'last3days':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 2);
        endDate = new Date(today);
        endDate.setHours(23, 59, 59, 999);
        break;
      case 'last7days':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 6);
        endDate = new Date(today);
        endDate.setHours(23, 59, 59, 999);
        break;
      case 'custom':
        if (parsed.fromDate && parsed.toDate) {
          startDate = new Date(parsed.fromDate);
          endDate = new Date(parsed.toDate);
        }
        break;
      default:
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 1);
        endDate = new Date(today);
        endDate.setHours(23, 59, 59, 999);
    }
  
    return { startDate, endDate };
  };
  