import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
} from '@material-ui/core';
import 'date-fns';
import styles from './styles.module.css';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ItemService from '../../../services/ItemService';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import EditIcon from '@mui/icons-material/Edit';
import Loader from '../../utils/Loading';
import ConfirmProduction from '../../popups/CofirmProduction';

function Production() {
  const [editSelectedData, setEditSelectedData] = useState({});
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(null);
  const [plu, setPlu] = useState('');
  const [name, setName] = useState('');
  const [cost, setCost] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [gst, setGst] = useState(0);
  const [productionItems, setProductionItems] = useState([]);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [stock, setStock] = useState(0);
  const [showConfirmProductionDialog, setShowConfirmProductionDialog] = useState(false);

  const getItems = useCallback(async () => {
    try {
      const items = await ItemService.getItems({ type: 'PRODUCTION' });
      setItems(items);
    } catch (error) {
      console.error('Error fetching production items:', error);
    }
  }, []);

  const fetchInitialData = useCallback(async () => {
    setLoadingIndicator(true);
    try {
      await getItems();
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setLoadingIndicator(false);
    }
  }, [getItems]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const handleChangeRate = (val) => {
    if (quantity === 0 || item === null) return;
    setCost(val);
  };

  const handleChangeQty = (val) => {
    if (item === null) return;
    setQuantity(val);
    setStock(item?.stock + Number(val));
  };

  const resetItemFields = () => {
    setName('');
    setPlu('');
    setCost('');
    setQuantity(1);
    setGst('');
    setItem(null);
    setStock('');
  };

  const addItem = () => {
    let data = {
      plu: item.plu,
      item: item,
      rate: Number(cost),
      quantity: Number(quantity),
      vat: Number(gst),
      stock: Number(stock),
      details: item.details,
    };
    if (editSelectedData && !(editSelectedData?.items || []).find((itm) => itm.item === item && itm.rate === cost)) {
      data = {
        ...data,
        purchase: editSelectedData.id,
        new_item: true,
      };
    } else {
      const updatingItem = (editSelectedData?.items || []).find((itm) => itm.item === item && itm.rate === cost);
      data = {
        ...updatingItem,
        ...data,
      };
    }
    setProductionItems([...productionItems, data]);
    resetItemFields();
  };

  const dltItem = (item) => {
    setProductionItems(productionItems.filter((itm) => itm.rate !== item.rate && itm.plu !== item.plu));
  };

  const handleEditItem = (productionItem) => {
    setEditSelectedData(productionItem);
    setItem(productionItem.item);
    setName(productionItem.item?.name);
    setPlu(productionItem?.plu);
    setQuantity(productionItem?.quantity);
    setCost(productionItem?.rate);
    setGst(productionItem?.vat);
    setProductionItems(
      productionItems.filter((itm) => itm.rate !== productionItem.rate && itm.plu !== productionItem.plu)
    );
    setStock(items.find((itm) => itm.id === productionItem?.item.id)?.stock + Number(productionItem?.quantity));
  };

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Production<span className={styles.menuTitle}>Management</span>
        </h2>
      </div>

      <div className={styles.changeable}>
        <Table>
          <TableRow key="input row">
            <TableCell>
              <Autocomplete
                value={item}
                onChange={(event, newItem) => {
                  setItem(newItem);
                  setName(newItem.name || '');
                  setCost(newItem?.cost || '');
                  setStock(newItem?.stock || 0);
                }}
                disableClearable
                inputValue={plu}
                onInputChange={(e, value) => {
                  setPlu(value);
                }}
                getOptionLabel={(option) => `${option.plu}`}
                options={items}
                renderInput={(params) => (
                  <TextField {...params} label="PLU" variant="outlined" size="small" fullWidth />
                )}
              />
            </TableCell>
            <TableCell>
              <Autocomplete
                value={item}
                onChange={(event, newItem) => {
                  setItem(newItem);
                  setPlu(String(newItem.plu) || '');
                  setCost(newItem?.cost || '');
                  setStock(newItem?.stock || 0);
                }}
                disableClearable
                inputValue={name}
                onInputChange={(e, value) => {
                  setName(value);
                }}
                getOptionLabel={(option) => `${option.name}`}
                options={items}
                renderInput={(params) => (
                  <TextField {...params} label="Name" variant="outlined" size="small" fullWidth />
                )}
                style={{minWidth:"250px"}}
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Cost"
                variant="outlined"
                size="small"
                fullWidth
                value={cost}
                onChange={(e) => handleChangeRate(e.target.value)}
                type="number"
                disabled
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Quantity"
                variant="outlined"
                size="small"
                fullWidth
                type="number"
                value={quantity}
                onChange={(e) => handleChangeQty(e.target.value)}
              />
            </TableCell>
            <TableCell>
              <TextField label="Stock" variant="outlined" size="small" fullWidth disabled value={stock} />
            </TableCell>
            <TableCell>
              <IconButton disabled={plu === '' || name === ''} onClick={addItem}>
                <LibraryAddIcon />
              </IconButton>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </Table>
      </div>

      <TableContainer style={{ marginTop: 4 }} component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.columnCell}>PLU</TableCell>
              <TableCell className={styles.nameColumn}>Name</TableCell>
              <TableCell className={styles.columnCell}>Cost</TableCell>
              <TableCell className={styles.columnCell}>Quantity</TableCell>
              <TableCell className={styles.columnCell}>Stock</TableCell>
              <TableCell className={styles.columnCell}> Actions</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productionItems &&
              productionItems.map((productionItem) => (
                <TableRow key={productionItem.id}>
                  <TableCell>{productionItem.plu}</TableCell>
                  <TableCell>{productionItem.item.name}</TableCell>
                  <TableCell>{productionItem.rate}</TableCell>
                  <TableCell>{productionItem.quantity}</TableCell>
                  <TableCell>{productionItem.stock}</TableCell>
                  <TableCell colSpan={2} style={{ display: 'flex' }}>
                    <IconButton onClick={() => dltItem(productionItem)}>
                      <DeleteIcon />
                    </IconButton>
                    <IconButton onClick={() => handleEditItem(productionItem)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container className={styles.submitSection} justify="flex-end">
        <Button
          variant="contained"
          color="secondary"
          className={styles.closeBtn}
          style={{ backgroundColor: '#f50057' }}
          disabled={productionItems.length === 0}
          onClick={() => {
            setProductionItems([]);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: '#00a65a' }}
          disabled={productionItems.length === 0}
          onClick={() => {
            setShowConfirmProductionDialog(true);
          }}
        >
          Save Production
        </Button>
        {showConfirmProductionDialog && (
          <ConfirmProduction
            items={productionItems}
            handleClose={() => setShowConfirmProductionDialog(false)}
            handleSuccess={() => {
              setProductionItems([]);
              setShowConfirmProductionDialog(false);
            }}
          />
        )}
      </Grid>
    </div>
  );
}

export default withConsoleBase(Production);
