import styles from './styles.module.css';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DataTable from '../utils/DataTable';
import Info from '../utils/Alert/Info';
import DateTimeHelpers from '../../helpers/DateTimeHelpers';

export default function ShiftPaymentCashBookPopup(props) {
  const { toggleViewCashBook, rowData } = props;
  const listHeader = [
    {
      id: 'date',
      label: 'Date',
      type: 'callback',
      viewRender: (item) => DateTimeHelpers.getTodayStr(item.time),
    },
    {
      id: 'time',
      label: 'Time',
      type: 'callback',
      viewRender: (item) => DateTimeHelpers.convertTo12HourTime(new Date(item.time)),
    },
    {
      id: 'description',
      label: 'Description',
      type: 'text',
    },
    {
      id: 'credit',
      label: 'In',
      type: 'text',
    },
    {
      id: 'debit',
      label: 'Out',
      type: 'text',
    },
    {
      id: 'balance',
      label: 'Balance',
      type: 'text',
    },
  ];

  return (
    <div className={styles.popUp}>
      <div className={styles.fullPageWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>{`${rowData?.summary?.payment_mode?.name} Book`}</h2>
          <CloseIcon
            onClick={() => {
              toggleViewCashBook(null);
            }}
          />
        </div>
        <div className={styles.tableContent3}>
          {rowData.tnx ? (
            <DataTable columns={listHeader} rows={rowData.tnx} />
          ) : (
            <Info title={'Cash Book Details not found'} content={'This cash book have no transaction to list up'} />
          )}
        </div>
      </div>
    </div>
  );
}
