import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import SearchIcon from '@material-ui/icons/Search';
import PurchaseService from '../../../services/PurchaseService';
import ListDialog from '../../popups/ListDialog';
import Loader from '../../utils/Loading';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { ShopContext } from '../../../Context/ShopContext';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { AlertMessagesContext } from 'react-alert-messages';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { saveAs } from 'file-saver';
import CsvHelper from '../../../helpers/CsvHelper';
import SyncIcon from '@mui/icons-material/Sync';
import ShopsHelper from '../../../helpers/ShopsHelper';
import Grayout from '../../utils/GrayOut/Grayout';
import PrintIcon from '@mui/icons-material/Print';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DateTimeRange from '../../utils/DateTimeRange/DateTimeRange';
import { dynamicDateRange } from '../../../services/DynamicDateRangeService';

function PurchaseSummary() {
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [filterFromDate, setFilterFromDate] = useState(new Date());
  const [filterToDate, setFilterToDate] = useState(new Date());

  const [searchText, setSearchText] = useState('');
  const [purchases, setPurchase] = useState('');
  const [viewItems, setViewItems] = useState('');
  const [currency, setCurrency] = useState('');
  const [listTitle, setListTitle] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(null);
  const [prevFilterData, setPrevFilterData] = useState({
    filterFromDate,
    filterToDate,
  });

  const CSV_COLUMNS = {
    id: 'Id',
    invoice_no: 'Invoice No',
    bill_date: 'Bill Date',
    date: 'Date',
    vendor: {
      title: 'Vendor Name',
      getValue: (purchaseItem) => purchaseItem?.vendor?.name ?? purchaseItem?.vendor_name_old ?? '-',
    },
    plu: {
      title: 'PLU',
      getValue: (purchaseItem) => purchaseItem?.item?.plu,
    },
    name: {
      title: 'Item Name',
      getValue: (purchaseItem) => purchaseItem?.item?.name,
    },
    rate: {
      title: 'Cost',
      getValue: (purchaseItem) => purchaseItem?.item?.rate,
    },
    quantity: {
      title: 'Quantity',
      getValue: (purchaseItem) => purchaseItem?.item?.quantity,
    },
    tax: {
      title: 'Tax',
      getValue: (purchaseItem) => {
        return ShopsHelper.getAmountFormatted(shop, purchaseItem?.item?.tax || 0);
      },
    },
    total: {
      title: 'Total',
      getValue: (purchaseItem) => {
        const amount = purchaseItem?.item?.rate * purchaseItem?.item?.quantity + (purchaseItem?.item?.tax || 0);
        return ShopsHelper.getAmountFormatted(shop, amount);
      },
    },
    total_amount: {
      title: 'Purchase Total',
      getValue: (purchaseItem) => {
        return ShopsHelper.getAmountFormatted(shop, purchaseItem?.total_amount || 0);
      },
    },
  };

  const subTotal = (items) => {
    return items.reduce(function (a, b) {
      return a + b['total'];
    }, 0);
  };

  const handleItemsView = (row = '') => {
    if (row) {
      const selectedItems = row.items;
      setListTitle(`Purchased items from ${row?.vendor?.name ?? row.vendor_name_old} on ${row.date}`);
      let data = selectedItems.map((item) => ({
        ...item,
        name: item?.item?.name,
        total: Number(item.quantity) * Number(item.rate) + Number(item.tax),
      }));
      data = [
        ...data,
        {
          plu: '',
          name: '',
          quantity: '',
          rate: '',
          tax: 'Total',
          total: subTotal(data),
        },
      ];
      return setViewItems(data);
    }
    return setViewItems('');
  };

  const toCsvBtnPressed = async () => {
    const csvFormattedPurchases = purchases.flatMap((obj) =>
      obj.items.map((item, index) => ({
        id: obj.id,
        invoice_no: obj.invoice_no,
        bill_date: obj.bill_date,
        date: obj.date,
        vendor: obj.vendor,
        vendor_name_old: obj.vendor_name_old,
        total_amount: index === 0 ? obj.total_amount : undefined,
        item: {
          ...item,
          name: item.item.name,
        },
      }))
    );

    const csv = CsvHelper.getString(csvFormattedPurchases, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `purchase-summary-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const _getPurchasesFiltered = (purchases, searchKey) => {
    if (!searchKey) {
      return purchases;
    }

    const searchKeySmallCase = searchKey.toLowerCase();
    return (purchases || []).filter(
      (purchase) =>
        purchase?.vendor.name.toLowerCase().includes(searchKeySmallCase) ||
        purchase.date.includes(searchKeySmallCase) ||
        purchase.total_amount.toString().includes(searchKeySmallCase)
    );
  };

  const _getPurchasesAmountSum = (purchases) => {
    if (!purchases || !purchases.length) {
      return 0;
    }

    let sum = 0;
    purchases.forEach((purchase) => (sum += purchase.total_amount));
    return ShopsHelper.getAmountFormatted(shop, sum);
  };

  const headerData = [
    {
      label: 'Vendor Name',
      id: 'vendor',
      type: 'callback',
      viewRender: (item) => item?.vendor?.name ?? item?.vendor_name_old ?? '-',
    },
    {
      label: 'Purchase Date',
      id: 'date',
      type: 'text',
    },
    {
      label: 'Bill Date',
      id: 'bill_date',
      type: 'text',
    },
    {
      label: 'Invoice No',
      id: 'invoice_no',
      type: 'text',
    },
    {
      label: 'Total Amount',
      id: 'total_amount',
      type: 'floatAmount',
    },
    {
      label: 'View items',
      title: 'View items',
      id: 'viewItems',
      type: 'callback',
      viewRender: (item) => {
        return (
          <Tooltip title="View items" onClick={() => handleItemsView(item)}>
            <RemoveRedEyeIcon className={styles.iconButton} />
          </Tooltip>
        );
      },
    },
  ];

  const listHeaderData = [
    {
      label: 'PLU',
      id: 'plu',
      type: 'text',
    },
    {
      label: 'Item Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Price',
      id: 'rate',
      type: 'text',
    },
    {
      label: 'Quantity',
      id: 'quantity',
      type: 'text',
    },
    {
      label: 'Tax',
      id: 'tax',
      type: 'text',
    },
    {
      label: 'Total',
      id: 'total',
      type: 'callback',
      viewRender: (item) => {
        return `${currency || ''} ${ShopsHelper.getAmountFormatted(shop, item.total)}`;
      },
    },
  ];

  const _getLoadPurchaseRequestParams = useCallback(() => {
    return {
      date_start: DateTimeHelpers.getDateYMD(filterFromDate),
      date_end: DateTimeHelpers.getDateYMD(filterToDate),
    };
  }, [filterFromDate, filterToDate]);

  const generatePurchaseHistory = () => {
    const { startDate, endDate } = dynamicDateRange("PURCHASE_SUMMARY");
  
    setFilterFromDate(startDate);
    setFilterToDate(endDate);
  
    getPurchase({
      date_start: DateTimeHelpers.getDateYMD(startDate),
      date_end: DateTimeHelpers.getDateYMD(endDate),
    });
  
    setIsShowGenerateButton(false);
    setPrevFilterData({
      filterFromDate: startDate,
      filterToDate: endDate,
    });
  };
  
  const getPurchase = useCallback(
    async (customDateParams) => {
      setLoadingIndicator(true);
      try {
        const params =
          customDateParams ||
          _getLoadPurchaseRequestParams();
  
        const res = await PurchaseService.getPurchaseDetails(params);
        setPurchase(res);
        setCurrency(res.currency);
      } catch (err) {
        postAlertMessage({ text: err.message, type: "failed" });
      }
      setLoadingIndicator(false);
    },
    [_getLoadPurchaseRequestParams, postAlertMessage]
  );

  const isAnyChangeOnReportsFilters = useCallback(() => {
    return filterFromDate !== prevFilterData.filterFromDate || filterToDate !== prevFilterData.filterToDate;
  }, [filterFromDate, filterToDate, prevFilterData.filterFromDate, prevFilterData.filterToDate]);

  useEffect(() => {
    generatePurchaseHistory();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnReportsFilters());
  }, [isAnyChangeOnReportsFilters, isShowGenerateButton]);

  const purchasesToDisplay = _getPurchasesFiltered(purchases, searchText);
  const purchaseAmountSum = _getPurchasesAmountSum(purchases);
  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <span className={styles.title}>
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>
        <div style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Print Report</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={window.print}
          >
            <PrintIcon className={styles.actionBtnIcon} />
            Print
          </Button>
        </div>
        <div style={{ justifyContent: 'flex-end' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Export As</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a', marginRight: 5 }}
            onClick={toCsvBtnPressed}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            Csv
          </Button>
        </div>
      </div>

      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>
              Purchase Summary Report for{'  '}
              {DateTimeHelpers.convertDateToDMY(filterFromDate)}
              {'  '}to{'  '}
              {DateTimeHelpers.convertDateToDMY(filterToDate)}
            </h2>
          </div>
          <div className={styles.filerInputSec}>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="Search purchase"
              />
              <Tooltip title="Search" placement="bottom">
                <SearchIcon className={styles.searchIcon} />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.filterDiv}>
            <DateTimeRange
              setFilterFromDate={setFilterFromDate}
              setFilterToDate={setFilterToDate}
              storageKey="PURCHASE_SUMMARY"
            />
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Load Purchases</label>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={generatePurchaseHistory}
                disabled={isShowGenerateButton === false}
              >
                <SyncIcon className={styles.actionBtnIcon} />
                Generate
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isShowGenerateButton === null ? (
        <Info
          title={'Set filters and click load button'}
          content={
            'Purchase history are generated based on the filters. Please set filters and click load purchase button to get purchase history.'
          }
        />
      ) : (
        purchases &&
        (purchasesToDisplay && purchasesToDisplay.length ? (
          <div className={styles.tableContainer}>
            <Grayout open={isShowGenerateButton} />
            <DataTable
              columns={headerData}
              rows={purchasesToDisplay}
              summary={{ vendor: 'Total', total_amount: purchaseAmountSum }}
            />
          </div>
        ) : (
          !loadingIndicator && (
            <Info
              title={'You have no purchases to list'}
              content={"You haven't any purchase to list with given properties"}
            />
          )
        ))
      )}
      {viewItems && (
        <ListDialog
          toggleItemsTab={handleItemsView}
          tableHeader={listHeaderData}
          rowData={viewItems}
          title={listTitle}
          emptyDataTitle="No purchase items"
          emptyDataContent=""
        />
      )}
    </div>
  );
}

export default withConsoleBase(PurchaseSummary);
