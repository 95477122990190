import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Tooltip, Grid } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import SearchIcon from '@material-ui/icons/Search';
import PurchaseService from '../../../services/PurchaseService';
import { Link, useHistory } from 'react-router-dom';
import ListDialog from '../../popups/ListDialog';
import Loader from '../../utils/Loading';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';
import Info from '../../utils/Alert/Info';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { ROUTES, SUBSCRIPTION_TYPE, USER_PERMISSIONS } from '../../../const';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
import DateFnsUtils from '@date-io/date-fns';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { AlertMessagesContext } from 'react-alert-messages';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { saveAs } from 'file-saver';
import CsvHelper from '../../../helpers/CsvHelper';
import SyncIcon from '@mui/icons-material/Sync';
import ShopsHelper from '../../../helpers/ShopsHelper';
import Grayout from '../../utils/GrayOut/Grayout';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

function PurchaseReturnHistory() {
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [filterFromDate, setFilterFromDate] = useState(new Date());
  const [filterToDate, setFilterToDate] = useState(new Date());

  const [searchText, setSearchText] = useState('');
  const [PurchaseReturns, setPurchaseReturns] = useState('');
  const [viewItems, setViewItems] = useState([]);
  const [currency, setCurrency] = useState('');
  const [listTitle, setListTitle] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(null);
  const [prevFilterData, setPrevFilterData] = useState({
    filterFromDate,
    filterToDate,
  });

  const subscriptionType = shop?.subscription?.type;
  const history = useHistory();
  const userHasPurchaseWritePermission = user?.shop_permissions?.purchase_permission === USER_PERMISSIONS.WRITE;

  const CSV_COLUMNS = {
    id: 'Id',
    return_date: 'Return Date',
    vendor: {
      title: 'Vendor Name',
      getValue: (purchaseItem) => purchaseItem?.vendor?.name ?? '-',
    },
    plu: {
      title: 'PLU',
      getValue: (purchaseItem) => purchaseItem?.item?.plu,
    },
    name: {
      title: 'Item Name',
      getValue: (purchaseItem) => purchaseItem?.item?.name,
    },
    rate: {
      title: 'Cost',
      getValue: (purchaseItem) => purchaseItem?.item?.rate,
    },
    quantity: {
      title: 'Quantity',
      getValue: (purchaseItem) => purchaseItem?.item?.quantity,
    },
    tax: {
      title: 'Tax',
      getValue: (purchaseItem) => {
        return ShopsHelper.getAmountFormatted(shop, purchaseItem?.item?.tax || 0);
      },
    },
    total: {
      title: 'Total',
      getValue: (purchaseItem) => {
        const amount = purchaseItem?.item?.rate * purchaseItem?.item?.quantity + (purchaseItem?.item?.tax || 0);
        return ShopsHelper.getAmountFormatted(shop, amount);
      },
    },
    total_amount: {
      title: 'Purchase Total',
      getValue: (purchaseItem) => {
        return ShopsHelper.getAmountFormatted(shop, purchaseItem?.total_amount || 0);
      },
    },
  };

  const ONE_PURCHASE_RETURN_CSV_COLUMNS = {
    purchase_return: 'ID',
    id: 'Return item ID',
    name: 'Item name',
    quantity: 'Quantity',
    rate: 'Rate',
    plu: 'PLU',
    tax: 'Tax',
    total: 'Total',
  };

  const handleEditPurchase = (row) => {
    history.push(ROUTES.EDIT_PURCHASE_RETURN.replace(':id', row.id));
  };
  const subTotal = (items) => {
    return items.reduce(function (a, b) {
      return a + b['total'];
    }, 0);
  };

  const handleItemsView = (row = '') => {
    if (row) {
      const selectedItems = row.items;
      setListTitle(
        `Purchase returned items from ${row?.vendor?.name || row.vendor_name_old || ''} on ${row.return_date}`
      );
      let data = selectedItems.map((item) => ({
        ...item,
        name: item?.item?.name,
        total: Number(item.quantity) * Number(item.rate) + Number(item.tax),
      }));
      data = [
        ...data,
        {
          plu: '',
          name: '',
          quantity: '',
          rate: '',
          tax: 'Total',
          total: subTotal(data),
        },
      ];
      return setViewItems(data);
    }
    return setViewItems([]);
  };
  const handleDeleteClick = (data) => setDeleteData(data);
  const handlePurchaseReturnDeletion = async (row) => {
    const id = row.id;
    try {
      await PurchaseService.deletePurchaseReturn(id);
      getPurchaseReturn().then();
      postAlertMessage({
        text: 'Purchase deleted successufully',
        type: 'success',
      });
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
  };

  const toCsvBtnPressed = async () => {
    const csvFormattedPurchaseReturns = PurchaseReturns.flatMap((obj) =>
      obj.items.map((item, index) => ({
        id: obj.id,
        invoice_no: obj.invoice_no,
        bill_date: obj.bill_date,
        date: obj.date,
        vendor: obj.vendor,
        vendor_name_old: obj.vendor_name_old,
        total_amount: index === 0 ? obj.total_amount : undefined,
        item: {
          ...item,
          name: item.item.name,
        },
      }))
    );

    const csv = CsvHelper.getString(csvFormattedPurchaseReturns, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `purchase-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const _getPurchaseReturnsFiltered = (PurchaseReturns, searchKey) => {
    if (!searchKey) {
      return PurchaseReturns;
    }

    const searchKeySmallCase = searchKey.toLowerCase();
    return (PurchaseReturns || []).filter(
      (purchase) =>
        (purchase.vendor?.name ?? '').toLowerCase().includes(searchKeySmallCase) ||
        (purchase?.vendor_name_old ?? '').toLowerCase().includes(searchKeySmallCase) ||
        purchase.date.includes(searchKeySmallCase) ||
        purchase.total_amount.toString().includes(searchKeySmallCase)
    );
  };

  const _getPurchaseReturnsAmountSum = (PurchaseReturns) => {
    if (!PurchaseReturns || !PurchaseReturns.length) {
      return 0;
    }

    let sum = 0;
    PurchaseReturns.forEach((purchase) => (sum += purchase.total_amount));
    return ShopsHelper.getAmountFormatted(shop, sum);
  };

  const headerData = [
    {
      label: 'Vendor Name',
      id: 'vendor',
      type: 'callback',
      viewRender: (item) => item?.vendor?.name ?? item?.vendor_name_old ?? '-',
    },
    {
      label: 'Return Date',
      id: 'return_date',
      type: 'text',
    },
    {
      label: 'Total Amount',
      id: 'total_amount',
      type: 'floatAmount',
    },
    {
      label: 'Actions',
      id: 'actions',
      type: 'callback',
      viewRender: (item) => {
        return (
          <Grid className={styles.btnGroup}>
            <Tooltip title="View Details" onClick={() => handleItemsView(item)}>
              <RemoveRedEyeIcon className={styles.iconButton} />
            </Tooltip>
            {userHasPurchaseWritePermission && (
              <>
                <Tooltip title="Edit Purchase" onClick={() => handleEditPurchase(item)}>
                  <EditIcon className={styles.iconButton} />
                </Tooltip>
                <Tooltip title="Delete Purchase" onClick={() => handleDeleteClick(item)}>
                  <DeleteIcon className={styles.iconButton} />
                </Tooltip>
              </>
            )}
          </Grid>
        );
      },
    },
  ];

  const listHeaderData = [
    {
      label: 'PLU',
      id: 'plu',
      type: 'text',
    },
    {
      label: 'Item Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Price',
      id: 'rate',
      type: 'text',
    },
    {
      label: 'Quantity',
      id: 'quantity',
      type: 'text',
    },
    {
      label: 'Tax',
      id: 'tax',
      type: 'text',
    },
    {
      label: 'Total',
      id: 'total',
      type: 'callback',
      viewRender: (item) => {
        return `${currency || ''} ${ShopsHelper.getAmountFormatted(shop, item.total)}`;
      },
    },
  ];

  const _getLoadPurchaseRequestParams = useCallback(() => {
    return {
      date_start: DateTimeHelpers.getDateYMD(filterFromDate),
      date_end: DateTimeHelpers.getDateYMD(filterToDate),
    };
  }, [filterFromDate, filterToDate]);

  const getPurchaseReturn = useCallback(async () => {
    setLoadingIndicator(true);
    try {
      const params = _getLoadPurchaseRequestParams();
      const res = await PurchaseService.getPurchaseReturnDetails(params);
      setPurchaseReturns(res);
      setCurrency(res.currency);
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  }, [_getLoadPurchaseRequestParams, postAlertMessage]);

  const generatePurchaseReturnHistory = () => {
    getPurchaseReturn();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      filterFromDate,
      filterToDate,
    });
  };

  const isAnyChangeOnReportsFilters = useCallback(() => {
    return filterFromDate !== prevFilterData.filterFromDate || filterToDate !== prevFilterData.filterToDate;
  }, [filterFromDate, filterToDate, prevFilterData.filterFromDate, prevFilterData.filterToDate]);

  useEffect(() => {
    generatePurchaseReturnHistory();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnReportsFilters());
  }, [isAnyChangeOnReportsFilters, isShowGenerateButton]);

  const PurchaseReturnsToDisplay = _getPurchaseReturnsFiltered(PurchaseReturns, searchText);
  const purchaseAmountSum = _getPurchaseReturnsAmountSum(PurchaseReturns);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <ConfirmPopup
        data={deleteData}
        handleClose={handleDeleteClick}
        handleOkay={handlePurchaseReturnDeletion}
        alertTitle={'Confirm Delete'}
        successBtnName={'Delete'}
        alertContent={"Deleted purchase return history can't be restored, do you want to delete the Purchase History?"}
      />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Purchase Return <span className={styles.menuTitle}>Management</span>
        </h2>
        {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
          <div style={{ justifyContent: 'flex-end' }}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Export As</label>
            </div>
            <Button
              variant="contained"
              color="primary"
              className={styles.actionBtn}
              style={{ backgroundColor: '#00a65a', marginRight: 5 }}
              onClick={toCsvBtnPressed}
            >
              <ImportExportIcon className={styles.actionBtnIcon} />
              Csv
            </Button>
          </div>
        )}
      </div>
      {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC && (
        <RestrictionInfo
          title={'Feature not available '}
          content={'To get purchase return history options upgrade subscription to premium'}
        />
      )}
      {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
        <>
          <div className={styles.changeable}>
            <div className={styles.filterSec}>
              <div className={styles.headTitle}>
                <h2 className={styles.subTitle}>Purchase Return History</h2>
              </div>
              <div className={styles.filerInputSec}>
                {userHasPurchaseWritePermission && (
                  <Link to={ROUTES.NEW_PURCHASE_RETURN} style={{ textDecorationColor: 'transparent' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ backgroundColor: '#ff851b' }}
                      className={styles.actionBtn}
                    >
                      <AddShoppingCartIcon className={styles.actionBtnIcon} />
                      New Purchase Return
                    </Button>
                  </Link>
                )}
                <div className={styles.searchSec}>
                  <input
                    type="text"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    className={styles.searchInput}
                    placeholder="Search"
                  />
                  <Tooltip title="Search" placement="bottom">
                    <SearchIcon className={styles.searchIcon} />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className={styles.actionButtons}>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>From</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-from"
                      className={styles.dateBox}
                      value={filterFromDate}
                      onChange={(date) => {
                        setFilterFromDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>To</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-to"
                      className={styles.dateBox}
                      value={filterToDate}
                      onChange={(date) => {
                        setFilterToDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>Load Purchase Returns</label>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={styles.actionBtn}
                    style={{ backgroundColor: '#00a65a' }}
                    onClick={generatePurchaseReturnHistory}
                    disabled={isShowGenerateButton === false}
                  >
                    <SyncIcon className={styles.actionBtnIcon} />
                    Generate
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {isShowGenerateButton === null ? (
            <Info
              title={'Set filters and click load button'}
              content={
                'Purchase return history are generated based on the filters. Please set filters and click load purchase button to get purchase history.'
              }
            />
          ) : (
            PurchaseReturns &&
            (PurchaseReturnsToDisplay && PurchaseReturnsToDisplay.length ? (
              <div className={styles.tableContainer}>
                <Grayout open={isShowGenerateButton} />
                <DataTable
                  columns={headerData}
                  rows={PurchaseReturnsToDisplay}
                  summary={{ date: 'Total', total_amount: purchaseAmountSum }}
                />
              </div>
            ) : (
              !loadingIndicator && (
                <Info
                  title={'You have no purchase returns to list'}
                  content={"You haven't any purchase returns to list with given properties"}
                />
              )
            ))
          )}

          {viewItems && viewItems.length > 0 && (
            <ListDialog
              toggleItemsTab={handleItemsView}
              tableHeader={listHeaderData}
              rowData={viewItems}
              title={listTitle}
              emptyDataTitle="No purchase return items"
              emptyDataContent=""
              csvColumns={ONE_PURCHASE_RETURN_CSV_COLUMNS}
            />
          )}
        </>
      )}
    </div>
  );
}

export default withConsoleBase(PurchaseReturnHistory);
