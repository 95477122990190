import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, MenuItem, TextField } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CreateIcon from '@material-ui/icons/Create';
import { AlertMessagesContext } from 'react-alert-messages';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import EditItemDialog from '../../popups/EditItemDialog';
import AddItemDialog from '../../popups/AddItemDialog';
import ItemService from '../../../services/ItemService';
import { saveAs } from 'file-saver';
import Loader from '../../utils/Loading';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { UserContext } from '../../../Context/UserContext';
import { ShopContext } from '../../../Context/ShopContext';
import { USER_PERMISSIONS } from '../../../const';
import CsvHelper from '../../../helpers/CsvHelper';
import ArrayHelper from '../../../helpers/ArrayHelper';
import { Paper, Pagination, Grid, TablePagination, Tooltip, IconButton } from '@mui/material';
import { ITEM_TYPES } from '../../../const';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@mui/icons-material/Edit';

function ItemsList() {
  const { user } = useContext(UserContext);
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterCategory, setFilterCategory] = useState('all');
  const [filterText, setFilterText] = useState('');
  const [selectedItemForEdit, setSelectedItemForEdit] = useState(null);
  const [isShowAddItemDialog, setIsShowAddItemDialog] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(100);
  const [count, setCount] = useState(0);
  const [isShowRefreshButton, setIsShowRefreshButton] = useState(false);
  const [filterItemType, setFilterItemType] = useState('all');

  const CSV_COLUMNS = {
    plu: 'PLU',
    external_item_id: 'External_Item_ID',
    name: 'Name',
    unit: 'Unit',
    price: 'Price',
    ean: 'EAN',
    is_available: {
      title: 'Available',
      getValue: (item) => (item.is_available ? 'YES' : 'NO'),
    },
    sync_enabled: {
      title: 'Machine_Sync',
      getValue: (item) => (item.sync_enabled ? 'YES' : 'NO'),
    },
    category_name: {
      title: 'Category',
      getValue: (item) => categories.find((category) => category.id === item.category)?.name,
    },
    stock: 'Stock',
    cost: 'Cost',
    tax: 'Tax',
    cess: 'Cess',
    order: 'Order',
    hsn: 'HSN',
    image: 'Image',
  };

  const dismissEditDialog = () => {
    setSelectedItemForEdit(null);
  };

  const dismissCreateDialog = () => {
    setIsShowAddItemDialog(false);
  };

  const showCreateDialog = () => {
    setIsShowAddItemDialog(true);
  };

  const getItems = useCallback(
    async ({ _offset = offset, _limit = limit, search = filterText, _filterCategory = filterCategory, _filterItemType = filterItemType, } = {}) => {
      setIsShowLoading(true);
      try {
        const params = {
          offset: _offset,
          limit: _limit,
          search,
          category: _filterCategory === 'all' ? undefined : _filterCategory,
          type: _filterItemType === 'all' ? `${ITEM_TYPES.INVENTORY},${ITEM_TYPES.COMBO},${ITEM_TYPES.SERVICE},${ITEM_TYPES.PRODUCTION}` : _filterItemType,
        };
        const response = await ItemService.getItemsResponse(params);
        setItems(response?.data);
        setCount(response?.count);
        setIsShowRefreshButton(false);
      } catch (error) {
        postAlertMessage({ text: error.message, type: 'failed' });
      }
      setIsShowLoading(false);
    },
    [limit, offset, filterText, filterCategory, postAlertMessage,filterItemType]
  );

  const handleExportScale = async () => {
    setIsShowLoading(true);
    try {
      await ItemService.updatePlu();
      postAlertMessage({
        text: 'Item scale updated successfully',
        type: 'success',
      });
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setIsShowLoading(false);
  };
  const handleGetExportData = async () => {
    setIsShowLoading(true);
    try {
      const allItems = await ItemService.getItems();
      await handleExportCsv(allItems);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setIsShowLoading(false);
  };

  const handleExportCsv = async (exportItems) => {
    const csv = CsvHelper.getString(exportItems, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `items-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const handleImportCsv = async (csvFile) => {
    setIsShowLoading(true);

    try {
      const csvData = await CsvHelper.parseCsvFile(csvFile);
      const items = csvData.map((row, index) => {
        if (!row.PLU || row.PLU === '0') {
          throw new Error('PLU must be provided and cannot be zero.');
        } else if (!row.Name || !row.Price || !row.Unit || !row.Category) {
          throw new Error(`On ${index + 1}th row, name or unit or price or category is empty`);
        }
        const category = categories.find((c) => c.name === row.Category) || {};
        return {
          plu: Number(row.PLU),
          external_item_id: row.External_Item_ID,
          name: row.Name,
          unit: row.Unit.toLowerCase() === 'kg' ? 'Kg' : 'Pcs',
          price: Number(row.Price),
          ean: row.EAN,
          is_available: row.Available === 'YES',
          sync_enabled: row.Machine_Sync === 'YES',
          stock: Number(row.Stock),
          cost: Number(row.Cost),
          tax: Number(row.Tax),
          cess: Number(row.Cess),
          order: Number(row.Order),
          category: {
            id: category.id,
            name: row.Category,
          },
          image: row.Image,
          hsn: Number(row.HSN),
        };
      });

      const alertId = 'items-batch-update';
      const itemsChunked = ArrayHelper.chunk(items, 40);
      postAlertMessage({
        key: alertId,
        text: `Updating items as ${itemsChunked.length} segments`,
        timeout: -1,
      });
      for (const [index, itemsChunk] of itemsChunked.entries()) {
        await ItemService.updateItems(itemsChunk);
        postAlertMessage({
          key: alertId,
          text: `${index + 1}th chunk uploaded successfully`,
          timeout: -1,
        });
      }

      getItems();

      postAlertMessage({
        key: alertId,
        text: 'Item imported successfully',
        type: 'success',
      });
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed', timeout: 5000 });
    }
    setIsShowLoading(false);
  };

  const handleCategorySearch = (_filterCategory) => {
    setFilterCategory(_filterCategory);
    getItems({ _filterCategory });
  };

  const handleItemsSearch = (value) => {
    if (value !== filterText) {
      setOffset(0);
      setFilterText(value);
      setIsShowRefreshButton(true);
    }
  };

  const handleChangePage = (event, page) => {
    const newPage = page - 1;
    setPage(newPage);
    const _offset = newPage * limit;
    setOffset(_offset);
    getItems({ _offset });
  };

  const handleChangeRowsPerPage = (event) => {
    const _limit = +event.target.value;
    setLimit(_limit);
    getItems({ _limit });
  };

  const headerData = [
    {
      label: 'PLU',
      id: 'plu',
      type: 'text',
    },
    {
      label: 'Name',
      id: 'name',
      type: 'text',
      main: 'true',
    },
    {
      label: 'Unit',
      id: 'unit',
      type: 'text',
    },
    {
      label: 'Price',
      id: 'price',
      type: 'text',
    },
    {
      label: 'MRP',
      id: 'mrp',
      type: 'text',
    },

    {
      label: 'Tax(%)',
      id: 'tax',
      type: 'text',
    },
    {
      label: 'Additional Tax(%)',
      id: 'cess',
      type: 'text',
    },
    {
      label: 'Type',
      id: 'type',
      type: 'text',
    },
    {
      label: 'Available',
      id: 'is_available',
      type: 'callback',
      viewRender: (item) => {
        return item.is_available ? 'Yes' : 'No';
      },
    },
    {
      label: 'Order',
      id: 'order',
      type: 'text',
    },
    {
      label: 'Stock',
      id: 'stock',
      type: 'text',
    },
  ];
  if (shop && shop.image_support_validity != null) {
    headerData.splice(2, 0, {
      label: 'Image',
      id: 'image',
      type: 'image',
    });
  }
  if (((user || {}).shop_permissions || {}).products_permission === USER_PERMISSIONS.WRITE) {
    headerData.push({
      label: 'Actions',
      id: 'updateItems',
      title: 'Edit Item',
      type: 'callback',
      viewRender: (bill) => {
        return (
          <Tooltip title="Edit Item" onClick={() => setSelectedItemForEdit(bill)}>
            <EditIcon className={styles.iconButton} />
          </Tooltip>
        );
      },
    });
  }

  const getRowStyle = (row) => {
    if (row.stock < 0 && row.type === ITEM_TYPES.INVENTORY) {
      return { backgroundColor: 'rgba(245, 39, 39, 0.2)' };
    } else if (row.stock < 10 && row.type === ITEM_TYPES.INVENTORY) {
      return { backgroundColor: 'rgba(245, 169, 39, 0.2)' };
    }
    return;
  };

  useEffect(() => {
    const getCategories = async () => {
      const res = await ItemService.getItemCategories();
      setCategories(res);
    };

    getCategories();
  }, []);

  useEffect(() => {
    getItems();
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={isShowLoading} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          {' '}
          Item<span className={styles.menuTitle}>list</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>Products</h2>
          </div>
          <div className={styles.filerInputSec}>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={filterText}
                onChange={(e) => {
                  handleItemsSearch(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    getItems();
                  }
                }}
                className={styles.searchInput}
                placeholder="Search items"
              />
              <Tooltip title="Search">
                <IconButton className={styles.iconBtn} onClick={getItems} disabled={isShowRefreshButton === false}>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
        
        <div className={styles.filterArea}>
          <div style={{display:"flex"}}>
            <div>
              <div style={{ paddingBottom: '4px' }}>
                <label className={styles.label}>Item Type</label>
              </div>
              <TextField
                select
                variant="outlined"
                size="small"
                value={filterItemType}
                onChange={(e) => {
                  setFilterItemType(e.target.value);
                  getItems({ _filterItemType: e.target.value });
                }}
                className={styles.selectBox}
                style={{marginRight:"10px"}}
              >
                <MenuItem value="all">All Types</MenuItem>
                <MenuItem value={ITEM_TYPES.INVENTORY}>Inventory</MenuItem>
                <MenuItem value={ITEM_TYPES.COMBO}>Combo</MenuItem>
                <MenuItem value={ITEM_TYPES.SERVICE}>Service</MenuItem>
                <MenuItem value={ITEM_TYPES.PRODUCTION}>Production</MenuItem>
              </TextField>
            </div>
            <div>
              <div style={{ paddingBottom: '4px' }}>
                <label className={styles.label}>Item Category</label>
              </div>
              <TextField
                select
                variant="outlined"
                size="small"
                defaultValue="all"
                selected={filterCategory}
                onChange={(e) => {
                  handleCategorySearch(e.target.value);
                }}
                className={styles.selectBox}
                style={{marginRight:"10px"}}
              >
                <MenuItem value="all">All</MenuItem>
                {categories.map((category) => (
                  <MenuItem value={category.id}>{category.name}</MenuItem>
                ))}
                <MenuItem value="none">None</MenuItem>
              </TextField>
            </div>
          </div>
          <div className={styles.actionButtons}>
            {((user || {}).shop_permissions || {}).products_permission === USER_PERMISSIONS.READ ? (
              ''
            ) : (
              <div className='buttons'  style={{display:"flex"}}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={styles.actionBtn}
                  onClick={showCreateDialog}
                >
                  <CreateIcon className={styles.actionBtnIcon} />
                  NEW ITEM
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={styles.actionBtn}
                  style={{ backgroundColor: '#ff851b' }}
                >
                  <input
                    accept="text/csv"
                    type="file"
                    hidden
                    id="import"
                    onChange={(event) => {
                      const fileField = event.target;
                      const csvFile = fileField.files[0];
                      fileField.value = null;
                      handleImportCsv(csvFile);
                    }}
                  />
                  <ImportExportIcon className={styles.actionBtnIcon} />
                  <label for="import">IMPORT CSV</label>
                </Button>
              </div>
            )}

            <Button
              variant="contained"
              color="primary"
              size="small"
              className={styles.actionBtn}
              style={{ backgroundColor: '#605ca8' }}
              onClick={handleExportScale}
            >
              <ImportExportIcon className={styles.actionBtnIcon} />
              Export to scale
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              className={styles.actionBtn}
              style={{ backgroundColor: '#d81b60' }}
              onClick={handleGetExportData}
            >
              <ImportExportIcon className={styles.actionBtnIcon} />
              EXPORT TO CSV
            </Button>
          </div>
        </div>
      </div>

      {items &&
        (items.length ? (
          <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '4px' }}>
            <DataTable columns={headerData} rows={items || items} rowStyle={getRowStyle} />
            <Grid container>
              <Grid item xs={6} display="flex" justifyContent="start">
                <TablePagination
                  rowsPerPageOptions={[100, 200]}
                  component="div"
                  count={count}
                  rowsPerPage={limit}
                  page={page}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    '& .MuiTablePagination-actions': {
                      display: 'none',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end" pt={1}>
                <Pagination
                  count={Math.ceil(count / limit)}
                  page={page + 1}
                  color="primary"
                  onChange={handleChangePage}
                  defaultPage={1}
                />
              </Grid>
            </Grid>
          </Paper>
        ) : (
          !isShowLoading && (
            <Info
              title={'You have no item to current filter'}
              content={
                'there are no results that match your current filter configration. Please try a different filter combination to view the desired data. '
              }
            />
          )
        ))}

      {selectedItemForEdit && (
        <EditItemDialog
          handleClose={dismissEditDialog}
          item={selectedItemForEdit}
          items={items}
          categories={categories}
          onSuccess={getItems}
          itemCount={count}
        />
      )}

      {isShowAddItemDialog && (
        <AddItemDialog
          handleClose={dismissCreateDialog}
          items={items}
          categories={categories}
          onSuccess={getItems}
          itemCount={count}
        />
      )}
    </div>
  );
}

export default withConsoleBase(ItemsList);
