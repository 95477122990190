import React, { useEffect, useState, useContext } from 'react';
import styles from './styles.module.css';
import { Grid } from '@mui/material';
import { ROUTES, STORAGE_KEYS, REGISTRATION_TYPE, REGISTRATION_CHANNEL, BLOCKED_PASSWORDS } from '../../../const';
import AuthService from '../../../services/AuthService';
import { useHistory, Link, useLocation } from 'react-router-dom';
import Loader from '../../utils/Loading';
import { OnlinePurchaseContext } from '../../../Context/OnlinePurchaseContext';
import { isValidEmail } from '../../../helpers/StringHelper';
import TextFieldBGGray from '../../utils/CustomComponents/TextFieldBGGray';
import Sanoft_log from '../../../images/sanoft_logo_new.png';
import ButtonBlue from '../../utils/CustomComponents/ButtonBlue';

export default function NewAccount(props) {
  const { loadCountryCode, isLoading: isLoadingInPurchaseContext, setPurchaseData } = useContext(OnlinePurchaseContext);
  const [data, setData] = useState({});
  const [isLoading, setIsLoding] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const registration_type = queryParams.get('registration_type');
  const registration_channel = queryParams.get('registration_channel');

  const signupBtnPressed = async (e) => {
    setErrorMessage(null);

    if (!data.name || !data.username || !data.email || !data.first_name || !data.mobile || !data.password) {
      setErrorMessage('Required field should not be empty.\n Fill the form correctly.');
      return;
    }

    if (!isValidEmail(data.email)) {
      setErrorMessage('Invalid email provided');
      return;
    }

    if (BLOCKED_PASSWORDS.includes(data.password)) {
      setErrorMessage('Your password is too weak. Please choose a stronger one.');
      return;
    }

    if (registration_type === REGISTRATION_TYPE.NEW && registration_channel === REGISTRATION_CHANNEL.ONLINE) {
      setPurchaseData((prevState) => ({ ...prevState, shop_details: data }));
      history.push(ROUTES.PROCESS_PAYMENT);
      return;
    }

    setIsLoding(true);
    try {
      const signupData = {
        ...data,
        mac_id: localStorage.getItem(STORAGE_KEYS.MAC_ID) || null,
        registration_key: localStorage.getItem(STORAGE_KEYS.REGISTRSATION_KEY) || null,
      };
      const params = { registration_channel, registration_type };

      const error = await AuthService.createAccount(signupData, params);
      if (error) {
        setErrorMessage(error.message);
        return;
      }

      if (registration_type === REGISTRATION_TYPE.TRIAL || registration_channel === REGISTRATION_CHANNEL.KEY) {
        localStorage.removeItem(STORAGE_KEYS.REGISTRSATION_KEY);
        localStorage.removeItem(STORAGE_KEYS.MAC_ID);
        history.push(ROUTES.ONBOARDING);
        return;
      }
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsLoding(false);
    }
  };

  useEffect(() => {
    const getCountryConfig = async () => {
      const countryData = await loadCountryCode();
      setData((prev) => ({ ...prev, country: countryData?.code ?? null, currency: countryData?.currency ?? '$' }));
    };

    getCountryConfig();
    //eslint-disable-next-line
  }, []);

  return (
    <Grid className={styles.mainDiv}>
      <Loader isOpen={isLoading || isLoadingInPurchaseContext} backdropColor={'#ffffff'} />
      <Grid container className={styles.secondDiv}>
        <Grid item xs={12} className={styles.headerContainer}>
          <img src={Sanoft_log} alt="" className={styles.logo} />
        </Grid>
        <Grid item xs={12} className={styles.subContainer}>
          <h2>Start your free 14 day trial now.</h2>
          <h4 className={styles.priceNote}>No credit card. No commitment. Just fill this form to set up your trial.</h4>
        </Grid>
        <Grid item container xs={12} className={styles.formContainer}>
          <Grid item container xs={12} className={styles.formWrapper} rowSpacing={2.2}>
            <Grid item xs={12}>
              <TextFieldBGGray
                name="shopname"
                label="Shop Name"
                type="text"
                size="small"
                required
                fullWidth
                onChange={(event) => {
                  setData({ ...data, name: event.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                name="email"
                label="Email"
                type="email"
                size="small"
                required
                fullWidth
                onChange={(event) => {
                  setData({ ...data, email: event.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                name="firstname"
                label="Name"
                type="text"
                required
                color="secondary"
                size="small"
                fullWidth
                onChange={(event) => {
                  setData({
                    ...data,
                    first_name: event.target.value,
                    last_name: '-',
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                name="username"
                label="Username"
                type="text"
                size="small"
                required
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setData({ ...data, username: event.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                name="password"
                label="Password"
                type="password"
                size="small"
                required
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setData({ ...data, password: event.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                name="mobilenumber"
                label="Mobile Number"
                type="tel"
                size="small"
                required
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setData({ ...data, mobile: event.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12} className={styles.errorMsg}>
              {errorMessage && <span>{errorMessage}</span>}
            </Grid>
            <Grid item xs={12}>
              <ButtonBlue
                onClick={signupBtnPressed}
                disabled={isLoading}
                fullWidth
                style={{ padding: 6, color: '#ABABAB !important' }}
              >
                Signup
              </ButtonBlue>
            </Grid>
            <Grid item xs={12} className={styles.forgotPasswordWrapper}>
              <Link to={ROUTES.LOGIN} className={styles.forgotPassword}>
                Login
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
