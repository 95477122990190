import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import styles from './styles.module.css';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import InventoryIcon from '@mui/icons-material/Inventory';
import PeopleIcon from '@material-ui/icons/People';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DevicesIcon from '@mui/icons-material/Devices';
import { Grid } from '@material-ui/core';
import { USER_PERMISSIONS, ROUTES } from '../../../const';
import { selectedTabContext } from '../../../Context/SelectedTabContext';
import { UserContext } from '../../../Context/UserContext';
import ReceiptIcon from '@mui/icons-material/Receipt';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import LayersIcon from '@mui/icons-material/Layers';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import CategoryIcon from '@mui/icons-material/Category';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import GroupsIcon from '@mui/icons-material/Groups';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Divider from '@mui/material/Divider';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';

export default function NavBar() {
  const { selectedTab, setSelectedTab, sections, setSections, selectedSubTab, setSelectedSubTab } =
    useContext(selectedTabContext);
  const { user } = useContext(UserContext);

  const tabStyle = {
    color: '#fff',
  };

  const handleSelectedTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleChangeSections = (tab) => {
    setSections((prev) => ({ ...prev, [tab]: !prev[tab] }));
  };

  const handleLinkClick = (route) => {
    setSelectedSubTab(route);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {((user || {}).shop_permissions || {}).reports_permission === USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link to={ROUTES.DASHBOARD} style={{ textDecorationColor: 'transparent' }}>
            <div
              className={`${styles.navSectionItem} ${styles.mt - 1}`}
              onClick={() => {
                handleChangeSections('dashboard');
              }}
              style={{ marginTop: '10px' }}
            >
              <span className={styles.title}>Dashboard</span>
            </div>
          </Link>
        )}

        <Divider
          sx={{
            backgroundImage: 'linear-gradient(to right, transparent, rgba(255, 255, 255, 0.6), transparent)',
            height: '1px',
            border: 'none',
          }}
        />

        <div
          className={`${styles.navSectionItem} ${styles.mt - 1}`}
          onClick={() => {
            handleChangeSections('reports');
          }}
        >
          <span className={styles.title}>Reports</span>
          {sections.reports ? <RemoveIcon className={styles.icons} /> : <AddIcon className={styles.icons} />}
        </div>
        {sections?.reports && (
          <>
            {((user || {}).shop_permissions || {}).reports_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <div
                className={`${styles.navItem} ${styles.mt - 1}`}
                style={{
                  ...tabStyle,
                  ...(selectedTab === 'salesTab' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                }}
                onClick={() => {
                  setSections((prev) => ({
                    ...prev,
                    purchaseTab: false,
                    TaxTab: false,
                    salesTab: !prev.salesTab,
                  }));
                  setSelectedTab('salesTab');
                }}
              >
                <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                  <AssignmentIcon fontSize="small" className={styles.navBarIconColor} />
                </span>
                <span className={styles.title}>Sales Reports</span>
                <span className={styles.icons}>
                  {sections.salesTab ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </span>
              </div>
            )}
            {sections.salesTab && (
              <div className={styles.subMenuContainer}>
                <div className={styles.subMenuItems}>
                  <div>
                    <div
                      className={styles.navBarStepper}
                      style={{ height: '522px', borderBottom: 'none', borderBottomLeftRadius: '0' }}
                    ></div>
                    <div className={styles.navBarStepper}></div>
                    <div>
                      <Link
                        to={ROUTES.BILL_SUMMARY}
                        style={{ textDecorationColor: 'transparent' }}
                        onClick={() => handleLinkClick(ROUTES.BILL_SUMMARY)}
                      >
                        <div
                          className={styles.subMenu}
                          style={
                            selectedSubTab === ROUTES.BILL_SUMMARY
                              ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                              : {}
                          }
                        >
                          <span>Sales Summary</span>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.ITEMISED_BILL}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.ITEMISED_BILL)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.ITEMISED_BILL
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Sales Items</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.ITEM_SUMMARY}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.ITEM_SUMMARY)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.ITEM_SUMMARY
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Item Summary</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.DAY_WISE_REPORT}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.DAY_WISE_REPORT)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.DAY_WISE_REPORT
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Day Wise Report</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.STAFF_SALES_REPORT}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.STAFF_SALES_REPORT)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.STAFF_SALES_REPORT
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Staff Sales Report</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.CATEGORY_SALES_REPORT}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.CATEGORY_SALES_REPORT)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.CATEGORY_SALES_REPORT
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Category Sales Report</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.ORDER_TYPE_SALES_REPORT}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.ORDER_TYPE_SALES_REPORT)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.ORDER_TYPE_SALES_REPORT
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Order Type Report</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.RETURNED_BILL_SUMMARY}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.RETURNED_BILL_SUMMARY)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.RETURNED_BILL_SUMMARY
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Returned Sales Summary</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.RETURNED_ITEM_SUMMARY}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.RETURNED_ITEM_SUMMARY)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.RETURNED_ITEM_SUMMARY
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Returned Item Summary</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.RETURNED_SALE_ITEM_SUMMARY}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.RETURNED_SALE_ITEM_SUMMARY)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.RETURNED_SALE_ITEM_SUMMARY
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Returned Sale Item</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.SHIFT_SUMMARY}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.SHIFT_SUMMARY)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.SHIFT_SUMMARY
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Shift Summary</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.PAYMENT_MODE_REPORT}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.PAYMENT_MODE_REPORT)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.PAYMENT_MODE_REPORT
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Payments Summary</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.PROFIT_BILL_SUMMARY}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.PROFIT_BILL_SUMMARY)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.PROFIT_BILL_SUMMARY
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Profit Sales Summary</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.PROFIT_ITEMISED_BILL}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.PROFIT_ITEMISED_BILL)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.PROFIT_ITEMISED_BILL
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Profit Sales Items</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.PROFIT_ITEM_SUMMARY}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.PROFIT_ITEM_SUMMARY)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.PROFIT_ITEM_SUMMARY
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Profit Item Summary</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.PROFIT_DAY_WISE_REPORT}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.PROFIT_DAY_WISE_REPORT)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.PROFIT_DAY_WISE_REPORT
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Profit Day Wise Report</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {((user || {}).shop_permissions || {}).reports_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <div
                className={`${styles.navItem} ${styles.mt - 1}`}
                style={{
                  ...tabStyle,
                  ...(selectedTab === 'purchaseTab' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                }}
                onClick={() => {
                  setSections((prev) => ({
                    ...prev,
                    salesTab: false,
                    purchaseTab: !prev.purchaseTab,
                    TaxTab: false,
                  }));
                  setSelectedTab('purchaseTab');
                }}
              >
                <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                  <WysiwygIcon fontSize="small" className={styles.navBarIconColor} />
                </span>
                <span className={styles.title}>Purchase Reports</span>
                <span className={styles.icons}>
                  {sections.purchaseTab ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </span>
              </div>
            )}
            {sections.purchaseTab && (
              <div className={styles.subMenuContainer}>
                <div className={styles.subMenuItems}>
                  <div>
                    <div className={styles.navBarStepper} style={{ height: '86px' }}></div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.PURCHASE_SUMMARY}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.PURCHASE_SUMMARY)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.PURCHASE_SUMMARY
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Purchase Summary</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.PURCHASE_ITEM_SUMMARY}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.PURCHASE_ITEM_SUMMARY)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.PURCHASE_ITEM_SUMMARY
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Purchase Items Summary</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={ROUTES.VENDON_PURCHASE_SUMMARY}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.VENDON_PURCHASE_SUMMARY)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.VENDON_PURCHASE_SUMMARY
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Vendor Purchase Summary</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {((user || {}).shop_permissions || {}).reports_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <div
                className={`${styles.navItem} ${styles.mt - 1}`}
                style={{
                  ...tabStyle,
                  ...(selectedTab === 'TaxTab' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                }}
                onClick={() => {
                  setSections((prev) => ({
                    ...prev,
                    salesTab: false,
                    purchaseTab: false,
                    TaxTab: !prev.TaxTab,
                  }));
                  setSelectedTab('TaxTab');
                }}
              >
                <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                  <ReceiptIcon fontSize="small" className={styles.navBarIconColor} />
                </span>
                <span className={styles.title}>Tax Reports</span>
                <span className={styles.icons}>
                  {sections.TaxTab ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </span>
              </div>
            )}
            {sections.TaxTab && (
              <div className={styles.subMenuContainer}>
                <div className={styles.subMenuItems}>
                  <div>
                    <div className={styles.navBarStepper} style={{ height: '86px' }}></div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.SALES_TAX_SUMMARY}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.SALES_TAX_SUMMARY)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.SALES_TAX_SUMMARY
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Sales Tax Summary</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className={styles.navBarStepper}></div>
                    <Link
                      to={ROUTES.PURCHASE_TAX_SUMMARY}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.PURCHASE_TAX_SUMMARY)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.PURCHASE_TAX_SUMMARY
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>Purchase Tax Summary</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={ROUTES.HSN_SUMMARY}
                      style={{ textDecorationColor: 'transparent' }}
                      onClick={() => handleLinkClick(ROUTES.HSN_SUMMARY)}
                    >
                      <div
                        className={styles.subMenu}
                        style={
                          selectedSubTab === ROUTES.HSN_SUMMARY
                            ? { backgroundColor: '#5C62A8', borderRadius: '10px' }
                            : {}
                        }
                      >
                        <span>HSN Summary</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {((user || {}).shop_permissions || {}).stock_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.STOCK_REPORT} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={`${styles.navItem} ${styles.mt - 4}`}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'stock-report' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('stock-report');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <SyncAltIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Stock Reports</span>
                </div>
              </Link>
            )}
          </>
        )}

        <Divider
          sx={{
            backgroundImage: 'linear-gradient(to right, transparent, rgba(255, 255, 255, 0.6), transparent)',
            height: '1px',
            border: 'none',
          }}
        />

        <div
          className={`${styles.navSectionItem} ${styles.mt - 1}`}
          onClick={() => {
            handleChangeSections('operations');
          }}
        >
          <span className={styles.title}>Operations</span>
          {sections.operations ? <RemoveIcon className={styles.icons} /> : <AddIcon className={styles.icons} />}
        </div>
        {sections?.operations && (
          <>
            {((user || {}).shop_permissions || {}).purchase_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.PURCHASE_HISTORY} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'purchase-history' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('purchase-history');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <ShoppingCartIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Purchases</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).purchase_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.PURCHASE_RETURN_HISTORY} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'purchase-return' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('purchase-return');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <RemoveShoppingCartIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Purchase Returns</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).purchase_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.VENDORS} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'vendors' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('vendors');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <InventoryIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Vendors</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).expense_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.PRODUCTION} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'production' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('production');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <SoupKitchenIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Production</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).expense_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.EXPENSES} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'expenses' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('expenses');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <CreditCardIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Expenses</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).damage_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.DAMAGE_HISTORY} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={`${styles.navItem} ${styles.mt - 4}`}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'damage-history' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('damage-history');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <AssessmentIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Stock Damages</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).reports_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.ORDER_ACTIONS} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'order-actions' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('order-actions');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <LayersIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Order Actions</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).products_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.CUSTOMERS} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'customers' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('customers');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <PeopleIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Customers</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).purchase_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.CUSTOMER_GROUP} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'customer-group' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('customer-group');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <GroupsIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Customer Groups</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).purchase_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.MANAGE_SALES} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'sales-history' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('sales-history');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <ShoppingBasketIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Sales</span>
                </div>
              </Link>
            )}
          </>
        )}

        <Divider
          sx={{
            backgroundImage: 'linear-gradient(to right, transparent, rgba(255, 255, 255, 0.6), transparent)',
            height: '1px',
            border: 'none',
          }}
        />

        <div
          className={`${styles.navSectionItem} ${styles.mt - 1}`}
          style={
            {
              // ...(sections?.management ? tabStyleActive : tabStyle),
              // borderBottom: sections?.management ? '1px solid rgb(255, 255, 255)' : 'none',
            }
          }
          onClick={() => {
            handleChangeSections('management');
          }}
        >
          <span className={styles.title}>Management</span>
          {sections.management ? <RemoveIcon className={styles.icons} /> : <AddIcon className={styles.icons} />}
        </div>
        {sections?.management && (
          <>
            {((user || {}).shop_permissions || {}).products_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.ITEMS} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={`${styles.navItem} ${styles.mt - 4}`}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'products' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('products');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <ShoppingBagIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Products</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).devices_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.CATEGORIES} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={`${styles.navItem} ${styles.mt - 4}`}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'categories' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('categories');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <CategoryIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Categories</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).devices_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.SALES_PERSON} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={`${styles.navItem} ${styles.mt - 4}`}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'salesperson' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('salesperson');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <PersonIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Salespersons</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).devices_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.TERMINALS} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={`${styles.navItem} ${styles.mt - 4}`}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'terminals' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('terminals');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <DevicesIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Terminals</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).shop_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.SETTINGS} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={`${styles.navItem} ${styles.mt - 4}`}
                  style={{
                    ...tabStyle,
                    ...(selectedTab === 'settings' && { backgroundColor: '#5C62A8', borderRadius: '10px' }),
                  }}
                  onClick={() => {
                    handleSelectedTabChange('settings');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <SettingsIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Settings</span>
                </div>
              </Link>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
