import BackendService from './BackendService';

async function initiatePayment(data = {}) {
  return BackendService.postData('/payments/', data);
}

async function paymentStatus(id, params = {}) {
  return BackendService.getData(`/payments/${id}/status/`, {}, params);
}

const PaymentService = {
  initiatePayment,
  paymentStatus,
};

export default PaymentService;
