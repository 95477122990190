import React, { useState, useEffect, useCallback, useContext } from 'react';
import styles from './styles.module.css';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';
import CustomerService from '../../../services/CustomerService';
import AlertHelper from '../../../helpers/AlertHelper';
import setDelay from '../../../helpers/LoadingDelay';
import Loader from '../../utils/Loading';
import { useParams, useHistory } from 'react-router-dom';
import Error from '../../utils/Alert/Error';
import Success from '../../utils/Alert/Success';
import DataTable from '../../utils/DataTable';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { Grid, Typography, Button } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import SyncIcon from '@mui/icons-material/Sync';
import { Paper, TablePagination } from '@mui/material';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import CustomerSaleDialog from '../../popups/CustomerSaleDialog';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import AddPaymentDialog from '../../popups/AddPaymentPopup';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CsvHelper from '../../../helpers/CsvHelper';
import { AlertMessagesContext } from 'react-alert-messages';
import { saveAs } from 'file-saver';
import { ShopContext } from '../../../Context/ShopContext';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import ShopsHelper from '../../../helpers/ShopsHelper';
import Grayout from '../../utils/GrayOut/Grayout';
import QrCodeIcon from '@mui/icons-material/QrCode';
import GenerateCustomerQrDialog from '../../popups/GenerateCustomerQrDialog';
import ClearIcon from '@mui/icons-material/Clear';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { ROUTES } from '../../../const';
import { Tooltip, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

const getMaxDate = (date) => {
  const maxDate = new Date(date);
  maxDate.setHours(23, 59, 59);
  return new Date(maxDate);
};

function CustomerDetails() {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { shop } = useContext(ShopContext);
  const history = useHistory();

  const [unsetData, setUnsetData] = useState(null);
  const [loadingIndicator, setLoadingIndicator] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [customerJournals, setCustomerJournals] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [isEnableSearchRefreshButton, setIsEnableSearchRefreshButton] = useState(true);
  const [customerJournalsPagination, setCustomerJournalsPagination] = useState({
    page: 0,
    offset: 0,
    limit: 20,
    count: 0,
  });
  const [loyaltyJournalsPagination, setLoyaltyJournalsPagination] = useState({
    page: 0,
    offset: 0,
    limit: 20,
    count: 0,
  });
  const [fromDate, setFromDate] = useState(new Date(new Date().setDate(new Date().getDate() - 5)));
  const [toDate, setToDate] = useState(getMaxDate(new Date()));
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(false);
  const [prevFilterData, setPrevFilterData] = useState({
    fromDate,
    toDate,
  });
  const [selectedSale, setSelectedSale] = useState(null);
  const [paymentTab, setPaymentTab] = useState(false);
  // const [loyaltyJournals, setLoyaltyJournals] = useState(null);
  const [showCustomerQr, setShowCustomerQr] = useState(false);

  const { customerId } = useParams();

  const CSV_COLUMNS = {
    id: 'Id',
    sale_id: 'Sale Id',
    date: {
      title: 'Date',
      getValue: (item) => {
        if (!item || !item.time) {
          return '';
        }
        const date = new Date(item.time);
        return DateTimeHelpers.convertDateToIsoDMY(date);
      },
    },
    time: {
      title: 'Time',
      getValue: (item) => {
        if (!item || !item.time) {
          return '';
        }
        const date = item.time.split('T')[1].split('.')[0];
        return DateTimeHelpers.formatServerTimeSegment(date);
      },
    },
    description: 'Description',
    debit: {
      title: 'Debit',
      getValue: (item) => {
        return ShopsHelper.getAmountFormatted(shop, item?.debit || 0);
      },
    },
    credit: {
      title: 'Credit',
      getValue: (item) => {
        return ShopsHelper.getAmountFormatted(shop, item?.credit || 0);
      },
    },
    balance: {
      title: 'Balance',
      getValue: (item) => {
        return ShopsHelper.getAmountFormatted(shop, item?.balance || 0);
      },
    },
  };

  const toCsvBtnPressed = async () => {
    const csv = CsvHelper.getString(customerJournals, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `transactions-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const handleEditSaleClick = (data) => history.push(`${ROUTES.MANAGE_SALES}?id=${data.sale_id}`);
  const handleUnsetSaleClick = (data) => setUnsetData(data);
  const handleUnsetSale = async (journal) => {
    setLoadingIndicator(true);
    try {
      await CustomerService.removeCustomerFromSale(journal.sale_id);
      getCustomerJournals();
      AlertHelper.setShortMessage(setSuccessMsg, 'Sales unassigned successufully');
    } catch (err) {
      AlertHelper.setShortMessage(setErrorMsg, err.message);
    }
    setDelay(setLoadingIndicator);
  };

  const getBalance = (data) => {
    const balance = ShopsHelper.getAmountFormatted(shop, data?.balance);
    let balanceStyle;
    if (balance >= 0) balanceStyle = { color: 'green' };
    else balanceStyle = { color: 'red' };
    return (
      <Typography variant="body2" style={balanceStyle} gutterBottom>
        {balance}
      </Typography>
    );
  };

  const getHeaderData = (type) => {
    const headerData = [
      {
        label: 'Date',
        id: 'Date',
        type: 'callback',
        viewRender: (item) => {
          if (!item || !item.time) {
            return '';
          }
          const date = new Date(item.time);
          return DateTimeHelpers.convertDateToIsoDMY(date);
        },
      },
      {
        label: 'Time',
        id: 'time',
        type: 'callback',
        viewRender: (item) => {
          if (!item || !item.time) {
            return '';
          }
          const date = item.time.split('T')[1].split('.')[0];
          return DateTimeHelpers.formatServerTimeSegment(date);
        },
      },
      {
        label: 'Description',
        id: 'description',
        type: 'text',
      },
      {
        label: 'Credit',
        id: 'credit',
        type: 'callback',
        viewRender: (item) => {
          return ShopsHelper.getAmountFormatted(shop, item?.credit || 0);
        },
      },
      {
        label: 'Debit',
        id: 'debit',
        type: 'callback',
        viewRender: (item) => {
          return ShopsHelper.getAmountFormatted(shop, item?.debit || 0);
        },
      },
      {
        label: 'Balance',
        id: 'balance',
        type: 'callback',
        viewRender: (data) => getBalance(data),
      },
    ];

    if (type === 'customerLog') {
      headerData.push({
        label: 'Actions',
        id: 'unset_sale',
        type: 'callback',
        viewRender: (row) => {
          if (row?.sale_id) {
            return (
              <>
                <Tooltip title="View Details">
                  <IconButton
                    onClick={(e) => {
                      toggleViewSale(row);
                      e.stopPropagation();
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Unset sale">
                  <IconButton
                    onClick={(e) => {
                      handleUnsetSaleClick(row);
                      e.stopPropagation();
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit sale">
                  <IconButton
                    onClick={(e) => {
                      handleEditSaleClick(row);
                      e.stopPropagation();
                    }}
                  >
                    <ModeEditOutlineIcon />
                  </IconButton>
                </Tooltip>
              </>
            );
          }
          return null;
        },
      });
    }

    return headerData;
  };

  const getCustomerJournals = async ({
    _fromDate = fromDate,
    _toDate = toDate,
    _offset = customerJournalsPagination.offset,
    _limit = customerJournalsPagination.limit,
  } = {}) => {
    setLoadingIndicator(true);
    try {
      const timezoneFreeStartTime = new Date(_fromDate.getTime() - _fromDate.getTimezoneOffset() * 60000);
      const timezoneFreeEndTime = new Date(_toDate.getTime() - _toDate.getTimezoneOffset() * 60000);

      const params = {
        start_time: timezoneFreeStartTime,
        end_time: timezoneFreeEndTime,
        offset: _offset,
        limit: _limit,
        search: filterText,
      };

      const res = await CustomerService.getCustomerJournals(customerId, params);
      let journals = [...res?.data];
      const firstJournal = journals[0];
      if (firstJournal) {
        journals = [
          {
            description: 'Opening balance',
            credit: '',
            debit: '',
            balance: firstJournal.balance - firstJournal.credit + firstJournal.debit,
          },
          ...journals,
        ];
      }
      setCustomerJournals(journals);
      setCustomerJournalsPagination({
        ...customerJournalsPagination,
        count: res?.count,
      });
      setIsEnableSearchRefreshButton(false);
    } catch (error) {
      AlertHelper.setShortMessage(setErrorMsg, error.message);
    }
    setLoadingIndicator(false);
  };

  // const getCustomerLoyaltyLogs = async ({
  //   _fromDate = fromDate,
  //   _toDate = toDate,
  //   _offset = loyaltyJournalsPagination.offset,
  //   _limit = loyaltyJournalsPagination.limit,
  // } = {}) => {
  //   setLoadingIndicator(true);
  //   try {
  //     const params = {
  //       start_time: _fromDate,
  //       end_time: _toDate,
  //       offset: _offset,
  //       limit: _limit,
  //       search: filterText,
  //     };
  //     const res = await CustomerService.getCustomerLoyaltyLogs(customerId, params);
  //     setLoyaltyJournals(res?.data);
  //     setLoyaltyJournalsPagination({
  //       ...loyaltyJournalsPagination,
  //       count: res?.count,
  //     });
  //     setIsEnableSearchRefreshButton(false);
  //   } catch (error) {
  //     AlertHelper.setShortMessage(setErrorMsg, error.message);
  //   }
  //   setLoadingIndicator(false);
  // };

  const getCustomerDetails = async () => {
    try {
      const res = await CustomerService.getCustomer(customerId);
      setCustomer(res);
    } catch (error) {
      AlertHelper.setShortMessage(setErrorMsg, error.message);
    }
  };

  const togglePaymentTab = () => setPaymentTab((prevValue) => !prevValue);

  const toggleViewSale = async (row) => {
    if (!row?.sale_id) {
      return setSelectedSale(null);
    }
    setLoadingIndicator(true);
    try {
      const res = await CustomerService.getCustomerSaleDetails(row?.sale_id);
      setSelectedSale(res);
    } catch (error) {
      AlertHelper.setShortMessage(setErrorMsg, error.message);
    }
    setLoadingIndicator(false);
  };

  const appPayment = async (data) => {
    setLoadingIndicator(true);
    try {
      await CustomerService.addPaymentToCustomer(customerId, data);
      togglePaymentTab();
      setCustomerJournalsPagination({
        ...customerJournalsPagination,
        page: 0,
        offset: 0,
      });
      getCustomerDetails();
      getCustomerJournals({ _offset: 0 });
      AlertHelper.setShortMessage(setSuccessMsg, 'Payment added successfully');
    } catch (error) {
      AlertHelper.setShortMessage(setErrorMsg, error.message);
    }
    setLoadingIndicator(false);
  };

  const handleChangeCustomerPage = (event, newPage) => {
    setCustomerJournalsPagination({
      ...customerJournalsPagination,
      page: newPage,
    });
    let offset = newPage * customerJournalsPagination.limit;
    setCustomerJournalsPagination({ ...customerJournalsPagination, offset });
    getCustomerJournals({ _offset: offset });
  };

  const handleChangeCustomerRowsPerPage = (event) => {
    const pageRowLimit = event.target.value;
    setCustomerJournalsPagination({
      ...customerJournalsPagination,
      limit: pageRowLimit,
    });
    getCustomerJournals({ _limit: pageRowLimit });
  };

  // const handleChangeLoyltyrPage = (event, newPage) => {
  //   setLoyaltyJournalsPagination({
  //     ...loyaltyJournalsPagination,
  //     page: newPage,
  //   });
  //   let offset = newPage * loyaltyJournalsPagination.limit;
  //   setLoyaltyJournalsPagination({ ...loyaltyJournalsPagination, offset });
  //   getCustomerLoyaltyLogs({ _offset: offset });
  // };

  // const handleChangeLoyltyRowsPerPage = (event) => {
  //   const pageRowLimit = event.target.value;
  //   setLoyaltyJournalsPagination({
  //     ...loyaltyJournalsPagination,
  //     limit: pageRowLimit,
  //   });
  //   getCustomerLoyaltyLogs({ _limit: pageRowLimit });
  // };

  const handleSearch = () => {
    setCustomerJournalsPagination({
      ...customerJournalsPagination,
      page: 0,
      offset: 0,
    });
    setLoyaltyJournalsPagination({
      ...loyaltyJournalsPagination,
      page: 0,
      offset: 0,
    });
    getCustomerJournals({ _offset: 0 });
    // getCustomerLoyaltyLogs({ _offset: 0 });
  };

  const generateCustomerDetails = () => {
    getCustomerJournals();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      fromDate,
      toDate,
    });
  };

  const isAnyChangeOnCustomerDetailsFilters = useCallback(() => {
    return fromDate !== prevFilterData.fromDate || toDate !== prevFilterData.toDate;
  }, [fromDate, toDate, prevFilterData.fromDate, prevFilterData.toDate]);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnCustomerDetailsFilters());
  }, [isAnyChangeOnCustomerDetailsFilters, isShowGenerateButton]);

  useEffect(() => {
    getCustomerDetails();
    getCustomerJournals();
    // getCustomerLoyaltyLogs();
    //eslint-disable-next-line
  }, [customerId]);

  return (
    <div className={styles.contentWrapper}>
      <ConfirmPopup
        data={unsetData}
        handleClose={handleUnsetSaleClick}
        handleOkay={handleUnsetSale}
        alertTitle={'Confirm unset'}
        successBtnName={'confirm'}
        alertContent={"Are you sure you want to remove this sale from the customer's records?"}
      />
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Transaction<span className={styles.menuTitle}>Details & History</span>
        </h2>
        {shop && shop?.customer_support_validity !== null && (
          <>
            <div style={{ justifyContent: 'flex-end' }}>
              <div style={{ paddingBottom: '4px' }}>
                <label className={styles.label}>Export As</label>
              </div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={toCsvBtnPressed}
              >
                <ImportExportIcon className={styles.actionBtnIcon} />
                CSV
              </Button>
            </div>
            <div style={{ justifyContent: 'flex-end', marginLeft: 10 }}>
              <div style={{ paddingBottom: '4px' }}>
                <label className={styles.label}>Generate QR</label>
              </div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#d5d5d5' }}
                onClick={() => {
                  setShowCustomerQr(customer);
                }}
              >
                <QrCodeIcon style={{ fontSize: 21 }} />
              </Button>
            </div>
          </>
        )}
      </div>
      {shop && shop.customer_support_validity == null && (
        <RestrictionInfo
          title={'Customer Support Feature not available '}
          content={'To get Customer support options, contact support team.'}
        />
      )}
      {shop && shop.customer_support_validity !== null && (
        <>
          <div className={styles.changeable}>
            <div className={styles.filterSub}>
              <div className={styles.headTitle}>
                <h2 className={styles.subTitle}>Transactions Details</h2>
              </div>
              <div className={styles.filerInputSec}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{ backgroundColor: '#ff851b' }}
                  className={styles.actionBtn}
                  onClick={togglePaymentTab}
                >
                  <RequestQuoteOutlinedIcon className={styles.actionBtnIcon} />
                  Add Payment
                </Button>
                <div className={styles.searchSec}>
                  <input
                    type="text"
                    value={filterText}
                    onChange={(e) => {
                      setFilterText(e.target.value);
                      setIsEnableSearchRefreshButton(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch();
                      }
                    }}
                    className={styles.searchInput}
                    placeholder="Search transactions"
                  />
                  <Tooltip title="Refresh">
                    <IconButton
                      className={styles.iconBtn}
                      onClick={handleSearch}
                      disabled={!isEnableSearchRefreshButton}
                    >
                      <SyncIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className={styles.actionButtons}>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>From</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-from"
                      max={new Date()}
                      className={styles.dateBox}
                      value={fromDate}
                      onChange={(date) => {
                        setFromDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>To</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-to"
                      className={styles.dateBox}
                      max={new Date()}
                      value={toDate}
                      onChange={(date) => {
                        setToDate(getMaxDate(date));
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>Generate Report</label>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.actionBtn}
                    style={{ backgroundColor: '#00a65a' }}
                    onClick={generateCustomerDetails}
                    disabled={isShowGenerateButton === false}
                  >
                    <SyncIcon className={styles.actionBtnIcon} />
                    Generate
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.changeable} style={{ paddingBottom: '15px' }}>
            <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
              <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Name</h3>
              </Grid>
              <Grid item xs={4} sm={4}>
                <h3 className={styles.inputLabel}>{customer?.name || '-'}</h3>
              </Grid>
              <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Balance</h3>
              </Grid>
              <Grid item xs={4} sm={4}>
                <h3 className={styles.inputLabel}>{ShopsHelper.getAmountFormatted(shop, customer?.balance || 0)}</h3>
              </Grid>
            </Grid>
            <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
              <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Mobile</h3>
              </Grid>
              <Grid item xs={4} sm={4}>
                <h3 className={styles.inputLabel}>{customer?.mobile || '-'}</h3>
              </Grid>
              <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Credit Limit</h3>
              </Grid>
              <Grid item xs={4} sm={4}>
                <h3 className={styles.inputLabel}>{customer?.credit_limit || 0}</h3>
              </Grid>
            </Grid>
            <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
              <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Email</h3>
              </Grid>
              <Grid item xs={4} sm={4}>
                <h3 className={styles.inputLabel}>{customer?.email || '-'}</h3>
              </Grid>
              <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Points</h3>
              </Grid>
              <Grid item xs={4} sm={4}>
                <h3 className={styles.inputLabel}>{customer?.points || 0}</h3>
              </Grid>
            </Grid>
            <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
              <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Location</h3>
              </Grid>
              <Grid item xs={4} sm={4}>
                <h3 className={styles.inputLabel}>{customer?.location || '-'}</h3>
              </Grid>
              <Grid item xs={2} sm={2} className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Loyalty Card No</h3>
              </Grid>
              <Grid item xs={4} sm={4}>
                <h3 className={styles.inputLabel}>{customer?.loyalty_card_no || '-'}</h3>
              </Grid>
            </Grid>
          </div>
          {errorMsg && (
            <div className={styles.marginTop}>
              <Error title={errorMsg} />
            </div>
          )}
          {successMsg && (
            <div className={styles.marginTop}>
              <Success title={successMsg} />
            </div>
          )}

          {/* TRANSACTOINS SECTION */}
          <Grid className={styles.changeable}>
            <div className={styles.headTitle} style={{ marginBottom: '10px' }}>
              <span className={styles.subTitle}>Transaction History</span>
            </div>
            {customerJournals && customerJournals.length ? (
              <>
                <Paper className={styles.tableWrapper}>
                  <div className={styles.tableContainer}>
                    <Grayout open={isShowGenerateButton} />
                    <DataTable
                      columns={getHeaderData('customerLog')}
                      rows={customerJournals}
                    />
                  </div>
                  <TablePagination
                    rowsPerPageOptions={[20, 50]}
                    component="div"
                    count={customerJournalsPagination.count}
                    rowsPerPage={customerJournalsPagination.limit}
                    page={customerJournalsPagination.page}
                    onPageChange={handleChangeCustomerPage}
                    onRowsPerPageChange={handleChangeCustomerRowsPerPage}
                  />
                </Paper>
              </>
            ) : (
              !loadingIndicator && (
                <Info
                  title={'No transactions to list'}
                  content={
                    'You have no transactions to list with current filter configuration. Please clear the filters or make some sale'
                  }
                />
              )
            )}
          </Grid>

          {/* LOYALTY POINT SECTION */}
          {/* <Grid className={styles.changeable}>
            <div className={styles.headTitle}>
              <span className={styles.subTitle}>Loyalty Points History</span>
            </div>
            {loyaltyJournals && loyaltyJournals.length ? (
              <>
                <Paper className={styles.tableWrapper}>
                  <div className={styles.tableContainer}>
                    <Grayout open={isShowGenerateButton} />
                    <DataTable
                      columns={() => getHeaderData('loyaltyLog')}
                      rows={loyaltyJournals}
                      rowClickListener={toggleViewSale}
                    />
                  </div>
                  <TablePagination
                    rowsPerPageOptions={[20, 50]}
                    component="div"
                    count={loyaltyJournalsPagination.count}
                    rowsPerPage={loyaltyJournalsPagination.limit}
                    page={loyaltyJournalsPagination.page}
                    onPageChange={handleChangeLoyltyrPage}
                    onRowsPerPageChange={handleChangeLoyltyRowsPerPage}
                  />
                </Paper>
              </>
            ) : (
              !loadingIndicator && (
                <Info
                  title={'No loyalty history to list'}
                  content={
                    'You have no loyalty history to list with current filter configuration. Please clear the filters or make some sale'
                  }
                />
              )
            )}
          </Grid> */}

          {selectedSale && <CustomerSaleDialog toggleItemsTab={toggleViewSale} rowData={selectedSale} />}
          {paymentTab && (
            <AddPaymentDialog
              togglePaymentTab={togglePaymentTab}
              addPaymentToCustomer={appPayment}
              currentBalance={customer?.balance || 0}
            />
          )}
          {showCustomerQr && (
            <GenerateCustomerQrDialog
              customer={customer}
              onClose={() => {
                setShowCustomerQr(false);
              }}
            />
          )}
        </>
      )}
    </div>
  );
}

export default withConsoleBase(CustomerDetails);
