import {
  Button,
  CardMedia,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { DialogActions, Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useCallback, useContext, useState, useEffect } from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import ItemService from '../../services/ItemService';
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import RestrictionInfo from '../utils/Alert/RestrictionInfo';
import { ShopContext } from '../../Context/ShopContext';
import { STORAGE_KEYS, SUBSCRIPTION_TYPE, ITEM_TYPES } from '../../const';
import SelectShopsDialog from './SelectShopsDialog';
import ShopService from '../../services/ShopService';
import Loader from '../utils/Loading';
import { AlertMessagesContext } from 'react-alert-messages';
import DeleteIcon from '@mui/icons-material/Delete';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function EditItemDialog(props) {
  const { handleClose, item, items, categories, onSuccess, itemCount } = props;
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [data, setData] = useState(item);
  const [imageData, setImageData] = useState(null);
  const [showPluTakenError, setShowPluTakenError] = useState(false);
  const [isShowSelectShopsDialog, setIsShowSelectShopsDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [itemAdvancedExisting, setItemAdvancedExisting] = useState({});
  const [itemAdvanced, setItemAdvanced] = useState({});
  const [loading, setLoading] = useState(false);
  const [subItem, setSubItem] = useState({});
  const [openSearchList, setOpenSearchList] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchItems, setSearchItems] = useState([]);

  const subscriptionType = shop?.subscription?.type || '';

  const readImageFile = (imageFile) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onloadend = async () => {
        resolve(reader.result);
      };
    });
  };

  const removeSubItem = (subItem) => {
    setData((prevData) => {
      const newSubItems = (prevData?.details?.sub_items || []).filter((item) => item.item_id !== subItem.item_id);

      const updatedDetails = {
        ...prevData?.details,
        sub_items: newSubItems,
      };

      return {
        ...prevData,
        details: updatedDetails,
      };
    });
  };

  const addSubItem = () => {
    setData((prevData) => ({
      ...prevData,
      details: {
        ...prevData?.details,
        sub_items: [...(prevData?.details?.sub_items || []), subItem],
      },
    }));
    setSubItem({});
    setSearchText('');
  };

  const fetchItems = useCallback(async () => {
    setLoading(true);

    if (searchText.trim()) {
      const response = await ItemService.getItemsResponse({ search: searchText });
      setSearchItems(response.data || []);
    }

    setLoading(false);
  }, [searchText]);

  const loadItemAdvancedDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const itemAdvanced = await ItemService.getItemAdvanced(item.id);
      setItemAdvanced(itemAdvanced);
      setItemAdvancedExisting(itemAdvanced);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setIsLoading(false);
  }, [setItemAdvanced, item.id, postAlertMessage]);

  const hasAnyUpdate = () => {
    return (
      Object.keys(getRequestDataToUpdate()).length > 0 ||
      Object.keys(getRequestAdvancedDataToUpdate()).length > 0 ||
      imageData
    );
  };

  const getRequestDataToUpdate = () => {
    const requestData = {};
    if (String(item.category) !== String(data.category)) {
      requestData.category = data.category;
    }
    if (String(item.plu) !== String(data.plu)) {
      requestData.plu = data.plu;
    }
    if (String(item.name) !== String(data.name)) {
      requestData.name = data.name;
    }
    if (String(item.unit) !== String(data.unit)) {
      requestData.unit = data.unit;
    }
    if (String(item.price) !== String(data.price)) {
      requestData.price = data.price;
    }
    if (String(item.mrp) !== String(data.mrp)) {
      requestData.mrp = data.mrp || Number(data.price);
    }
    if (String(item.least_price) !== String(data.least_price)) {
      requestData.least_price = data.least_price || 0;
    }
    if (String(item.cost) !== String(data.cost)) {
      requestData.cost = data.cost;
    }
    if (String(item.stock) !== String(data.stock)) {
      requestData.stock = data.stock ? Number(data.stock) : 0;
    }
    if (String(item.ean) !== String(data.ean)) {
      requestData.ean = data.ean || 'nil';
    }
    if (String(item.tax) !== String(data.tax)) {
      requestData.tax = data.tax || 0;
    }
    if (String(item.cess) !== String(data.cess)) {
      requestData.cess = data.cess || 0;
    }
    if (String(item.external_item_id) !== String(data.external_item_id)) {
      requestData.external_item_id = data.external_item_id ?? null;
    }
    if (item.is_available !== data.is_available) {
      requestData.is_available = data.is_available;
    }
    if (item.is_available_on_cart !== data.is_available_on_cart) {
      requestData.is_available_on_cart = data.is_available_on_cart;
    }
    if (item.sync_enabled !== data.sync_enabled) {
      requestData.sync_enabled = data.sync_enabled;
    }
    if (String(item.order) !== String(data.order)) {
      requestData.order = data.order;
    }
    if (String(item.type) !== String(data.type)) {
      requestData.type = data.type;
    }
    if (String(item.hsn) !== String(data.hsn)) {
      requestData.hsn = data.hsn;
    }
    if (JSON.stringify(item.details) !== JSON.stringify(data.details)) {
      requestData.details = {
        ...(item.details || {}),
        sub_items: [...(data.details?.sub_items || [])],
      };
    }
    return requestData;
  };

  const getRequestAdvancedDataToUpdate = () => {
    const requestAdvancedData = {};
    if (String(itemAdvanced.text1) !== String(itemAdvancedExisting.text1)) {
      requestAdvancedData.text1 = itemAdvanced.text1;
    }
    if (String(itemAdvanced.text2) !== String(itemAdvancedExisting.text2)) {
      requestAdvancedData.text2 = itemAdvanced.text2;
    }
    if (String(itemAdvanced.text3) !== String(itemAdvancedExisting.text3)) {
      requestAdvancedData.text3 = itemAdvanced.text3;
    }
    if (String(itemAdvanced.text4) !== String(itemAdvancedExisting.text4)) {
      requestAdvancedData.text4 = itemAdvanced.text4;
    }
    if (String(itemAdvanced.text5) !== String(itemAdvancedExisting.text5)) {
      requestAdvancedData.text5 = itemAdvanced.text5;
    }
    if (String(itemAdvanced.text6) !== String(itemAdvancedExisting.text6)) {
      requestAdvancedData.text6 = itemAdvanced.text6;
    }
    if (String(itemAdvanced.text7) !== String(itemAdvancedExisting.text7)) {
      requestAdvancedData.text7 = itemAdvanced.text7;
    }
    if (String(itemAdvanced.min_quantity) !== String(itemAdvancedExisting.min_quantity)) {
      requestAdvancedData.min_quantity = itemAdvanced.min_quantity;
    }
    if (String(itemAdvanced.max_quantity) !== String(itemAdvancedExisting.max_quantity)) {
      requestAdvancedData.max_quantity = itemAdvanced.max_quantity;
    }
    return requestAdvancedData;
  };

  const handleUpdateItem = async (shopIds) => {
    setIsLoading(true);
    try {
      const requestData = getRequestDataToUpdate();

      if (Object.keys(requestData).length > 0) {
        if (shopIds) {
          requestData.shop_ids = shopIds;
        }
        await ItemService.updateItem(item.id, requestData);
      }

      const requestAdvancedData = getRequestAdvancedDataToUpdate();
      if (Object.keys(requestAdvancedData).length > 0) {
        await ItemService.updateItemAdvanced(requestAdvancedData, item.id);
      }

      if (imageData) {
        const data = { image: await readImageFile(imageData) };
        await ItemService.uploadItemImage(data, item.id);
      }
      postAlertMessage({
        text: 'Item updated successfully',
        type: 'success',
      });
      onSuccess();
      handleClose();
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setIsLoading(false);
  };

  const checkIsPluAvailable = (value) => {
    setData({ ...data, plu: value });
    setShowPluTakenError(item.plu !== data.plu && items.find((_item) => _item.plu === Number(value)));
  };

  const handleSuggestPlu = async () => {
    try {
      const resp = await ItemService.getHighestPlu();
      setData({ ...data, plu: String(resp?.highest_plu + 1) });
    } catch (error) {
      setData({ ...data, plu: String(itemCount + 1) });
    }
  };

  const onEditItemButtonClicked = async () => {
    if (await shouldShowSelectOtherShopsDialog()) {
      setIsShowSelectShopsDialog(true);
      return;
    }
    await handleUpdateItem();
  };

  const closeOtherShopsSelectionDialog = () => {
    setIsShowSelectShopsDialog(false);
  };

  const onOtherShopsSelected = async (shopIds) => {
    setIsShowSelectShopsDialog(false);
    await handleUpdateItem(shopIds);
  };

  const shouldShowSelectOtherShopsDialog = async () => {
    if (Object.keys(getRequestDataToUpdate()).length === 0) {
      return false;
    }

    if (subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM) {
      return false;
    }

    const localPreference = localStorage.getItem(STORAGE_KEYS.DONT_SHOW_OTHER_SHOPS_ON_ITEM_EDIT);
    if (localPreference && localPreference === String(true)) {
      return false;
    }

    setIsLoading(true);
    const shops = await ShopService.getShops();
    setIsLoading(false);
    return shops.length > 1;
  };

  const onItemDetailsOpenOrClose = (_, expanded) => {
    if (!expanded || subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM) {
      return;
    }
    loadItemAdvancedDetails();
  };

  useEffect(() => {
    if (searchText && openSearchList) {
      const debounce = setTimeout(() => {
        fetchItems();
      }, 700);

      return () => clearTimeout(debounce);
    } else {
      setSearchItems([]);
    }
  }, [searchText, openSearchList, fetchItems]);

  return (
    <>
      <Loader isOpen={isLoading} />

      {!isShowSelectShopsDialog && (
        <div className={styles.popUp}>
          <div className={styles.contentWrapper}>
            <div className={styles.headSec}>
              <h2 className={styles.editTitle}>Edit item</h2>
              <CloseIcon onClick={handleClose} />
            </div>
            <div className={styles.inputSec}>
              {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM && (
                <RestrictionInfo
                  title={'Input restrictions '}
                  content={'To get all input options upgrade subscription to premium'}
                />
              )}

              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Item ID</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField label="Item Id" variant="outlined" size="small" fullWidth disabled value={item.id} />
                </Grid>
              </Grid>

              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Item PLu</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label={'Item PLU'}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={styles.numberInput}
                    error={showPluTakenError}
                    helperText={showPluTakenError ? 'This PLU is already taken' : ''}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="To suggest a plu">
                            <IconButton onClick={handleSuggestPlu}>
                              <AutoRenewIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      checkIsPluAvailable(e.target.value);
                    }}
                    value={data.plu}
                  />
                </Grid>
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Item Name</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="Item Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={data.name}
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>
              {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
                <Grid container className={styles.inputRow} alignItems="center">
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    display={'flex'}
                    justifyContent="flex-end"
                    className={styles.inputLabelContainer}
                  >
                    <h3 className={styles.inputLabel}>Item category</h3>
                  </Grid>
                  <Grid item xs={8} sm={8}>
                    <TextField
                      select
                      name="Unit"
                      size="small"
                      variant="outlined"
                      color="primary"
                      value={data.category}
                      onChange={(e) => {
                        setData({ ...data, category: e.target.value });
                      }}
                      fullWidth
                    >
                      <MenuItem value={''} selected>
                        None
                      </MenuItem>
                      {categories.map((category) => (
                        <MenuItem value={category.id}>{category.name}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              )}
              {shop && shop.image_support_validity && (
                <Grid container className={styles.inputRow} alignItems="center">
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    display={'flex'}
                    justifyContent="flex-end"
                    className={styles.inputLabelContainer}
                  >
                    <h3 className={styles.inputLabel}>Item Image</h3>
                  </Grid>
                  <Grid item xs={8} sm={8}>
                    <div className={styles.imageContainer}>
                      <CardMedia
                        image={imageData ? URL.createObjectURL(imageData) : item.image}
                        className={styles.image}
                      />
                    </div>
                    <input
                      accept="image/*"
                      type="file"
                      hidden
                      id="upload"
                      onChange={(e) => {
                        const img = e.target.files[0];
                        if (img.size > 101000) {
                          postAlertMessage({
                            text: 'Upload file size should be less than 100KB.',
                            type: 'failed',
                          });
                          return;
                        }
                        setImageData(img);
                      }}
                    />
                    <Button variant="contained" className={styles.uploadBtn}>
                      <label for="upload">Select</label>
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Unit</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    select
                    name="unit"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={data.unit}
                    onChange={(e) => {
                      setData({ ...data, unit: e.target.value });
                    }}
                  >
                    {(shop.units.split(',') || []).map((unit) => {
                      return <MenuItem value={unit}>{unit}</MenuItem>;
                    })}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Price</h3>
                </Grid>
                <Grid
                  item
                  xs={subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC ? 8 : 3}
                  sm={subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC ? 8 : 3}
                >
                  <TextField
                    label="Price"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={data.price}
                    onChange={(e) => {
                      setData({ ...data, price: e.target.value });
                    }}
                  />
                </Grid>
                {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
                  <>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      display={'flex'}
                      justifyContent="flex-end"
                      className={styles.inputLabelContainer}
                    >
                      <h3 className={styles.inputLabel}>Cost</h3>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <TextField
                        label="Cost"
                        variant="outlined"
                        size="small"
                        type="number"
                        fullWidth
                        value={data.cost}
                        onChange={(e) => {
                          setData({ ...data, cost: e.target.value });
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>MRP</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="MRP"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="mrp"
                    value={data.mrp}
                    onChange={(e) => {
                      setData({ ...data, mrp: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>EAN</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="Product Barcode"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={data.ean}
                    onChange={(e) => {
                      setData({ ...data, ean: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Tax</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="Tax %"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={data.tax}
                    onChange={(e) => {
                      setData({ ...data, tax: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container pt={1} className={styles.subContainer}>
                <Grid item container className={styles.inputRow} alignItems="center">
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    display={'flex'}
                    justifyContent="flex-end"
                    className={styles.inputLabelContainer}
                  >
                    <h3 className={styles.inputLabel}>Item Type</h3>
                  </Grid>
                  <Grid item xs={8} sm={8} display={'flex'} justifyContent="flex-end">
                    <TextField
                      select
                      name="type"
                      size="small"
                      variant="outlined"
                      color="primary"
                      value={data?.type}
                      onChange={(e) => {
                        let type = e.target.value;
                        if (type === ITEM_TYPES.INVENTORY) {
                          setData({
                            ...data,
                            type,
                            details: { ...(data?.details || {}), sub_items: [] },
                          });
                        } else {
                          setData({ ...data, type, stock: 0 });
                        }
                      }}
                      fullWidth
                    >
                      <MenuItem value={ITEM_TYPES.INVENTORY}>Inventory</MenuItem>
                      <MenuItem value={ITEM_TYPES.COMBO}>Combo</MenuItem>
                      <MenuItem value={ITEM_TYPES.SERVICE}>Service</MenuItem>
                      <MenuItem value={ITEM_TYPES.PRODUCTION}>Production</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                {data?.type === ITEM_TYPES.INVENTORY && (
                  <Grid container className={styles.inputRow} pt={2} alignItems="center">
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      display={'flex'}
                      justifyContent="flex-end"
                      className={styles.inputLabelContainer}
                    >
                      <h3 className={styles.inputLabel}>Stock</h3>
                    </Grid>
                    <Grid item xs={8} sm={8}>
                      <TextField
                        label="Stock"
                        variant="outlined"
                        size="small"
                        type="number"
                        fullWidth
                        value={data.stock}
                        onChange={(e) => {
                          setData({ ...data, stock: e.target.value });
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
                {data?.type === ITEM_TYPES.COMBO && (
                  <Grid container className={styles.inputRow} alignItems="center">
                    <Grid item container xs={12} pt={1} columnSpacing={1.5}>
                      <Grid item xs={7} display={'flex'} justifyContent={'center'}>
                        <h3 className={styles.inputSubLabel}>Sub items</h3>
                      </Grid>
                      <Grid item xs={3} display={'flex'} justifyContent={'center'}>
                        <h3 className={styles.inputSubLabel}>Fraction</h3>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} pt={1} pb={2} rowSpacing={1} columnSpacing={2}>
                      <>
                        <Grid item xs={7}>
                          <Autocomplete
                            fullWidth
                            size="small"
                            open={openSearchList}
                            onOpen={() => {
                              setOpenSearchList(true);
                            }}
                            onClose={() => {
                              setOpenSearchList(false);
                              setSearchItems([]);
                            }}
                            isOptionEqualToValue={(searchItem, value) => searchItem.name === value.name}
                            getOptionLabel={(searchItem) => searchItem.name}
                            options={searchItems}
                            loading={loading}
                            onInputChange={(event, newInputValue) => {
                              setSearchText(newInputValue);
                            }}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setSubItem({ ...subItem, item_id: newValue.id, name: newValue.name });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                size="small"
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            variant="outlined"
                            size="small"
                            type="number"
                            className={styles.numberInput}
                            name="fraction"
                            value={subItem?.fraction || ''}
                            onChange={(e) => setSubItem({ ...subItem, fraction: Number(e.target.value) })}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            variant="contained"
                            disabled={!subItem?.fraction || subItem?.fraction === '' || !subItem?.item_id}
                            onClick={addSubItem}
                          >
                            <LibraryAddIcon />
                          </Button>
                        </Grid>
                        {(data?.details?.sub_items || []).map((subItem) => {
                          return (
                            <>
                              <Grid item xs={7} className={styles.subLabel}>
                                {subItem?.name}
                              </Grid>
                              <Grid item xs={3} className={styles.subLabel}>
                                {subItem?.fraction || 0}
                              </Grid>
                              <Grid item xs={2}>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    removeSubItem(subItem);
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Grid>
                            </>
                          );
                        })}
                      </>
                    </Grid>
                  </Grid>
                )}
                {data?.type === ITEM_TYPES.PRODUCTION && (
                  <Grid container className={styles.inputRow} alignItems="center">
                    <Grid container className={styles.inputRow} pt={2} alignItems="center">
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        display={'flex'}
                        justifyContent="flex-end"
                        className={styles.inputLabelContainer}
                      >
                        <h3 className={styles.inputLabel}>Stock</h3>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                        <TextField
                          label="Stock"
                          variant="outlined"
                          size="small"
                          type="number"
                          fullWidth
                          value={data.stock}
                          onChange={(e) => {
                            setData({ ...data, stock: e.target.value });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} pt={1} columnSpacing={1.5}>
                      <Grid item xs={7} display={'flex'} justifyContent={'center'}>
                        <h3 className={styles.inputSubLabel}>Sub items</h3>
                      </Grid>
                      <Grid item xs={3} display={'flex'} justifyContent={'center'}>
                        <h3 className={styles.inputSubLabel}>Fraction</h3>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} pt={1} pb={2} rowSpacing={1} columnSpacing={2}>
                      <>
                        <Grid item xs={7}>
                          <Autocomplete
                            fullWidth
                            size="small"
                            open={openSearchList}
                            onOpen={() => {
                              setOpenSearchList(true);
                            }}
                            onClose={() => {
                              setOpenSearchList(false);
                              setSearchItems([]);
                            }}
                            isOptionEqualToValue={(searchItem, value) => searchItem.name === value.name}
                            getOptionLabel={(searchItem) => searchItem.name}
                            options={searchItems}
                            loading={loading}
                            onInputChange={(event, newInputValue) => {
                              setSearchText(newInputValue);
                            }}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setSubItem({ ...subItem, item_id: newValue.id, name: newValue.name });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                size="small"
                                {...params}
                                placeholder={params.inputProps.placeholder || 'Search by product name'}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            variant="outlined"
                            size="small"
                            type="number"
                            className={styles.numberInput}
                            name="fraction"
                            value={subItem?.fraction || ''}
                            onChange={(e) => setSubItem({ ...subItem, fraction: Number(e.target.value) })}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            variant="contained"
                            disabled={!subItem?.fraction || subItem?.fraction === '' || !subItem?.item_id}
                            onClick={addSubItem}
                          >
                            <LibraryAddIcon />
                          </Button>
                        </Grid>
                        {(data?.details?.sub_items || []).map((subItem) => {
                          return (
                            <>
                              <Grid item xs={7} className={styles.subLabel}>
                                {subItem?.name}
                              </Grid>
                              <Grid item xs={3} className={styles.subLabel}>
                                {subItem?.fraction || 0}
                              </Grid>
                              <Grid item xs={2}>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    removeSubItem(subItem);
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Grid>
                            </>
                          );
                        })}
                      </>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Additional Charge</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="Additional Charge %"
                    variant="outlined"
                    size="small"
                    type="number"
                    className={styles.numberInput}
                    name="tax"
                    value={data.cess}
                    onChange={(e) => {
                      setData({ ...data, cess: e.target.value });
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>External Item ID</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="External Item ID"
                    variant="outlined"
                    size="small"
                    type="text"
                    className={styles.numberInput}
                    name="tax"
                    value={data.external_item_id}
                    onChange={(e) => {
                      setData({
                        ...data,
                        external_item_id: e.target.value,
                      });
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>HSN</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="HSN"
                    variant="outlined"
                    size="small"
                    type="text"
                    className={styles.numberInput}
                    name="tax"
                    value={data.hsn}
                    onChange={(e) => {
                      setData({
                        ...data,
                        hsn: e.target.value,
                      });
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Order</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="Order"
                    variant="outlined"
                    size="small"
                    type="number"
                    className={styles.numberInput}
                    name="order"
                    value={data.order}
                    onChange={(e) => {
                      setData({
                        ...data,
                        order: e.target.value,
                      });
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Least Price</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="Least Price (Optional)"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="least_price"
                    value={data.least_price}
                    onChange={(e) => {
                      setData({ ...data, least_price: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" justify="flex-start">
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        color="primary"
                        checked={data.sync_enabled}
                        onChange={() =>
                          setData({
                            ...data,
                            sync_enabled: !data.sync_enabled,
                          })
                        }
                      />
                    }
                    label="Export to scale"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        color="primary"
                        checked={data.is_available}
                        onChange={() =>
                          setData({
                            ...data,
                            is_available: !data.is_available,
                          })
                        }
                      />
                    }
                    label="Available for sale"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" justify="flex-start">
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        color="primary"
                        checked={data.is_available_on_cart}
                        onChange={() =>
                          setData({
                            ...data,
                            is_available_on_cart: !data.is_available_on_cart,
                          })
                        }
                      />
                    }
                    label="Available on cart"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
              {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.PREMIUM && (
                <>
                  <Grid container className={styles.inputRow} alignItems="center">
                    <Grid item xs={12} sm={12}>
                      <Accordion onChange={onItemDetailsOpenOrClose}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>Advanced</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container className={styles.inputRow} alignItems="center">
                            <Grid item xs={4} sm={4} spacing={2} className={styles.inputLabelContainer}>
                              <h3 className={styles.inputLabel}>Text 1</h3>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                              <TextField
                                placeholder="Text 1"
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="text"
                                value={itemAdvanced.text1}
                                onChange={(e) => {
                                  setItemAdvanced({
                                    ...itemAdvanced,
                                    text1: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className={styles.inputRow} alignItems="center">
                            <Grid item xs={4} sm={4} spacing={2} className={styles.inputLabelContainer}>
                              <h3 className={styles.inputLabel}>Text 2</h3>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                              <TextField
                                placeholder="Text 2"
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="text"
                                value={itemAdvanced.text2}
                                onChange={(e) => {
                                  setItemAdvanced({
                                    ...itemAdvanced,
                                    text2: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className={styles.inputRow} alignItems="center">
                            <Grid item xs={4} sm={4} spacing={2} className={styles.inputLabelContainer}>
                              <h3 className={styles.inputLabel}>Text 3</h3>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                              <TextField
                                placeholder="Text 3"
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="text"
                                value={itemAdvanced.text3}
                                onChange={(e) => {
                                  setItemAdvanced({
                                    ...itemAdvanced,
                                    text3: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className={styles.inputRow} alignItems="center">
                            <Grid item xs={4} sm={4} spacing={2} className={styles.inputLabelContainer}>
                              <h3 className={styles.inputLabel}>Text 4</h3>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                              <TextField
                                placeholder="Text 4"
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="text"
                                value={itemAdvanced.text4}
                                onChange={(e) => {
                                  setItemAdvanced({
                                    ...itemAdvanced,
                                    text4: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className={styles.inputRow} alignItems="center">
                            <Grid item xs={4} sm={4} spacing={2} className={styles.inputLabelContainer}>
                              <h3 className={styles.inputLabel}>Text 5</h3>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                              <TextField
                                placeholder="Text 5"
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="text"
                                value={itemAdvanced.text5}
                                onChange={(e) => {
                                  setItemAdvanced({
                                    ...itemAdvanced,
                                    text5: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className={styles.inputRow} alignItems="center">
                            <Grid item xs={4} sm={4} spacing={2} className={styles.inputLabelContainer}>
                              <h3 className={styles.inputLabel}>Text 6</h3>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                              <TextField
                                placeholder="Text 6"
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="text"
                                value={itemAdvanced.text6}
                                onChange={(e) => {
                                  setItemAdvanced({
                                    ...itemAdvanced,
                                    text6: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className={styles.inputRow} alignItems="center">
                            <Grid item xs={4} sm={4} spacing={2} className={styles.inputLabelContainer}>
                              <h3 className={styles.inputLabel}>Text 7</h3>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                              <TextField
                                placeholder="Text 7"
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="text"
                                value={itemAdvanced.text7}
                                onChange={(e) => {
                                  setItemAdvanced({
                                    ...itemAdvanced,
                                    text7: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className={styles.inputRow} alignItems="center">
                            <Grid item xs={4} sm={4} spacing={2} className={styles.inputLabelContainer}>
                              <h3 className={styles.inputLabel}>Min Qty</h3>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                              <TextField
                                placeholder="Min Qty"
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="number"
                                value={itemAdvanced.min_quantity}
                                onChange={(e) => {
                                  setItemAdvanced({
                                    ...itemAdvanced,
                                    min_quantity: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container className={styles.inputRow} alignItems="center">
                            <Grid item xs={4} sm={4} spacing={2} className={styles.inputLabelContainer}>
                              <h3 className={styles.inputLabel}>Max Qty</h3>
                            </Grid>
                            <Grid item xs={8} sm={8}>
                              <TextField
                                placeholder="Max Qty"
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="number"
                                value={itemAdvanced.max_quantity}
                                onChange={(e) => {
                                  setItemAdvanced({
                                    ...itemAdvanced,
                                    max_quantity: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
            <div className={styles.footSec}>
              <DialogActions>
                <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
                  close
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={
                    data.name === '' ||
                    data.price === '' ||
                    data.cost === '' ||
                    data.stock === '' ||
                    showPluTakenError ||
                    !data.plu ||
                    !hasAnyUpdate()
                  }
                  onClick={onEditItemButtonClicked}
                >
                  Update Item
                </Button>
              </DialogActions>
            </div>
          </div>
        </div>
      )}
      {isShowSelectShopsDialog && (
        <SelectShopsDialog
          onClose={closeOtherShopsSelectionDialog}
          onShopsSelected={onOtherShopsSelected}
          message={`Do you want to update item with PLU '${data.plu}' in all shops?`}
        />
      )}
    </>
  );
}
