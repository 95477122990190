import React, { useEffect, useState, useContext, useCallback } from 'react';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import styles from './styles.module.css';
import { Button, Divider, Grid, Menu, MenuItem } from '@mui/material';
import SearchIcon from '@material-ui/icons/Search';
import Loader from '../../utils/Loading';
import PrintIcon from '@mui/icons-material/Print';
import ShiftService from '../../../services/ShiftService';
import { AlertMessagesContext } from 'react-alert-messages';
import DataTable from '../../utils/DataTable';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import ShiftPaymentCashBookPopup from '../../popups/ShiftPaymentCashBookPopup';
import Info from '../../utils/Alert/Info';

function ShiftSummary() {
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [shifts, setShifts] = useState([]);
  const [shiftTransactions, setShiftTransactions] = useState([]);
  const [shiftDetails, setShiftDetails] = useState({});
  const [selectedShift, setSelectedShift] = useState(null);
  const [filteredShiftTransactions, setFilteredShiftTransactions] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const addBalance = (transactions) => {
    let balance = 0;

    return transactions.map((tnx) => {
      const newBanance = balance + tnx.credit - tnx.debit;
      balance = newBanance;
      return { ...tnx, balance };
    });
  };

  const showViewCashBook = (data) => {
    const openingBalance = {
      time: selectedShift?.opened_at,
      credit: data?.opening || 0,
      debit: 0,
      description: `Opening balance ${data?.opening || 0}`,
      balance: data?.opening_balance || 0,
    };

    const transactionsWithBalance = addBalance([openingBalance, ...data.transactions]);

    setFilteredShiftTransactions({
      summary: data,
      tnx: transactionsWithBalance,
    });
  };

  const handleSelectShiftClick = (shift) => {
    setSelectedShift(shift);
    setAnchorEl(null);
  };

  const shiftReportHeaders = [
    {
      label: 'Payment',
      id: 'payment_mode',
      type: 'callback',
      viewRender: (obj) => obj?.payment_mode?.name,
    },
    {
      label: 'Opening',
      id: 'opening',
      type: 'callback',
      viewRender: (item) => item.opening || 0,
    },
    {
      label: 'In',
      id: 'cash_in',
      type: 'callback',
      viewRender: (item) => item.cash_in || item.transactions.reduce((sum, sp) => sum + (sp.credit || 0), 0),
    },
    {
      label: 'Out',
      id: 'cash_out',
      type: 'callback',
      viewRender: (item) => item.cash_out || item.transactions.reduce((sum, sp) => sum + (sp.debit || 0), 0),
    },
    {
      label: 'Closing',
      id: 'closing',
      type: 'callback',
      viewRender: (item) => item.closing || '-',
    },
    {
      id: 'deleteBtn',
      type: 'callback',
      align: 'right',
      viewRender: (obj) => (
        <Button
          onClick={(e) => {
            showViewCashBook(obj);
          }}
          variant="contained"
          size="small"
          color="primary"
          style={{ width: 120 }}
        >
          {`${obj?.payment_mode?.name} Book`}
        </Button>
      ),
    },
  ];

  const shiftOrderHeaders = [
    {
      label: 'Date',
      id: 'date',
      type: 'callback',
      viewRender: (item) => DateTimeHelpers.getTodayStr(item.time),
    },
    {
      label: 'Time',
      id: 'time',
      type: 'callback',
      viewRender: (item) => DateTimeHelpers.convertTo12HourTime(new Date(item.time)),
    },
    {
      label: 'Description',
      id: 'description',
      type: 'text',
    },
    {
      label: 'In',
      id: 'credit',
      type: 'text',
    },
    {
      label: 'Out',
      id: 'debit',
      type: 'text',
    },
    {
      label: 'Balance',
      id: 'balance',
      type: 'text',
    },
  ];

  const handleCloseCashBook = () => setFilteredShiftTransactions(null);

  const handleSearchClick = async (event) => {
    if (searchText.length === 0) {
      postAlertMessage({ text: 'Enter search text', type: 'failed' });
      setAnchorEl(null);
      return;
    }
    setLoadingIndicator(true);
    try {
      const searchAnchor = event.currentTarget;
      const resp = await await ShiftService.getShifts({ limit: 10, search: searchText });
      if (resp.length === 0) {
        postAlertMessage({ text: 'No sales found', type: 'failed' });
        setAnchorEl(null);
        setLoadingIndicator(false);
        return;
      }
      setAnchorEl(searchAnchor);
      setShifts(resp);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const handleSearchClose = () => {
    setAnchorEl(null);
  };

  const getShiftDetails = useCallback(() => {
    return ShiftService.getShiftDetails(selectedShift.id);
  }, [selectedShift?.id]);

  const getShiftTransactions = useCallback(() => {
    return ShiftService.getShiftTransactions(selectedShift.id);
  }, [selectedShift?.id]);

  useEffect(() => {
    if (!selectedShift) return;
    const getShiftDetailes = async () => {
      setLoadingIndicator(true);
      try {
        const [shiftDetails, shiftTransactions] = await Promise.all([getShiftDetails(), getShiftTransactions()]);
        setShiftDetails({
          ...shiftDetails,
          shift_payments: shiftDetails.shift_payments.map((sp) => {
            return { ...sp, transactions: shiftTransactions.filter((sr) => sr.mode.id === sp.payment_mode.id) };
          }),
        });
        setShiftTransactions(addBalance(shiftTransactions));
      } catch (error) {
        postAlertMessage({ text: error.message, type: 'failed' });
      }
      setLoadingIndicator(false);
    };
    getShiftDetailes();
  }, [selectedShift, postAlertMessage, getShiftTransactions, getShiftDetails]);

  useEffect(() => {
    const fetchInitialShift = async () => {
      setLoadingIndicator(true);
      try {
        const resp = await ShiftService.getShifts({ limit: 1 });
        setSelectedShift(resp?.[0]);
      } catch (error) {
        postAlertMessage({ text: error.message, type: 'failed' });
      }
      setLoadingIndicator(false);
    };

    fetchInitialShift();
  }, [postAlertMessage]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <span className={styles.title}>
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>
        <div style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Print Report</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={window.print}
          >
            <PrintIcon className={styles.actionBtnIcon} />
            Print
          </Button>
        </div>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>{selectedShift && `Shift Summary Report of ${selectedShift?.name}`}</h2>
          </div>
          <div className={styles.filerInputSec}>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleSearchClick(event);
                  }
                }}
                className={styles.searchInput}
                placeholder="Search by shift"
              />
              <SearchIcon
                className={styles.searchIcon}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleSearchClick}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                style={{ width: 200 }}
                onClose={handleSearchClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {shifts.map((shift) => (
                  <>
                    <MenuItem onClick={() => handleSelectShiftClick(shift)}>{shift.name}</MenuItem>
                    <Divider />
                  </>
                ))}
              </Menu>
            </div>
          </div>
        </div>
      </div>
      {selectedShift && (
        <div className={styles.changeable} style={{ paddingBottom: '15px' }}>
          <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
            <Grid item xs={3} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>ID</h3>
            </Grid>
            <Grid item xs={3}>
              <h3 className={styles.inputLabel}>{selectedShift?.id || '-'}</h3>
            </Grid>
            <Grid item xs={3} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Shift</h3>
            </Grid>
            <Grid item xs={3}>
              <h3 className={styles.inputLabel}>{selectedShift?.name || '-'}</h3>
            </Grid>
          </Grid>
          <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
            <Grid item xs={3} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>No of invoice</h3>
            </Grid>
            <Grid item xs={3}>
              <h3 className={styles.inputLabel}>
                {shiftTransactions.filter((item) => item.type === 'sale').length || '-'}
              </h3>
            </Grid>
            <Grid item xs={3} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Opening balance</h3>
            </Grid>
            <Grid item xs={3}>
              <h3 className={styles.inputLabel}>
                {(shiftDetails?.shift_payments || []).reduce((sum, sp) => sum + (sp.opening || 0), 0)}
              </h3>
            </Grid>
          </Grid>
          <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
            <Grid item xs={3} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Closing balance</h3>
            </Grid>
            <Grid item xs={3}>
              <h3 className={styles.inputLabel}>
                {(shiftDetails?.shift_payments || []).reduce((sum, sp) => sum + (sp.closing || 0), 0)}
              </h3>
            </Grid>
            <Grid item xs={3} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Total bill amount</h3>
            </Grid>
            <Grid item xs={3}>
              <h3 className={styles.inputLabel}>
                {shiftTransactions
                  .filter((item) => item.type === 'sale')
                  .reduce((sum, order) => sum + (order.credit || 0), 0)}
              </h3>
            </Grid>
          </Grid>
          <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
            <Grid item xs={3} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Total Expenses</h3>
            </Grid>
            <Grid item xs={3}>
              <h3 className={styles.inputLabel}>
                {shiftTransactions
                  .filter((item) => item.type === 'expense')
                  .reduce((sum, order) => sum + (order.debit || 0), 0)}
              </h3>
            </Grid>
            <Grid item xs={3} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Opened</h3>
            </Grid>
            <Grid item xs={3}>
              <h3 className={styles.inputLabel}>{`${DateTimeHelpers.getTodayStr(
                selectedShift?.opened_at
              )} ${DateTimeHelpers.convertTo12HourTime(new Date(selectedShift?.opened_at))}`}</h3>
            </Grid>
          </Grid>
          <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
            <Grid item xs={3} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Closed at</h3>
            </Grid>
            <Grid item xs={3}>
              <h3 className={styles.inputLabel}>
                {selectedShift?.closed_at
                  ? `${DateTimeHelpers.getTodayStr(selectedShift?.closed_at)} ${DateTimeHelpers.convertTo12HourTime(
                      new Date(selectedShift?.opened_at)
                    )}`
                  : '-'}
              </h3>
            </Grid>
            <Grid item xs={3} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Status</h3>
            </Grid>
            <Grid item xs={3}>
              <h3 className={styles.inputLabel}>{selectedShift?.closed_at ? 'Closed' : 'Opened'}</h3>
            </Grid>
          </Grid>
          <Grid container alignItems="center" style={{ paddingLeft: '10px', paddingTop: '5px' }} spacing={2}>
            <Grid item xs={3} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Terminal ID</h3>
            </Grid>
            <Grid item xs={3}>
              <h3 className={styles.inputLabel}>{selectedShift?.terminal.id}</h3>
            </Grid>
            <Grid item xs={3} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Terminal Name</h3>
            </Grid>
            <Grid item xs={3}>
              <h3 className={styles.inputLabel}>{selectedShift?.terminal.name}</h3>
            </Grid>
          </Grid>
        </div>
      )}

      <Grid className={styles.changeable}>
        <div className={styles.headTitle} style={{ marginBottom: '10px' }}>
          <span className={styles.subTitle}>Shift Details</span>
        </div>
        {(shiftDetails?.shift_payments || []).length > 0 && (
          <DataTable columns={shiftReportHeaders} rows={shiftDetails?.shift_payments} />
        )}
      </Grid>

      {shiftTransactions.length > 0 ? (
        <Grid className={styles.changeable}>
          <div className={styles.headTitle} style={{ marginBottom: '10px' }}>
            <span className={styles.subTitle}>Shift Orders</span>
          </div>
          <DataTable columns={shiftOrderHeaders} rows={shiftTransactions} />
        </Grid>
      ) : (
        !loadingIndicator && (
          <Info
            title={'No transactions found'}
            content={'There are no transactions to display for the current shift.'}
          />
        )
      )}
      {filteredShiftTransactions && (
        <ShiftPaymentCashBookPopup rowData={filteredShiftTransactions} toggleViewCashBook={handleCloseCashBook} />
      )}
    </div>
  );
}

export default withConsoleBase(ShiftSummary);
