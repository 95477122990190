import BackendService from './BackendService';
import { STORAGE_KEYS } from '../const';
async function getBillSummaryReport(data = {}) {
  return BackendService.postData('/reports/sales-summary/', data);
}
async function getReturnedBillSummaryReport(data = {}) {
  return BackendService.postData('/reports/returned-sales-summary/', data);
}
async function getBillSummaryReportWithProfif(data = {}) {
  return BackendService.postData('/reports/sales-summary/profit/', data);
}
async function getBillSummaryCsv(data = {}) {
  return BackendService.post('/reports/bills/', data);
}
async function getBillDetails(data = {}) {
  return BackendService.postData('/reports/sales/details/', data);
}
async function getDayWiseReport(data = {}) {
  return BackendService.postData('/reports/day-wise/', data);
}
async function getDayWiseReportWithProfit(data = {}) {
  return BackendService.postData('/reports/day-wise/profit/', data);
}
async function getCashierSalesReport(data = {}) {
  return BackendService.postData('/reports/cashier-sales-summary/', data);
}
async function getWaiterSalesReport(data = {}) {
  return BackendService.postData('/reports/waiter-sales-summary/', data);
}
async function getCategorySalesReport(data = {}) {
  return BackendService.postData('/reports/category-sales-summary/', data);
}
async function exportBillToPdf(data = {}) {
  const authToken = localStorage.getItem(STORAGE_KEYS.TOKEN);
  return BackendService.getData(
    '/reports/bills/details/pdf/',
    {},
    {
      token: authToken,
      machine_no: data.machine_no,
      bill_no: data.bill_no,
    }
  );
}
async function voidInvoice(inoiceId) {
  return BackendService.destroy(`/sales/${inoiceId}/`);
}

const BillSummaryReportService = {
  getBillSummaryReport,
  getBillSummaryCsv,
  getBillDetails,
  exportBillToPdf,
  getBillSummaryReportWithProfif,
  getDayWiseReportWithProfit,
  getDayWiseReport,
  getWaiterSalesReport,
  getCashierSalesReport,
  getCategorySalesReport,
  getReturnedBillSummaryReport,
  voidInvoice,
};

export default BillSummaryReportService;
