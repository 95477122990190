import React, { useState, useEffect, useCallback, useContext } from 'react';
import styles from './styles.module.css';
import Loader from '../../utils/Loading';
import { Button, Select, FormControl, MenuItem, ListItemText, OutlinedInput } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import CustomerService from '../../../services/CustomerService';
import OrderActionsService from '../../../services/OrderActionService';
import SyncIcon from '@mui/icons-material/Sync';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { filterEndDate } from '../../utils/FilterEndDate';
import Info from '../../utils/Alert/Info';
import Grayout from '../../utils/GrayOut/Grayout';
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  Box,
  Paper,
  TableContainer,
  TableBody,
  Table,
  Typography,
  Toolbar,
  Tooltip,
  Chip,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import { AlertMessagesContext } from 'react-alert-messages';
import { ORDER_STATUS, STORAGE_KEYS, BACKEND_URL, SelectFilterStyle } from '../../../const';
import DateTimeRange from '../../utils/DateTimeRange/DateTimeRange';
import { dynamicDateRange } from '../../../services/DynamicDateRangeService';

const headCells = [
  {
    id: 'bill_no',
    numeric: false,
    disablePadding: true,
    label: 'Sale',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
  {
    id: 'customer',
    numeric: false,
    disablePadding: false,
    label: 'Customer',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'time',
    numeric: false,
    disablePadding: false,
    label: 'Time',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'payable',
    numeric: true,
    disablePadding: false,
    label: 'Payable',
  },
  {
    id: 'actions',
    disablePadding: false,
    label: 'Actions',
  },
];

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          Order Actions
        </Typography>
      )}
    </Toolbar>
  );
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all tasks',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <span className={styles.tableHeader}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const Status = {
  READY: 'READY',
  PENDING: 'PENDING',
};

function OrderActions() {
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState(getDefaultStartTime());
  const [filterToDate, setFilterToDate] = useState(filterEndDate());
  const [orderActions, setOrderActions] = useState([]);
  const [customerGroups, setCustomerGroups] = useState([]);
  const [filteredOrderAction, setFilteredOrderAction] = useState('all');
  const [filteredCustomerGroups, setFilteredCustomerGroups] = useState([]);
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(null);
  const [prevFilterData, setPrevFilterData] = useState({
    filterFromDate,
    filterToDate,
  });
  const [orderActionTasks, setOrderActionTasks] = useState([]);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('bill_no');
  const [nextAction, setNextAction] = useState(null);
  const [filterOrderStatus, setFilterOrderStatus] = useState(Status.READY);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = orderActionTasks.map((t) => t.id);
      setSelectedTaskIds(newSelected);
      return;
    }
    setSelectedTaskIds([]);
  };

  const handleClickRow = (event, id) => {
    const selectedIndex = selectedTaskIds.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTaskIds, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTaskIds.slice(1));
    } else if (selectedIndex === selectedTaskIds.length - 1) {
      newSelected = newSelected.concat(selectedTaskIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTaskIds.slice(0, selectedIndex),
        selectedTaskIds.slice(selectedIndex + 1)
      );
    }
    setSelectedTaskIds(newSelected);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function getDefaultStartTime() {
    const date = DateTimeHelpers.getFiveDateEarlier();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  function getTaskById(id) {
    return orderActionTasks.find((task) => task.id === id);
  }

  const downloadPdfInvoice = async (sale) => {
    const authToken = localStorage.getItem(STORAGE_KEYS.TOKEN);
    const shopId = localStorage.getItem(STORAGE_KEYS.SHOP_ID);
    const url = `${BACKEND_URL}/reports/sales/details/pdf/?token=${authToken}&shop_id=${shopId}&bill_no=${sale.bill_no}`;
    window.open(url);
  };

  const downloadSelectedPdfInvoices = async () => {
    const tasks = selectedTaskIds.map((taskId) => getTaskById(taskId));
    const billNos = [...new Set(tasks.map((task) => task.sale.bill_no))];
    const authToken = localStorage.getItem(STORAGE_KEYS.TOKEN);
    const shopId = localStorage.getItem(STORAGE_KEYS.SHOP_ID);
    const url = `${BACKEND_URL}/invoices/pdf/?token=${authToken}&shop_id=${shopId}&bill_nos=${billNos}`;
    window.open(url);
  };

  const loadOrderActionTasks = async ({
    startTime,
    endTime,
    order_action_id = filteredOrderAction,
    customer_group_ids = filteredCustomerGroups,
    status = filterOrderStatus,
  } = {}) => {
    const { startDate: dynamicStartDate, endDate: dynamicEndDate } = dynamicDateRange('ORDER_ACTION');

    const start = startTime || dynamicStartDate;
    const end = endTime || dynamicEndDate;

    setLoadingIndicator(true);
    try {
      const timezoneFreeStartTime = DateTimeHelpers.getTimezoneOffsetDate(start);
      const timezoneFreeEndTime = DateTimeHelpers.getTimezoneOffsetDate(end);
      const params = {
        filter_start_time: timezoneFreeStartTime,
        filter_end_time: timezoneFreeEndTime,
      };
      if (customer_group_ids.length) {
        params.customer_group_ids = customer_group_ids.join(',');
      }
      if (status !== 'all') {
        params.status = status;
      }
      if (order_action_id !== 'all') {
        params.order_action_id = order_action_id;
      }

      const resp = await OrderActionsService.getOrderActionTasks(params);
      setOrderActionTasks(resp);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const getOrderActionTasks = () => {
    const { startDate: dynamicStartDate, endDate: dynamicEndDate } = dynamicDateRange('ORDER_ACTION');

    setFilterFromDate(dynamicStartDate);
    setFilterToDate(dynamicEndDate);

    loadOrderActionTasks();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      filterFromDate: dynamicStartDate,
      filterToDate: dynamicEndDate,
      filteredCustomerGroups,
      filteredOrderAction,
      filterOrderStatus,
    });
  };

  const shouldShowMoveToNextActionButton = () => {
    if (selectedTaskIds.length <= 0) {
      return false;
    }
    if (selectedTaskIds.find((taskId) => getTaskById(taskId)?.status !== Status.READY)) {
      return false;
    }
    const tasks = selectedTaskIds.map((taskId) => getTaskById(taskId));
    const firstTask = tasks[0];
    const hasMultiActionsSelected = tasks.find((task) => task?.action?.id !== firstTask.action?.id);

    return !hasMultiActionsSelected;
  };

  const moveToNextAction = async () => {
    setLoadingIndicator(true);
    try {
      await OrderActionsService.moveToNextActions({ updating_tasks: selectedTaskIds, next_action: nextAction?.id });
      setSelectedTaskIds([]);
      getOrderActionTasks();
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const isAnyChangeOnReportsFilters = useCallback(() => {
    return (
      filterFromDate !== prevFilterData.filterFromDate ||
      filterToDate !== prevFilterData.filterToDate ||
      filteredCustomerGroups !== prevFilterData.filteredCustomerGroups ||
      filteredOrderAction !== prevFilterData.filteredOrderAction ||
      filterOrderStatus !== prevFilterData.filterOrderStatus
    );
  }, [
    filterFromDate,
    filterToDate,
    filteredCustomerGroups,
    filteredOrderAction,
    filterOrderStatus,
    prevFilterData.filterFromDate,
    prevFilterData.filterToDate,
    prevFilterData.filteredOrderAction,
    prevFilterData.filteredCustomerGroups,
    prevFilterData.filterOrderStatus,
  ]);

  useEffect(() => {
    if (filteredOrderAction && filteredOrderAction !== 'all') {
      const filterAction = orderActions.find((act) => act.id === filteredOrderAction);
      const next = orderActions
        .filter((action) => action.order > filterAction.order)
        .sort((a, b) => a.order - b.order)[0];
      setNextAction(next || null);
    } else {
      setNextAction(null);
    }
  }, [filteredOrderAction, orderActions]);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnReportsFilters());
  }, [isAnyChangeOnReportsFilters, isShowGenerateButton]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingIndicator(true);
      try {
        const [orderActionsResp, customerGroupsResp] = await Promise.all([
          OrderActionsService.getOrderActions(),
          CustomerService.getCustomerGroups(),
        ]);
        setCustomerGroups(customerGroupsResp);
        setOrderActions(orderActionsResp);
        await loadOrderActionTasks();
      } catch (error) {
        postAlertMessage({ text: error.message, type: 'failed' });
      }
      setLoadingIndicator(false);
    };

    fetchData();
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <span className={styles.title}>
          Order<span className={styles.menuTitle}>Actions</span>
        </span>
        <div style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Print Report</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={window.print}
          >
            <PrintIcon className={styles.actionBtnIcon} />
            Print
          </Button>
        </div>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>
              <span className={styles.subText}>
                {orderActions.find((action) => action.id === filteredOrderAction)?.action}
              </span>{' '}
              Order Actions from {DateTimeHelpers.convertDateToDMY(filterFromDate)} to{' '}
              {DateTimeHelpers.convertDateToDMY(filterToDate)}
            </h2>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.dateDiv}>
            <DateTimeRange
              setFilterFromDate={setFilterFromDate}
              setFilterToDate={setFilterToDate}
              storageKey="ORDER_ACTION"
            />
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Customer Groups</label>
            </div>
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  displayEmpty
                  value={filteredCustomerGroups}
                  onChange={(e) => setFilteredCustomerGroups(e.target.value)}
                  input={<OutlinedInput />}
                  style={{ width: 135, height: 40 }}
                  renderValue={(filteredCustomerGroups) => {
                    if (filteredCustomerGroups.length === 0) return <span>All</span>;
                    const selectedNames = filteredCustomerGroups.map(
                      (id) => customerGroups.find((cg) => cg.id === id).name
                    );
                    return selectedNames.join(', ');
                  }}
                  MenuProps={SelectFilterStyle}
                >
                  {customerGroups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      <Checkbox checked={filteredCustomerGroups.includes(group.id)} color="primary" />
                      <ListItemText primary={group.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Order Actions</label>
            </div>
            <div>
              <FormControl variant="outlined" style={{ minWidth: 120 }} size="small">
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={filteredOrderAction}
                  onChange={(e) => setFilteredOrderAction(e.target.value)}
                >
                  <MenuItem key={'all'} value={'all'}>
                    All
                  </MenuItem>
                  {orderActions.map((action) => (
                    <MenuItem key={action.id} value={action.id}>
                      <span className={styles.subText}>{action?.action}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Task Status</label>
            </div>
            <div>
              <FormControl variant="outlined" style={{ minWidth: 120 }} size="small">
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={filterOrderStatus}
                  onChange={(e) => setFilterOrderStatus(e.target.value)}
                >
                  {Object.entries(ORDER_STATUS).map(([status, label]) => (
                    <MenuItem key={status} value={status}>
                      <span className={styles.subText}>{label}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Load Actions</label>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={getOrderActionTasks}
                disabled={isShowGenerateButton === false}
              >
                <SyncIcon className={styles.actionBtnIcon} />
                Generate
              </Button>
            </div>
          </div>
          {selectedTaskIds.length > 0 && (
            <div className={styles.filterDiv}>
              <div style={{ paddingBottom: '4px' }}>
                <label className={styles.label}>Load Actions</label>
              </div>
              <div>
                {shouldShowMoveToNextActionButton() && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.actionBtn}
                    style={{ backgroundColor: '#272c86', color: 'white' }}
                    onClick={moveToNextAction}
                  >
                    {nextAction ? `Move to ${nextAction.action}` : 'Mark as Complete'}
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.actionBtn}
                  style={{ backgroundColor: '#272c86', color: 'white', marginLeft: 10 }}
                  onClick={downloadSelectedPdfInvoices}
                >
                  PRINT INVOICE
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      {orderActionTasks && orderActionTasks.length ? (
        <div className={styles.tableContainer}>
          <Grayout open={isShowGenerateButton} />
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <EnhancedTableToolbar numSelected={selectedTaskIds.length} />
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                  <EnhancedTableHead
                    numSelected={selectedTaskIds.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={orderActionTasks.length}
                  />
                  <TableBody>
                    {orderActionTasks.map((row, index) => {
                      const isItemSelected = selectedTaskIds.includes(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      const createdAt = new Date(row.created_at);

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClickRow(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row?.sale?.bill_no}
                          </TableCell>
                          <TableCell>{row.action?.action || '-'}</TableCell>
                          <TableCell>{row.sale?.customer?.name || '-'}</TableCell>
                          <TableCell>{DateTimeHelpers.convertDateToIsoDMY(createdAt)}</TableCell>
                          <TableCell>{DateTimeHelpers.convertTo12HourTime(createdAt)}</TableCell>
                          <TableCell>
                            {row.status === Status.READY ? (
                              <Tooltip title="Ready to move next">
                                <Chip label={Status.READY} color="primary" />
                              </Tooltip>
                            ) : row.status === Status.PENDING ? (
                              <Tooltip title="Waiting for previous task">
                                <Chip label="waiting" sx={{ background: '#e0a06d', color: 'white' }} />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Task completed">
                                <Chip label="completed" color="success" />
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell>{row.sale?.payable}</TableCell>
                          <TableCell>
                            <div style={{ textAlign: 'center', cursor: 'pointer' }}>
                              <PrintIcon onClick={() => downloadPdfInvoice(row.sale)} />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </div>
      ) : (
        !loadingIndicator && (
          <Info
            severity="warning"
            title={'Oh no, there are no order actions matching to your filter'}
            content={'There are no order actions matching to your filter. Please verify your inputs to fetch list.'}
          />
        )
      )}
    </div>
  );
}

export default withConsoleBase(OrderActions);
