import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { AlertMessagesContext } from 'react-alert-messages';
import styles from './styles.module.css';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ItemService from '../../../services/ItemService';
import PurchaseService from '../../../services/PurchaseService';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { ROUTES } from '../../../const';
import EditIcon from '@mui/icons-material/Edit';
import VendorServices from '../../../services/VendorServices';
import Loader from '../../utils/Loading';
import CustomMenuItem from '../../utils/CustomComponents/CustomMenuItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NewVendorDialog from '../../popups/NewVendorDialog';
import { featureIsExpired } from '../../utils/FeatureValidityExpire';
import { ShopContext } from '../../../Context/ShopContext';
import { roundOff } from '../../../helpers/NumberFormatHelper';
import PurchaseReturnTypePopup from '../../popups/PurchaseReturnTypePopup';

function AddPurchaseReturn() {
  const { shop } = useContext(ShopContext);

  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const purchaseId = queryParams.get('purchase_id');

  const { postAlertMessage } = useContext(AlertMessagesContext);
  const [editData, setEditData] = useState({});
  const [vendorId, setVendorId] = useState(null);
  const [vendorNameOld, setVendorNameOld] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [returnDate, setReturnDate] = useState(new Date());
  const [billDate, setBillDate] = useState(new Date());
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(null);
  const [itemPlu, setItemPlu] = useState('');
  const [itemName, setItemName] = useState('');
  const [cost, setCost] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [tax, setTax] = useState(0);
  const [totalAmount, setTotalAmount] = useState('');
  const [returnedItems, setReturnedItems] = useState([]);
  const [removedItems, setRemovedItems] = useState('');
  const [vendors, setVendors] = useState([]);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [isShowAddVendonDialog, setShowAddVendonDialog] = useState(false);
  const [stock, setStock] = useState(0);
  const [showReturnTypePopup, setShowReturnTypePopup] = useState(false);

  const featureValidity = shop?.customer_support_validity;

  const onCreateVendor = () => {
    getVendors();
    setShowAddVendonDialog(false);
  };

  const openCreateVendorDialog = () => {
    setShowAddVendonDialog(true);
  };
  const closeCreateVendorDialog = () => {
    setShowAddVendonDialog(false);
  };

  const getItems = async () => {
    const items = await ItemService.getItems();
    setItems(items);
  };

  const getVendors = async () => {
    const vedors = await VendorServices.getVendors();
    setVendors(vedors);
  };

  const handleChangePayable = (val) => {
    if (quantity === 0 || item === null) return;
    setTotalAmount(val);
    setCost(roundOff((val - tax) / quantity));
  };

  const handleChangeRate = (val) => {
    if (quantity === 0 || item === null) return;
    setCost(val);
    setTotalAmount(roundOff(val * quantity + tax));
  };

  const handleChangeQty = (val) => {
    if (item === null) return;
    setQuantity(val);
    setStock(item?.stock - Number(val));
    setTotalAmount(roundOff(Number(val) * Number(cost) + Number(tax)));
  };

  const handleChangeTax = (val) => {
    if (item === null) return;
    setTax(val);
    setTotalAmount(roundOff(cost * quantity + Number(val)));
  };

  const resetItemFields = () => {
    setItemName('');
    setItemPlu('');
    setCost('');
    setQuantity(1);
    setTax('');
    setTotalAmount('');
    setItem(null);
    setStock('');
  };

  const addItem = () => {
    let data = {
      plu: item.plu,
      item: item,
      rate: Number(cost),
      quantity: Number(quantity),
      tax: Number(tax),
      payable: Number(totalAmount),
      stock: Number(stock),
    };
    if (editData && !(editData?.items || []).find((itm) => itm.item === item && itm.rate === cost)) {
      data = {
        ...data,
        purchase: editData.id,
        new_item: true,
      };
    } else {
      const updatingItem = (editData?.items || []).find((itm) => itm.item === item && itm.rate === cost);
      data = {
        ...updatingItem,
        ...data,
      };
    }
    setReturnedItems([...returnedItems, data]);
    resetItemFields();
  };

  const handleAddPurchaseReturn = async (refundType) => {
    setLoadingIndicator(true);
    try {
      const data = {
        vendor_id: vendorId,
        invoice_no: invoiceNumber,
        bill_date: DateTimeHelpers.convertDateToDMY(billDate),
        return_date: DateTimeHelpers.convertDateToDMY(returnDate),
        items: returnedItems.map((pi) => ({ ...pi, item_id: pi.item.id })),
        refund_type: refundType,
        purchase_id: purchaseId,
      };
      await PurchaseService.addPurchaseReturn(data);
      postAlertMessage({
        text: 'Purchase return added successfully',
        type: 'success',
      });
      history.push(ROUTES.PURCHASE_RETURN_HISTORY);
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const dltItem = (item) => {
    if (item.hasOwnProperty('id')) {
      setRemovedItems((old) => [...old, item.id]);
    }
    setReturnedItems(returnedItems.filter((itm) => itm.rate !== item.rate && itm.plu !== item.plu));
  };

  const handleEditItem = (returnItem) => {
    setItem(returnItem.item);
    setItemName(returnItem.item?.name);
    setItemPlu(returnItem?.plu);
    setQuantity(returnItem?.quantity);
    setCost(returnItem?.rate);
    setTax(returnItem?.tax);
    setTotalAmount(returnItem?.payable);
    setReturnedItems(returnedItems.filter((itm) => itm.rate !== returnItem.rate && itm.plu !== returnItem.plu));
    setStock(items.find((itm) => itm.id === returnItem?.item.id)?.stock - Number(returnItem?.quantity));
  };

  const handleEditPurchaseReturn = async () => {
    setLoadingIndicator(true);
    try {
      const _items = returnedItems.map((pi) => ({
        ...pi,
        item_id: pi.item.id,
      }));
      const olditems = _items.filter((returnItem) => !returnItem.new_item);
      const newItems = _items.filter((returnItem) => returnItem.new_item);
      const data = {
        ...editData,
        vendor_id: vendorId,
        bill_date: DateTimeHelpers.convertDateToDMY(billDate),
        return_date: DateTimeHelpers.convertDateToDMY(returnDate),
        items: olditems,
        new_items: newItems,
        remove_items: removedItems,
      };
      await PurchaseService.updatePurchaseReturn(data, id);
      postAlertMessage({
        text: 'Purchase updated successfully',
        type: 'success',
      });
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setLoadingIndicator(false);
    history.push(ROUTES.PURCHASE_RETURN_HISTORY);
  };

  const fetchInitialData = async () => {
    setLoadingIndicator(true);
    await Promise.all([getItems(), getVendors()]);
    setLoadingIndicator(false);
  };

  const handleShowReturnTypePopup = () => {
    if (editData.id) {
      handleEditPurchaseReturn();
    } else if (editData.paid_amount && editData.paid_amount !== 0) {
      setShowReturnTypePopup(true);
    } else {
      handleAddPurchaseReturn('credit');
    }
  };

  const handleClosePurchseReturnTypePopup = () => {
    setShowReturnTypePopup(false);
  };

  useEffect(() => {
    fetchInitialData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }

    const getPurchaseReturnData = async () => {
      const purchaseReturn = await PurchaseService.getPurchaseReturn(id);
      setEditData(purchaseReturn);
      setReturnDate(new Date(purchaseReturn.return_date));
      setBillDate(new Date(purchaseReturn.bill_date));
      setReturnedItems(purchaseReturn.items);
      setVendorId(purchaseReturn.vendor?.id);
      setInvoiceNumber(purchaseReturn.invoice_no);
      setVendorNameOld(purchaseReturn.vendorNameOld);
    };
    getPurchaseReturnData();
  }, [id]);

  useEffect(() => {
    if (!purchaseId) {
      return;
    }

    const getPurchaseData = async () => {
      const purchase = await PurchaseService.getPurchase(purchaseId);
      setEditData({ ...purchase, id: null });
      setReturnDate(new Date());
      setReturnedItems(purchase.items);
      setVendorId(purchase.vendor?.id);
      setInvoiceNumber(purchase.invoice_no);
      setVendorNameOld(purchase.vendorNameOld);
    };
    getPurchaseData();
  }, [purchaseId]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Purchase Return<span className={styles.menuTitle}>Management</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>New Purchase Return</h2>
          </div>
        </div>
        <Grid container justify="flex-end" alignItems="center">
          <Grid item xs={2} sm={2} justify="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Vendor</h3>
          </Grid>
          {shop && featureIsExpired(featureValidity) ? (
            <Grid item xs={4} sm={4}>
              <TextField
                labelId="demo-select-small-label"
                fullWidth
                id="demo-select-small"
                value={vendorNameOld || ''}
                label="Vendor"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setVendorNameOld(e.target.value);
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={4} sm={4}>
              <TextField
                select
                labelId="demo-select-small-label"
                fullWidth
                id="demo-select-small"
                value={vendorId || 0}
                label="Vendor"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setVendorId(Number(e.target.value));
                }}
              >
                <MenuItem value={0}>
                  <em>No Vendor Selected</em>
                </MenuItem>
                {vendors.map((vendor) => (
                  <MenuItem key={vendor.id} value={`${vendor.id}`}>
                    {vendor.name}
                  </MenuItem>
                ))}
                <CustomMenuItem onClick={openCreateVendorDialog}>
                  <AddCircleIcon fontSize="large" style={{ marginLeft: 5 }} />{' '}
                  <p className={styles.normalText}>Create New Vendor</p>
                </CustomMenuItem>
              </TextField>
            </Grid>
          )}
          <Grid item xs={3} sm={3} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Return Date</h3>
          </Grid>
          <Grid item xs={3} sm={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                error={false}
                id="date-purchase"
                value={returnDate}
                onChange={(date) => {
                  setReturnDate(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid container justify="flex-end" alignItems="center">
          <Grid item xs={2} sm={2} justify="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Invoice Number</h3>
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextField
              label="Invoice Number"
              variant="outlined"
              size="small"
              fullWidth
              value={invoiceNumber}
              onChange={(e) => {
                setInvoiceNumber(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3} sm={3} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Bill Date</h3>
          </Grid>
          <Grid item xs={3} sm={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-bill"
                value={billDate}
                onChange={(date) => {
                  setBillDate(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'Change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </div>
      <div className={styles.changeable}>
        <Table>
          <TableRow key="input row">
            <TableCell>
              <Autocomplete
                value={item}
                onChange={(event, newItem) => {
                  setItem(newItem);
                  setItemName(newItem.name || '');
                  setCost(newItem?.cost || '');
                  setStock(newItem?.stock || 0);
                  setTotalAmount(newItem?.cost * quantity);
                }}
                disableClearable
                inputValue={itemPlu}
                onInputChange={(e, value) => {
                  setItemPlu(value);
                }}
                getOptionLabel={(option) => `${option.plu}`}
                options={items}
                renderInput={(params) => (
                  <TextField {...params} label="PLU" variant="outlined" size="small" fullWidth />
                )}
              />
            </TableCell>
            <TableCell>
              <Autocomplete
                value={item}
                onChange={(event, newItem) => {
                  setItem(newItem);
                  setItemPlu(String(newItem.plu) || '');
                  setCost(newItem?.cost || '');
                  setStock(newItem?.stock || 0);
                  setTotalAmount(newItem?.cost * quantity);
                }}
                disableClearable
                inputValue={itemName}
                onInputChange={(e, value) => {
                  setItemName(value);
                }}
                getOptionLabel={(option) => `${option.name}`}
                options={items}
                renderInput={(params) => (
                  <TextField {...params} label="Name" variant="outlined" size="small" fullWidth />
                )}
                style={{ minWidth: '200px' }}
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Cost"
                variant="outlined"
                size="small"
                fullWidth
                value={cost}
                onChange={(e) => handleChangeRate(e.target.value)}
                type="number"
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Quantity"
                variant="outlined"
                size="small"
                fullWidth
                type="number"
                value={quantity}
                onChange={(e) => handleChangeQty(e.target.value)}
              />
            </TableCell>
            <TableCell>
              <TextField label="Stock" variant="outlined" size="small" fullWidth disabled value={stock} />
            </TableCell>
            <TableCell>
              <TextField
                label="Tax"
                variant="outlined"
                size="small"
                fullWidth
                value={tax}
                type="number"
                onChange={(e) => handleChangeTax(e.target.value)}
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Total"
                variant="outlined"
                size="small"
                fullWidth
                value={totalAmount}
                type="number"
                onChange={(e) => handleChangePayable(e.target.value)}
              />
            </TableCell>
            <TableCell>
              <IconButton disabled={itemPlu === '' || itemName === '' || cost === ''} onClick={addItem}>
                <LibraryAddIcon />
              </IconButton>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </Table>
      </div>
      <TableContainer style={{ marginTop: 4 }} component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.columnCell}>Item PLU</TableCell>
              <TableCell className={styles.nameColumn}>Item Name</TableCell>
              <TableCell className={styles.columnCell}>Cost</TableCell>
              <TableCell className={styles.columnCell}>Quantity</TableCell>
              <TableCell className={styles.columnCell}>Stock</TableCell>
              <TableCell className={styles.columnCell}>Total Tax</TableCell>
              <TableCell className={styles.columnCell}>Total</TableCell>
              <TableCell className={styles.columnCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {returnedItems &&
              returnedItems.map((purchasedItem) => (
                <TableRow key={purchasedItem.id}>
                  <TableCell>{purchasedItem.plu}</TableCell>
                  <TableCell>{purchasedItem.item.name}</TableCell>
                  <TableCell>{purchasedItem.rate}</TableCell>
                  <TableCell>{purchasedItem.quantity}</TableCell>
                  <TableCell>{purchasedItem.item?.stock - purchasedItem.quantity}</TableCell>
                  <TableCell>{purchasedItem.tax}</TableCell>
                  <TableCell>
                    {Number(purchasedItem.rate) * Number(purchasedItem.quantity) + Number(purchasedItem.tax)}
                  </TableCell>
                  <TableCell colSpan={2} style={{ display: 'flex' }}>
                    <IconButton onClick={() => dltItem(purchasedItem)}>
                      <DeleteIcon />
                    </IconButton>
                    <IconButton onClick={() => handleEditItem(purchasedItem)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container className={styles.submitSection} justify="flex-end">
        <Link to={ROUTES.PURCHASE_HISTORY} className={styles.link}>
          <Button variant="contained" color="secondary" className={styles.closeBtn}>
            Cancel
          </Button>
        </Link>
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: '#00a65a' }}
          disabled={invoiceNumber === '' || returnDate === '' || returnedItems.length === 0}
          onClick={handleShowReturnTypePopup}
        >
          {editData.id ? 'Update Purchase Return' : 'Save Purchase Return'}
        </Button>
      </Grid>
      {isShowAddVendonDialog && <NewVendorDialog onClose={closeCreateVendorDialog} onSuccess={onCreateVendor} />}
      {showReturnTypePopup && (
        <PurchaseReturnTypePopup
          closeDialog={handleClosePurchseReturnTypePopup}
          handleAddPurchaseReturn={handleAddPurchaseReturn}
        />
      )}
    </div>
  );
}

export default withConsoleBase(AddPurchaseReturn);
