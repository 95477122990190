import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import KitchenIcon from '@mui/icons-material/Kitchen';
import ImageIcon from '@mui/icons-material/Image';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import ScaleIcon from '@mui/icons-material/Scale';
// import PaidIcon from '@mui/icons-material/Paid';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ImageSupportImg from '../../../images/image_support.svg';
import CustomerSupportImg from '../../../images/customer_support.svg';
import BluetoothSupportImg from '../../../images/bluetooth_support.svg';
// import ProfitLossSupportImg from '../../../images/profit_loss_support.svg';
import PosTerminalImg from '../../../images/pos_terminal.svg';
import KOTSupportImg from '../../../images/kot_support.svg';
import ECSSupportImg from '../../../images/ecs_support.svg';
import QuickbookSupportImg from '../../../images/quickbook_support.svg';
import SmartScaleSupportImg from '../../../images/smart_scale_support.svg';
import SalesAddonImg from '../../../images/sales_addon.svg';

export const PREMIUM_FEATURES = [
  '2000 product support',
  '3000 sales per month',
  'Category management',
  'Sales sync in five minutes',
  'One counter support',
  'Profit and loss calculation',
  'Additional expenses support',
  'Enable transaction period locking',
];

export const ADD_ONS_DETAILS = {
  image: {
    key: 'image',
    name: 'Image support',
    icon: ImageIcon,
    message: 'View and add prodict images',
    image: ImageSupportImg,
    note: 'User can add images for categories and items. Enhance your listings with images to provide a better visual experience.',
  },
  customer: {
    key: 'customer',
    name: 'Customer support',
    icon: PeopleAltIcon,
    message: 'Save and track customer ',
    image: CustomerSupportImg,
    note: 'Save and manage customer details effortlessly. Associatecustomer profiles with their purchases. Implement a loyaltypoints system to reward frequent customers.',
  },
  bluetooth_scale: {
    key: 'bluetooth_scale',
    name: 'Bluetooth support',
    icon: BluetoothIcon,
    message: '(For Premium Plan)',
    image: BluetoothSupportImg,
    note: 'Print bills effortlessly using Bluetooth printers, directly from the Android POS app, for a seamless and efficient checkout experience.',
  },
  kot: {
    key: 'kot',
    name: 'KOT support',
    icon: KitchenIcon,
    message: '(For Premium Plan)',
    image: KOTSupportImg,
    note: 'Automatically print order item lists in the kitchen when an order is placed, ensuring timely and accurate preparation of customer orders.',
  },
  smart_scale: {
    key: 'smart_scale',
    name: 'Smart scale',
    icon: ScaleIcon,
    message: '(For Premium Plan)',
    image: SmartScaleSupportImg,
    note: 'Integrate Bluetooth-enabled weighing scales with your Android POS app for seamless, accurate weight-based transactions, enhancing efficiency and customer experience.',
  },
  ecs_terminal: {
    key: 'ecs_terminal',
    name: 'ECS terminal',
    icon: PointOfSaleIcon,
    message: 'Connect ECS decive',
    image: ECSSupportImg,
    note: 'Connect the weighing scale directly to the server with the ECS terminal, enabling bill transmission without the need for an app.',
  },
  pos_terminal: {
    key: 'pos_terminal',
    name: 'Additional POS terminal',
    icon: PointOfSaleIcon,
    message: 'Connect POS decive',
    image: PosTerminalImg,
    note: 'The POS terminal facilitates seamless billing directly from your POS device, streamlining the process and enhancing efficiency.',
  },
  external_api: {
    key: 'external_api',
    name: 'Quickbook support',
    icon: LibraryBooksIcon,
    message: 'Sync sale to quickbook',
    image: QuickbookSupportImg,
    note: 'Support for QuickBooks accounting software: Connect your QuickBooks account to our software to automatically add each bill to your QuickBooks account.',
  },
  additional_sales: {
    key: 'additional_sales',
    name: 'Additional sales',
    message: 'Purchase more sales',
    note: 'We have a monthly sales limit. If the limit is reached, you will need to purchase additional sales.',
    image: SalesAddonImg,
  },
};

export const ADDON_PACKS = [
  {
    id: 'restaurant_pack',
    name: 'Restaurant Pack',
    add_ons: ['image', 'bluetooth_scale', 'customer', 'kot'],
  },
  {
    id: 'premium_pack',
    name: 'Premium Pack',
    add_ons: ['image', 'bluetooth_scale', 'customer', 'kot', 'ecs_terminal', 'smart_scale'],
  },
];

export const ADDITIONAL_PURCHASE_PACK = [
  { id: 17, sales_count: 3000, price: 1000, image: SalesAddonImg },
  { id: 18, sales_count: 6000, price: 2000, image: SalesAddonImg },
];

export const SANOFT_WEB_URLS = {
  PRIVECY_POLICY: 'https://www.sanoft.com/privacy-policy',
  TERMS: 'https://www.sanoft.com/terms-and-conditions',
  REFUND_POLICY: 'https://www.sanoft.com/refund-policy',
};

export const ADDONS = {
  kot: 'kot_validity',
  image: 'image_support_validity',
  smart_scale: 'smart_scale_validity',
  bluetooth_scale: 'bluetooth_scale_validity',
  customer: 'customer_support_validity',
  external_api: 'quickbook_support_validity',
  pos_terminal: 'pos',
  ecs_terminal: 'ecs',
};

export const REGISTER_PLANS = ['sanoft-pro', 'sanoft-lite', 'sanoft-elite'];
