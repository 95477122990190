import React, { useContext, useEffect, useState, useCallback } from 'react';
import { ShopContext } from '../../../Context/ShopContext';
import Loader from '../../utils/Loading';
import styles from './styles.module.css';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import { UserContext } from '../../../Context/UserContext';
import Info from '../../utils/Alert/Info';
import DataTable from '../../utils/DataTable/';
import { Paper } from '@mui/material';
import { USER_PERMISSIONS } from '../../../const';
import { AlertMessagesContext } from 'react-alert-messages';
import CustomerService from '../../../services/CustomerService';
import { Button, Tooltip } from '@material-ui/core';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import NewCustomerGroupDialog from '../../popups/NewCustomerGroupDialog';
import EditCustomerGroupDialog from '../../popups/EditCustomerGroupDialog';
import EditIcon from '@mui/icons-material/Edit';

function CustomerGroup() {
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [addTab, setAddTab] = useState('');
  const [groups, setGroups] = useState();
  const [editData, setEditData] = useState('');

  const customerFeatureIsExpired = () => {
    const currentDate = new Date();
    const validityDate = new Date(shop.customer_support_validity);
    return shop.customer_support_validity == null || currentDate > validityDate;
  };

  const toggleAddTab = (move = '') => {
    if (move === 'save') getCustomerGroups();
    addTab ? setAddTab('') : setAddTab('open');
  };

  const toggleEditTab = (rowData = '') => {
    editData ? setEditData('') : setEditData(rowData);
  };

  const headerData = [
    {
      label: 'ID',
      id: 'id',
      type: 'text',
    },
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Description',
      id: 'description',
      type: 'text',
    },
    {
      label: 'Edit',
      id: 'updateItems',
      type: 'callback',
      viewRender: (item) => {
        return (
          <Tooltip title="Edit" onClick={() => toggleEditTab(item)}>
              <EditIcon className={styles.iconButton} />
          </Tooltip>
        );
      },
    }  
  ];

  const getCustomerGroups = useCallback(async () => {
    setLoadingIndicator(true);
    try {
      const res = await CustomerService.getCustomerGroups();
      setGroups(res);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  }, [postAlertMessage]);

  const userHasCustomersWritePermission = user?.shop_permissions?.customers_permission === USER_PERMISSIONS.WRITE;

  useEffect(() => {
    getCustomerGroups();
  }, [getCustomerGroups]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Customer Group<span className={styles.menuTitle}>Management</span>
        </h2>
      </div>
      {shop && customerFeatureIsExpired() ? (
        <RestrictionInfo
          title={'Customer support feature not available '}
          content={'To get customer support options, contact support team..'}
        />
      ) : (
        <>
          <div className={styles.changeable}>
            <div className={styles.filterSec} style={{ justifyContent: 'flex-end' }}>
              <div className={styles.filerInputSec}>
                {userHasCustomersWritePermission && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ backgroundColor: '#ff851b' }}
                    className={styles.actionBtn}
                    onClick={toggleAddTab}
                  >
                    <NoteAddIcon className={styles.actionBtnIcon} />
                    New Group
                  </Button>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {groups && groups.length ? (
        <Paper className={styles.tableWrapper}>
          <DataTable columns={headerData} rows={groups} deactiveRow={true} />
        </Paper>
      ) : (
        !loadingIndicator && (
          <Info
            title={'No customers to list'}
            content={
              'You have no customers to list with current filter configuration. Please clear the filters or create a customer'
            }
          />
        )
      )}
      {editData && (
        <EditCustomerGroupDialog
          toggleEditTab={toggleEditTab}
          editData={editData}
          getCustomerGroups={getCustomerGroups}
        />
      )}
      {addTab && <NewCustomerGroupDialog toggleAddTab={toggleAddTab} />}
    </div>
  );
}

export default withConsoleBase(CustomerGroup);
