import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, LinearProgress, Button } from '@mui/material';
import Lottie from 'lottie-react';
import SuccessAnimation from './SuccessAnimation.json';
import FailedAnimation from './FailedAnimation.json';
import PendingAnimation from './PendingAnimation.json';
import PaymentService from '../../../services/PaymentService';
import { ROUTES, REGISTRATION_TYPE, STORAGE_KEYS } from '../../../const';

const MAX_API_CALLS = 12;
const PAYMENT_STATUS = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  INITIATED: 'INITIATED',
  FAILED: 'FAILED',
  TIMEDOUT: 'TIMEDOUT',
};

function PaymentStatus() {
  const history = useHistory();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentId = queryParams.get('payment_id');
  const secret = queryParams.get('secret');
  const action = queryParams.get('action');
  const error = queryParams.get('error');

  const [paymentStatus, setPaymentStatus] = useState(PAYMENT_STATUS.PENDING);
  const [apiCallCount, setApiCallCount] = useState(0);

  useEffect(() => {
    if (paymentStatus !== PAYMENT_STATUS.SUCCESS) return;
  
    const targetRoute = 
      action === REGISTRATION_TYPE.NEW || action === REGISTRATION_TYPE.TRIAL 
        ? ROUTES.ONBOARDING 
        : ROUTES.DASHBOARD;
  
    const timeout = setTimeout(() => history.push(targetRoute), 5000);
  
    return () => clearTimeout(timeout);
  }, [paymentStatus, history, action]);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      const resp = await PaymentService.paymentStatus(paymentId, { secret });
      setPaymentStatus(resp?.payment?.status);
      setApiCallCount((count) => count + 1);
      if (!resp?.token) return;
      localStorage.setItem(STORAGE_KEYS.TOKEN, resp?.token);
      localStorage.setItem(STORAGE_KEYS.SHOP_ID, resp?.shop?.id);
    };

    if (
      apiCallCount < MAX_API_CALLS &&
      (paymentStatus === PAYMENT_STATUS.PENDING || paymentStatus === PAYMENT_STATUS.INITIATED)
    ) {
      const interval = setInterval(checkPaymentStatus, 5000);
      return () => {
        clearInterval(interval);
      };
    } else if (apiCallCount >= MAX_API_CALLS) {
      setPaymentStatus(PAYMENT_STATUS.TIMEDOUT);
    }
  }, [paymentId, history, apiCallCount, secret, paymentStatus]);

  const animationData =
    paymentStatus === PAYMENT_STATUS.SUCCESS
      ? SuccessAnimation
      : paymentStatus === PAYMENT_STATUS.FAILED
      ? FailedAnimation
      : PendingAnimation;

  return (
    <Grid
      className={styles.container}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      rowGap={6}
    >
      {(paymentStatus === PAYMENT_STATUS.PENDING || paymentStatus === PAYMENT_STATUS.INITIATED) && !error ? (
        <>
          <Grid style={{ maxWidth: 500 }}>
            <p style={{ paddingBottom: 20 }}>Payment processing....</p>
            <LinearProgress />
          </Grid>
        </>
      ) : (
        <>
          <Grid style={{ maxHeight: 500, maxWidth: 500 }}>
            <Lottie animationData={animationData} />
          </Grid>
          <Grid>
            {paymentStatus === PAYMENT_STATUS.FAILED ? (
              <div>Payment failed! Please retry or contact our customer support if it persists</div>
            ) : paymentStatus === PAYMENT_STATUS.TIMEDOUT ? (
              <div>
                Oh sorry, we couldn't confirm your payment yet! Please contact our customer support if you didn't
                received any email from us
              </div>
            ) : paymentStatus === PAYMENT_STATUS.SUCCESS ? (
              <div>Yay, we received your payment and your subscription updated already</div>
            ) : (
              <div></div>
            )}
          </Grid>
          <Grid display="flex" justifyContent="center" columnGap={2}>
            {action === REGISTRATION_TYPE.RENEWAL && paymentStatus !== PAYMENT_STATUS.FAILED && (
              <Button variant="contained" onClick={() => history.push(ROUTES.DASHBOARD)}>
                GO TO HOME
              </Button>
            )}
            {action === REGISTRATION_TYPE.RENEWAL && paymentStatus === PAYMENT_STATUS.FAILED && (
              <Button variant="contained" onClick={() => history.push(ROUTES.RENEW_PLAN)}>
                RE-TRY PAYMENT
              </Button>
            )}
            {action !== REGISTRATION_TYPE.RENEWAL && paymentStatus === PAYMENT_STATUS.FAILED && (
              <Button variant="contained" onClick={() => history.push(ROUTES.PLANS)}>
                RE-TRY PAYMENT
              </Button>
            )}
            {action !== REGISTRATION_TYPE.RENEWAL && paymentStatus === PAYMENT_STATUS.SUCCESS && (
              <Button variant="contained" onClick={() => history.push(ROUTES.ONBOARDING)}>
                GO TO ONBOARDING
              </Button>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default PaymentStatus;
